"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Modal = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_dom_1 = require("react-dom");
const react_1 = __importStar(require("react"));
const Modal = ({ open, onRequestClose, children, testId, }) => {
    (0, react_1.useEffect)(() => {
        function escHandler({ key }) {
            if (key === "Escape") {
                onRequestClose();
            }
        }
        if (open) {
            window.addEventListener("keydown", escHandler);
        }
        return () => {
            window.removeEventListener("keydown", escHandler);
        };
    }, [open, onRequestClose]);
    return ((0, jsx_runtime_1.jsx)(react_1.default.Fragment, { children: open
            ? (0, react_dom_1.createPortal)((0, jsx_runtime_1.jsx)("div", { className: "justify-center items-center flex overflow-y-auto fixed inset-0 bg-black/50", "data-testid": `${testId}-modal`, onClick: () => onRequestClose(), children: (0, jsx_runtime_1.jsx)("div", { className: "relative w-auto mx-auto my-auto", children: (0, jsx_runtime_1.jsx)("div", { children: children }) }) }), document.body)
            : null }));
};
exports.Modal = Modal;
