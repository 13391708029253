import PageHeader from "./page-header"
import PhoneAnchorTag from "../utils/phone-anchor-tag"
import { store } from "../store"

export default function CongratsErrorPageContent() {
  const agentInfo = store.useState(s => s.agentInfo)
  const firstName = agentInfo.name?.split(" ")[0]
  return (
    <div className="grins-lg:pr-32">
      <PageHeader testId="error-page-header-h2" className="mb-6">
        Thank you!
      </PageHeader>
      <p className="mb-6 text-xl text-white font-medium">
        You're one step closer to finding great insurance coverage.
      </p>
      <p className="mb-6 text-xl text-white font-medium">
        {firstName
          ? `Due to current market conditions, some of our insurance carriers have limited digital quote options available. No worries, ${firstName} is searching for additional carriers right now for the best policy available for you. They will be in touch shortly to discuss your options or you can call now at `
          : `Due to current market conditions, some of our insurance carriers have limited digital quote options available. No worries, our agents are searching additional carriers right now for the best policy available for you. They will be in touch shortly to discuss your options.`}
        {firstName && (
          <PhoneAnchorTag className="font-bold whitespace-nowrap" underlined />
        )}
      </p>
      {!firstName && (
        <p className="mb-6 text-xl text-white font-medium">
          Want to talk to an agent now? Give us a call at&nbsp;
          <PhoneAnchorTag className="font-bold whitespace-nowrap" underlined />
        </p>
      )}
    </div>
  )
}
