"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StateToBranchCode = exports.PolicyShellDepartment = exports.PremiumPayableType = void 0;
var PremiumPayableType;
(function (PremiumPayableType) {
    PremiumPayableType["BR"] = "Broker (External)";
    PremiumPayableType["IN"] = "Broker (Internal)";
    PremiumPayableType["CA"] = "Carrier";
})(PremiumPayableType || (exports.PremiumPayableType = PremiumPayableType = {}));
var PolicyShellDepartment;
(function (PolicyShellDepartment) {
    PolicyShellDepartment["PL"] = "Personal Lines";
    PolicyShellDepartment["CL"] = "Commercial Lines";
    PolicyShellDepartment["LH"] = "Life & Health";
})(PolicyShellDepartment || (exports.PolicyShellDepartment = PolicyShellDepartment = {}));
var StateToBranchCode;
(function (StateToBranchCode) {
    StateToBranchCode["CA"] = "CA";
    StateToBranchCode["KS"] = "KS";
    StateToBranchCode["MI"] = "MI";
    StateToBranchCode["MN"] = "MN";
    StateToBranchCode["MO"] = "MO";
    StateToBranchCode["ND"] = "ND";
    StateToBranchCode["NE"] = "NE";
    StateToBranchCode["NH"] = "NH";
    StateToBranchCode["NY"] = "NY";
    StateToBranchCode["TX"] = "TX";
    StateToBranchCode["default"] = "GRI";
})(StateToBranchCode || (exports.StateToBranchCode = StateToBranchCode = {}));
