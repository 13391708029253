import CongratsErrorPageContent from "../components/congrats-error-page-content"
import { usePageLoadEvent } from "../utils/session"

export default function NoQuotes() {
  usePageLoadEvent({
    page_path: "/noquotes",
    page_title: "No Quotes Available",
  })

  return <CongratsErrorPageContent />
}
