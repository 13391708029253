"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toBicEffectiveHomeCloseDate = void 0;
const luxon_1 = require("luxon");
/**
 * @summary Applies business rules and formats the closing date, per Branch Insurance Carrier (Branch IC) reqs.
 * @param {string} dateToConvert the date to convert (if nec) and format, for Branch IC, home close date
 * @returns converted (if nec) & formatted date
 * @throws {Error} when date is missing or empty or if there is a problem with input date value.
 */
const toBicEffectiveHomeCloseDate = (dateToConvert) => {
    if (!dateToConvert || dateToConvert.trim().length <= 0) {
        throw new Error(`Input date is empty or undefined`);
    }
    const luxonDate = luxon_1.DateTime.fromJSDate(new Date(dateToConvert), {
        zone: "utc",
    });
    if (luxonDate.invalidReason) {
        throw new Error(`Input date error: ${luxonDate.invalidReason}`);
    }
    const dateToConvertIsPassed = luxonDate.toUTC().startOf("day").toSeconds() <
        luxon_1.DateTime.utc().startOf("day").toSeconds();
    let effectiveHomeCloseDate = luxonDate.toFormat("yyyy-MM-dd");
    // per biz rules, if date is passed, force it to 7 days beyond now.
    if (dateToConvertIsPassed) {
        effectiveHomeCloseDate = luxon_1.DateTime.utc()
            .plus({ days: 7 })
            .toFormat("yyyy-MM-dd");
    }
    return effectiveHomeCloseDate;
};
exports.toBicEffectiveHomeCloseDate = toBicEffectiveHomeCloseDate;
