import { AutoQuote } from "grid-transform"
import { formatCurrency, toKiloUnits } from "grins-utils"
import { useState } from "react"
import AutoQuoteInfoModal from "./quote-info-modal"

export interface Props {
  autoQuote: AutoQuote
}

export default function AutoQuoteDetails({ autoQuote }: Props) {
  const [modalOpen, setModalOpen] = useState(false)
  const [infoModalTitle, setInfoModalTitle] = useState("")
  const [infoModalVerbiage, setInfoModalVerbiage] = useState("")
  const openInfoModal = (title: string, verbiage: string) => {
    setInfoModalTitle(title)
    setInfoModalVerbiage(verbiage)
    setModalOpen(true)
  }

  const renderDetail = (
    title: string,
    value: number,
    infoModalTitle: string,
    infoModalVerbiage: string
  ) => (
    <div className="flex flex-col gap-2">
      <div>
        <span className="text-sm">{title}&nbsp;</span>
        <img
          src="/public/info-icon.svg"
          className="cursor-pointer inline"
          alt="more info icon"
          onClick={() => openInfoModal(infoModalTitle, infoModalVerbiage)}
        />
      </div>
      <span className="font-bold">
        {formatCurrency(toKiloUnits(value))}
        {Math.abs(value) > 999 ? "K" : ""}
      </span>
    </div>
  )

  const renderLowHighDetail = (
    title: string,
    lowValue: number,
    highValue: number,
    infoModalTitle: string,
    infoModalVerbiage: string
  ) => (
    <div className="flex flex-col gap-2">
      <div>
        <span className="text-sm">{title}&nbsp;</span>
        <img
          src="/public/info-icon.svg"
          className="cursor-pointer inline"
          alt="more info icon"
          onClick={() => openInfoModal(infoModalTitle, infoModalVerbiage)}
        />
      </div>
      <span className="font-bold">
        {formatCurrency(toKiloUnits(lowValue))}
        {Math.abs(lowValue) > 999 ? "K" : ""} /{" "}
        {formatCurrency(toKiloUnits(highValue))}
        {Math.abs(highValue) > 999 ? "K" : ""}
      </span>
    </div>
  )

  return (
    <div className="grid grid-cols-2 sm:grid-cols-3 gap-y-7">
      {renderLowHighDetail(
        "Bodily Injury",
        autoQuote.bodilyInjuryCoverage.low ?? 0,
        autoQuote.bodilyInjuryCoverage.high ?? 0,
        "Bodily Injury",
        "Coverage that pays for costs incurred if you injure someone in an accident that you cause."
      )}
      {renderDetail(
        "Property Damage",
        autoQuote.propertyDamageCoverage ?? 0,
        "Property Damage",
        "Coverage that pays for costs incurred if you damage someone’s property in an accident."
      )}
      {renderDetail(
        "Medical Coverage",
        autoQuote.medicalCoverage ?? 0,
        "Medical Coverage",
        "Medical payments coverage helps pay for injuries sustained in an accident for passengers in the car with you."
      )}
      {renderLowHighDetail(
        "Uninsured Motorist",
        autoQuote.uninsuredMotoristCoverage.low ?? 0,
        autoQuote.uninsuredMotoristCoverage.high ?? 0,
        "Uninsured/Underinsured Motorist",
        "This coverage protects you if you are hit by a driver who doesn’t have insurance — or doesn’t have sufficient coverage to cover the cost of the damage they cause."
      )}
      {renderDetail(
        "Comprehensive Deductible",
        autoQuote.comprehensiveDeductible ?? 0,
        "Comprehensive Coverage",
        "Comprehensive coverage pays for repairs that result from damages that are not vehicle collisions.  The deductible is what you will pay before the coverage kicks in for any remaining amount."
      )}
      {renderDetail(
        "Collision Deductible",
        autoQuote.collisionDeductible ?? 0,
        "Collision Coverage",
        "Collision coverage helps repair or replace your vehicle if you are in an accident.  The deductible is what you will pay before the coverage kicks in for any remaining amount."
      )}
      <AutoQuoteInfoModal
        title={infoModalTitle}
        verbiage={infoModalVerbiage}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      />
    </div>
  )
}
