import { ChangeEventHandler } from "react"
import {
  AddressInput,
  Button,
  DateInput,
  SelectComplexOptions,
  SelectOptionsType,
  TextInput,
} from "grins-ui"
import { DateTime } from "luxon"
import { store } from "../../store"
import { OmsiteAddressObject } from "grid-transform"
import get from "lodash/get"
import { UsStateJsMap } from "grins-utils"
import PageHeader from "../../components/page-header"
import { usePageLoadEvent } from "../../utils/session"

interface PropertyPageProps {
  onNext: () => void
}

export default function PropertyPage({ onNext }: PropertyPageProps) {
  usePageLoadEvent({
    page_path: "/homeflow/propertyinfo",
    page_title: "Home Property Info",
  })

  const isSubmitting = store.useState(s => s.isSubmitting)
  const inputState = store.useState(s => ({
    property: s.application.property,
  }))
  const handleChange = ({
    e,
    address,
  }: {
    e?: React.ChangeEvent
    address?: OmsiteAddressObject | string
  }) => {
    const target = e?.target as HTMLInputElement
    const name = target?.name
    const value = target?.value
    store.update(s => {
      if (address !== undefined && typeof address === "object") {
        s.application.property.address = address
      } else if (typeof address === "string") {
        const [streetNumber, streetName] = address.split(/\s+(.*)/)
        s.application.property.address.streetName = streetName
        s.application.property.address.streetNumber = streetNumber
      } else {
        s.application.property.address[name as keyof OmsiteAddressObject] =
          value
      }
    })
  }
  const handlePurchaseDateChange = (value: DateTime | null) => {
    store.update(s => {
      s.application.property.purchaseDate = value
    })
  }
  const handleStateChange = (value: number | null) => {
    store.update(s => {
      if (value === null) {
        s.application.property.address.state = null
      } else {
        s.application.property.address.state = usStatesArray[value].value.base
      }
    })
  }
  const handleZipChange: ChangeEventHandler<HTMLInputElement> = e => {
    const newValue = e.target.value.replace(/\D/g, "")
    store.update(s => {
      s.application.property.address.zip = newValue
    })
  }

  const shouldButtonBeDisabled = () => {
    const {
      property: { address, purchaseDate },
    } = inputState
    if (
      address.streetNumber.length > 0 &&
      get(address, "streetName", "").length > 0 &&
      address.city.length > 2 &&
      address.state &&
      address.state.length == 2 &&
      address.zip.length === 5 &&
      purchaseDate !== null
    ) {
      return false
    } else {
      return true
    }
  }

  const usStatesArray: SelectOptionsType<string>[] = [
    {
      uiLabel: "State",
      value: {
        base: "State",
        data: undefined,
      },
    },
  ]

  // need to do below via `new Set()` because, as a lookup Map, UsStateJsMap has duplicate values
  const tempArray: string[] = [...new Set(Array.from(UsStateJsMap.values()))]

  tempArray.forEach(stateValue => {
    usStatesArray.push({
      uiLabel: stateValue,
      value: {
        base: stateValue,
        data: undefined,
      },
    })
  })

  const findSelectedStateIndex = () => {
    const foundIndex = usStatesArray.findIndex(e => {
      return (
        e.value.base === inputState.property.address.state ||
        e.value.base === ""
      )
    })
    return foundIndex >= 0 ? foundIndex : null
  }

  const isStateCalifornia = inputState.property.address.state === "CA"
  const businessName = isStateCalifornia
    ? "Rate Insurance Agency, LLC"
    : "Rate Insurance"

  return (
    <div className="max-w-sm relative" data-testid="property-page">
      <PageHeader
        className="grins-lg:whitespace-nowrap"
        testId="property-page-header-h2"
      >
        What's your address?
      </PageHeader>
      <div>
        <AddressInput
          className="my-6"
          testId="property-address-input"
          apiKey={window?.ENV?.GOOGLE_MAPS_API_KEY}
          onChange={address =>
            handleChange({ address: address as OmsiteAddressObject | string })
          }
        />
      </div>
      <div>
        <TextInput
          data-testid="apt-suite-number-input"
          name="aptSuiteNumber"
          className="my-6"
          label="APT/Suite number"
          value={inputState.property.address.aptSuiteNumber}
          onChange={e => handleChange({ e })}
        />
        <div className="flex gap-3 my-6">
          <TextInput
            data-testid="city-input"
            name="city"
            className="w-36"
            label="City"
            value={inputState.property.address.city}
            onChange={e => handleChange({ e })}
          />
          <SelectComplexOptions
            testId="state-select"
            autoCompleteName="address-level1"
            className="w-28"
            inputName="state"
            label="State"
            onSelectOption={handleStateChange}
            options={usStatesArray}
            selectedOption={findSelectedStateIndex()}
          />
          <TextInput
            data-testid="zip-input"
            name="zip"
            className="w-36"
            label="Zip"
            inputMode="numeric"
            maxLength={5}
            value={inputState.property.address.zip}
            onChange={handleZipChange}
          />
        </div>
        <div className="mt-10 mb-6 text-2xl text-white font-semibold leading-7">
          When did you purchase this home?
        </div>
        <DateInput
          testId="purchase-date-input"
          allowFutureDates={true}
          onDateComplete={handlePurchaseDateChange}
          includeDayInput={false}
        />
        <div className="my-2.5 text-xs text-white font-normal leading-4">
          If you can't remember the exact day, just give us your best estimate.
        </div>
        <div className="my-2.5 text-xs text-white font-normal leading-4">
          If you are in the process of purchasing this home, enter the estimated
          closing date.
        </div>
        <div
          className="my-3 text-2xs text-white font-normal leading-3"
          data-testid="property-page-disclaimer-div"
        >
          {`To provide accurate quotes, some of the insurance companies we
          represent will confirm your information through a consumer credit
          report. By clicking "Next" you authorize ${businessName}
          permission to order your credit information.`}
        </div>
        <div className="mt-14">
          <Button
            data-testid="next-btn"
            size="large-square"
            label="Next"
            loading={isSubmitting}
            disabled={shouldButtonBeDisabled()}
            onClick={onNext}
          />
        </div>
      </div>
    </div>
  )
}
