import { Button, RadioButton } from "grins-ui"
import PageHeader from "../../components/page-header"
import { useState } from "react"
import { usePageLoadEvent } from "../../utils/session"

export interface Props {
  onYes: () => void
  onNo: () => void
}

export default function AddCoApplicant({ onYes, onNo }: Props) {
  usePageLoadEvent({
    page_path: "/homeflow/addadditionalapplicant",
    page_title: "Home Add Additional Applicant Question",
  })

  const [selectedRadioButton, setSelectedRadioButton] = useState<string>("")

  return (
    <div>
      <PageHeader testId="add-co-applicant-header-h2">
        Want to add anyone to the quote?
      </PageHeader>
      <div className="flex flex-col mt-6 gap-6 w-80 max-w-sm">
        <RadioButton
          data-testid="add-co-applicant-yes-radio-btn"
          name="add-co-applicant-yes"
          label="Yes"
          value="Yes"
          checked={selectedRadioButton === "Yes"}
          onChange={() => setSelectedRadioButton("Yes")}
        />

        <RadioButton
          data-testid="add-co-applicant-no-radio-btn"
          name="add-co-applicant-no"
          label="No, just me"
          value="No"
          checked={selectedRadioButton === "No"}
          onChange={() => setSelectedRadioButton("No")}
        />
      </div>
      <div className="mt-14">
        <Button
          data-testid="next-btn"
          size="large-square"
          label="Next"
          disabled={selectedRadioButton === ""}
          onClick={() => (selectedRadioButton === "Yes" ? onYes() : onNo())}
        />
      </div>
    </div>
  )
}
