import AutoQuotesSummary from "./auto-quotes-summary"
import NoQuotes from "../no-quotes"
import { store } from "../../store"
import { usePageLoadEvent } from "../../utils/session"

export default function AutoQuotesDisplay() {
  usePageLoadEvent({
    page_path: "/quotes",
    page_title: "Quotes",
  })

  const { topAutoQuotes, bindableAutoQuotes } = store.useState(s => ({
    topAutoQuotes: s.topAutoQuotes,
    bindableAutoQuotes: s.bindableAutoQuotes,
  }))

  const haveQuotesToDisplay = topAutoQuotes.length || bindableAutoQuotes.length

  return haveQuotesToDisplay ? <AutoQuotesSummary /> : <NoQuotes />
}
