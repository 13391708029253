"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertDateStringToISOWithoutTimestamp = exports.calculateYearsAtAddress = void 0;
const luxon_1 = require("luxon");
function calculateYearsAtAddress(purchaseDate) {
    const luxonPurchaseDate = luxon_1.DateTime.fromJSDate(new Date(purchaseDate), {
        zone: "utc",
    });
    if (!luxonPurchaseDate.isValid) {
        throw new Error(`Error calculating years at address.  Purchase date input was invalid: '${purchaseDate}'`);
    }
    const currentYear = luxon_1.DateTime.utc().toObject().year;
    const purchaseYear = luxonPurchaseDate.toObject().year;
    if (!currentYear || !purchaseYear) {
        throw new Error(`Error calculating years at address.  Purchase date input was invalid: '${purchaseDate}'`);
    }
    return currentYear - purchaseYear;
}
exports.calculateYearsAtAddress = calculateYearsAtAddress;
/**
 * This function formats date strings as ISO without timestamp.
 * @param value - The string value to be converted to iso string format.
 * @returns The ISO formatted string without timestamp or undefined if invalid date string was passed.
 */
function convertDateStringToISOWithoutTimestamp(value) {
    const luxonDate = luxon_1.DateTime.fromJSDate(new Date(value), { zone: "utc" });
    if (luxonDate.invalidReason) {
        return undefined;
    }
    const dateToReturn = luxonDate.toFormat("yyyy-LL-dd");
    return dateToReturn;
}
exports.convertDateStringToISOWithoutTimestamp = convertDateStringToISOWithoutTimestamp;
