"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Button = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const LoadingSVG = ({ size }) => {
    let svgDivClasses, strokeWidth, svgWidth, svgHeight;
    switch (size) {
        case "xs-small":
        case "xs-small-square":
        case "xs-small-square-inverted":
            svgDivClasses = "left-1 top 1";
            strokeWidth = "8";
            svgWidth = svgHeight = "15px";
            break;
        case "small":
        case "small-square":
            svgDivClasses = "left-4 top-1";
            strokeWidth = "10";
            svgWidth = svgHeight = "22px";
            break;
        case "large":
        case "large-square":
        default:
            svgDivClasses = "left-7 top-3";
            strokeWidth = "12";
            svgWidth = svgHeight = "28px";
            break;
    }
    return ((0, jsx_runtime_1.jsx)("div", { className: `inline absolute ${svgDivClasses}`, "data-testid": "loadingSVGDiv", children: (0, jsx_runtime_1.jsx)("svg", { xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", width: svgWidth, height: svgHeight, viewBox: "0 0 100 100", preserveAspectRatio: "xMidYMid", children: (0, jsx_runtime_1.jsx)("circle", { cx: "50", cy: "50", fill: "none", stroke: "#ffffff", strokeWidth: strokeWidth, r: "35", strokeDasharray: "164.93361431346415 56.97787143782138", children: (0, jsx_runtime_1.jsx)("animateTransform", { attributeName: "transform", type: "rotate", repeatCount: "indefinite", dur: "1s", values: "0 50 50;360 50 50", keyTimes: "0;1" }) }) }) }));
};
const Button = ({ size = "large", disabled = false, loading = false, label, ...props }) => {
    const sizeAndShape = (size) => {
        switch (size) {
            case "xs-small":
                return "text-xl px-5 py-2 rounded-3xl";
            case "xs-small-square":
                return "text-xl px-5 py-2 rounded-lg";
            //in the case of xs-small-square-inverted, we won't use any of the defaults below
            case "xs-small-square-inverted":
                return "text-gr-red bg-white text-xl px-2 py-2 rounded-lg font-bold border-2 border-gr-red cursor-pointer inline-block leading-4 focus:outline-offset-2 sm:focus:outline-offset-4 disabled:bg-gr-disabled-grey relative";
            case "small":
                return "text-xl px-10 py-2 rounded-3xl";
            case "small-square":
                return "text-xl px-10 py-2 rounded-lg";
            case "large":
                return "text-2xl py-5 px-16 rounded-4xl";
            case "large-square":
                return "disabled:text-background-grey text-xl py-[18px] px-[62px] rounded-lg";
        }
    };
    return ((0, jsx_runtime_1.jsxs)("button", { type: "button", className: size === "xs-small-square-inverted"
            ? sizeAndShape(size)
            : `text-white font-bold border-0 cursor-pointer inline-block leading-4 focus:outline-offset-2 sm:focus:outline-offset-4 bg-gr-red disabled:bg-gr-disabled-grey relative ${sizeAndShape(size)}`, ...props, disabled: disabled || loading, children: [loading ? (0, jsx_runtime_1.jsx)(LoadingSVG, { size: size }) : null, label] }));
};
exports.Button = Button;
