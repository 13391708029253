import { getCarrierData } from "grid-transform"
import { store, ClientFlow } from "../store"
import PhoneAnchorTag from "../utils/phone-anchor-tag"

export default function OtherCarriers() {
  const carriersData = store.useState(s => s.carriers)

  const progress = store.useState(s => s.progress)
  const homeQuotes = store.useState(s => s.otherQuotes)
  const autoQuotes = store.useState(s => s.otherAutoQuotes)

  const lobIsHome = progress.flow === ClientFlow.home.toString()
  const otherQuotes = lobIsHome ? homeQuotes : autoQuotes

  const failedQuotesWithoutEzlynx = otherQuotes.filter(
    quote => quote.carrierId !== "1003" // EZLynx carrierID
  )

  const hasOnlyEzLynxFailedQuote =
    failedQuotesWithoutEzlynx.length === 0 && otherQuotes.length === 1

  const renderOtherQuotes = () => {
    return failedQuotesWithoutEzlynx.map((quote, index) => {
      const logo = getCarrierData(quote.carrierId, carriersData)

      if (!logo) {
        return null
      }

      return (
        <img
          key={index}
          alt={logo.name}
          src={logo.logo_url}
          data-testid="other-carrier-logo"
          className="max-h-8"
        />
      )
    })
  }

  const summary = hasOnlyEzLynxFailedQuote ? (
    <>
      <p className="text-lg pb-3 text-center grins-sm:text-left">
        We have additional carrier options but we will need to gather additional
        information to provide a quote. <br /> Call us at{" "}
        <PhoneAnchorTag underlined /> to learn more.
      </p>
    </>
  ) : (
    <>
      <p className="text-lg pb-3 text-center grins-sm:text-left">
        We also attempted to get quotes with these carriers:
      </p>
      <div className="flex flex-wrap flex-col gap-5 grins-sm:flex-row  items-center justify-between">
        {renderOtherQuotes()}
      </div>
    </>
  )

  return (
    <div>
      <div>
        <div className="bg-white my-6 p-3 w-full border border-gr-disabled-grey rounded-xl shadow-lg flex flex-col justify-center">
          {summary}
        </div>
      </div>
    </div>
  )
}
