import { Page } from "./utils/enum"
import { store } from "./store"
import {
  HomeQuote,
  QuoteStatus,
  AutoQuote,
  VehicleDetails,
  Ymm,
  getCarrierData,
  Carrier,
  QuoteServicePromptQuestion,
  QuoteServicePromptAnswer,
} from "grid-transform"
import _sort from "lodash/sortBy"

export function navigatePage(page: Page) {
  store.update(s => {
    s.currentPage = page
  })
  updateProgressWidget(page)
}

export function updatePromptsWithPurchaseAmount(
  originalPromptQuestions: QuoteServicePromptQuestion[],
  omlinkPurchasePriceAmount: number | null
) {
  let filteredPromptQuestions: QuoteServicePromptQuestion[] = []
  const promptAnswers: QuoteServicePromptAnswer[] = []
  const replacementCostPromptQuestion = originalPromptQuestions.find(
    promptQuestion => promptQuestion.prompt_type === "replacement_cost"
  )

  if (replacementCostPromptQuestion && omlinkPurchasePriceAmount) {
    filteredPromptQuestions = originalPromptQuestions.filter(
      promptQuestion => promptQuestion.prompt_type !== "replacement_cost"
    )
    promptAnswers.push({
      prompt_id: replacementCostPromptQuestion.prompt_id,
      prompt_type: "replacement_cost",
      value: omlinkPurchasePriceAmount,
    })

    store.update(s => {
      s.promptQuestions = filteredPromptQuestions
      s.application.promptAnswers.push(...promptAnswers)
    })
  }
}

export function setQuoteResults(
  homeQuotes: HomeQuote[],
  carriersData: Carrier[],
  promptQuestions: QuoteServicePromptQuestion[]
) {
  const bindableCarrierIds = ["1001"]

  const successfulAssistedQuotes = homeQuotes.filter(
    q =>
      q.status === QuoteStatus.Succeeded &&
      !bindableCarrierIds.includes(q.carrierId) &&
      getCarrierData(q.carrierId, carriersData)
  )

  const successfulBindableQuotes = homeQuotes.filter(
    q =>
      q.status === QuoteStatus.Succeeded &&
      bindableCarrierIds.includes(q.carrierId) &&
      getCarrierData(q.carrierId, carriersData)
  )

  const failedQuotes = homeQuotes.filter(
    q =>
      q.status !== QuoteStatus.Succeeded &&
      getCarrierData(q.carrierId, carriersData)
  )

  const showTopNBindable = 4
  const {
    topQuotes: topBindableQuotes,
    otherSuccessfulQuotes: otherSuccessfulBindableQuotes,
  } = sortSeparateTopNQuotes(successfulBindableQuotes, showTopNBindable)
  //we want to show to show 4 quotes in total, with bindable quotes taking priority
  const showTopNAssisted =
    4 - topBindableQuotes.length > 0 ? 4 - topBindableQuotes.length : 0
  const {
    topQuotes: topAssistedQuotes,
    otherSuccessfulQuotes: otherSuccessfulAssistedQuotes,
  } = sortSeparateTopNQuotes(successfulAssistedQuotes, showTopNAssisted)

  store.update(s => {
    s.bindableQuotes = successfulBindableQuotes
    s.topQuotes = topAssistedQuotes
    s.otherQuotes = [
      ...otherSuccessfulAssistedQuotes,
      ...otherSuccessfulBindableQuotes,
      ...failedQuotes,
    ]
    s.promptQuestions = promptQuestions
  })
}

function sortSeparateTopNQuotes(homeQuotes: HomeQuote[], topN: number) {
  const sortedSuccessfulQuotes = _sort(homeQuotes, "costPerYear")
  const topQuotes = sortedSuccessfulQuotes.slice(0, topN)
  const otherSuccessfulQuotes = sortedSuccessfulQuotes.slice(topN)

  return {
    topQuotes,
    otherSuccessfulQuotes,
  }
}

export function setAutoQuoteResults(
  autoQuotes: AutoQuote[],
  carriersData: Carrier[]
) {
  const bindableCarrierIds = ["1001"]

  const successfulQuotes = autoQuotes.filter(
    q =>
      q.status === QuoteStatus.Succeeded &&
      !bindableCarrierIds.includes(q.carrierId) &&
      getCarrierData(q.carrierId, carriersData)
  )

  const successfulBindableQuotes = autoQuotes.filter(
    q =>
      q.status === QuoteStatus.Succeeded &&
      bindableCarrierIds.includes(q.carrierId) &&
      getCarrierData(q.carrierId, carriersData)
  )

  const failedQuotes = autoQuotes.filter(
    q =>
      q.status !== QuoteStatus.Succeeded &&
      getCarrierData(q.carrierId, carriersData)
  )

  // Bindable Quotes
  const showTopNBindable = 4
  const sortedBindableQuotes = _sort(successfulBindableQuotes, "monthlyPremium")
  const topBindableAutoQuotes = sortedBindableQuotes.slice(0, showTopNBindable)
  const otherBindableQuotes = sortedBindableQuotes.slice(showTopNBindable)

  // Assisted Quotes
  const remainingTopQuoteSpaces = 4 - topBindableAutoQuotes.length
  const haveRemainingQuoteSpaces = remainingTopQuoteSpaces > 0
  const defaultSpaceCountIfNoneAvailable = 0
  const showTopNAssisted = haveRemainingQuoteSpaces
    ? remainingTopQuoteSpaces
    : defaultSpaceCountIfNoneAvailable

  const sortedAssistedQuotes = _sort(successfulQuotes, "monthlyPremium")
  const topAssistedAutoQuotes = sortedAssistedQuotes.slice(0, showTopNAssisted)
  const otherAssistedQuotes = sortedAssistedQuotes.slice(showTopNAssisted)

  store.update(s => {
    s.bindableAutoQuotes = topBindableAutoQuotes
    s.topAutoQuotes = topAssistedAutoQuotes
    s.otherAutoQuotes = [
      ...otherAssistedQuotes,
      ...otherBindableQuotes,
      ...failedQuotes,
    ]
  })
}

export function updateProgressWidget(page: Page) {
  switch (page) {
    // Home flow
    case Page.applicant:
    case Page.coApplicant:
      return store.update(s => {
        s.progress.currentStep = 0
      })

    case Page.propertyPage:
      return store.update(s => {
        s.progress.currentStep = 1
      })

    case Page.additionalInfo:
      return store.update(s => {
        s.progress.currentStep = s.progress.steps.findIndex(
          step => step.title === "Additional Info"
        )
      })

    // Auto flow
    case Page.primaryDriver:
    case Page.additionalDrivers:
    case Page.addDriver:
      return store.update(s => {
        s.progress.currentStep = 0
      })

    case Page.garagingAddress:
      return store.update(s => {
        s.progress.currentStep = 1
      })

    case Page.addVehicle:
    case Page.vehicleDetails:
    case Page.addAdditionalVehicle:
      return store.update(s => {
        s.progress.currentStep = 2
      })

    // Prefill flow
    case Page.prefill:
      return store.update(s => {
        s.progress.currentStep = 0
      })

    // Shared
    case Page.fetchingQuotes:
      return store.update(s => {
        s.progress.currentStep = s.progress.steps.length - 1
      })

    // Referral flow
    case Page.referral:
      return store.update(s => {
        s.progress.currentStep = 0
      })
    case Page.referralSuccess:
      return store.update(s => {
        s.progress.currentStep = 1
      })

    // Last steps for Auto and Home
    case Page.quotesDisplay:
    case Page.autoQuotesDisplay:
    case Page.ezLynxPrefillError:
    case Page.error:
      return store.update(s => {
        s.progress.currentStep = s.progress.steps.length
      })
  }
}

export function addBlankAdditionalDriver() {
  store.update(s => {
    s.autoApplication.additionalDrivers.push({
      firstName: "",
      lastName: "",
      dateOfBirth: null,
      gender: null,
      maritalStatus: null,
      phoneNumber: "",
    })
  })
}

export function removeLastAdditionalDriver() {
  store.update(s => {
    s.autoApplication.additionalDrivers.pop()
  })
}

export function addApplicantToProgress() {
  store.update(s => {
    const applicant = s.application.applicant
    s.progress.steps[0].subTitles.push(
      `${applicant.firstName} ${applicant.lastName}`
    )
  })
}

export function toggleCoApplicantExists(exists: boolean) {
  store.update(s => {
    s.application.coApplicant.exists = exists
  })
}

export function addCoApplicantToProgress() {
  store.update(s => {
    const coApplicant = s.application.coApplicant
    s.progress.steps[0].subTitles.push(
      `${coApplicant.firstName} ${coApplicant.lastName}`
    )
  })
}

export function addPrimaryDriverToProgress() {
  store.update(s => {
    const primaryDriver = s.autoApplication.primaryDriver
    s.progress.steps[0].subTitles.push(
      `${primaryDriver.firstName} ${primaryDriver.lastName}`
    )
  })
}

export function addDriverToProgress() {
  store.update(s => {
    const drivers = s.autoApplication.additionalDrivers
    const driver = drivers[drivers.length - 1]
    s.progress.steps[0].subTitles.push(`${driver.firstName} ${driver.lastName}`)
  })
}

export function addHomeAddressToProgress(stepIndex = 1) {
  store.update(s => {
    const address = s.application.property.address
    s.progress.steps[stepIndex].subTitles.push(
      `${address.streetNumber} ${address.streetName}`
    )
    if (address.aptSuiteNumber) {
      s.progress.steps[stepIndex].subTitles.push(address.aptSuiteNumber)
    }
    s.progress.steps[stepIndex].subTitles.push(
      `${address.city}, ${address.state || ""} ${address.zip}`
    )
  })
}

export function addAutoAddressToProgress() {
  store.update(s => {
    const address = s.autoApplication.property.address
    s.progress.steps[1].subTitles.push(
      `${address.streetNumber} ${address.streetName}`
    )
    if (address.aptSuiteNumber) {
      s.progress.steps[1].subTitles.push(address.aptSuiteNumber)
    }
    s.progress.steps[1].subTitles.push(
      `${address.city}, ${address.state || ""} ${address.zip}`
    )
  })
}

export function addVehicle(ymm: Ymm) {
  store.update(s => {
    const currentVehicle = {
      ymm,
      details: { ownership: "", usage: "", miles: "" },
    }
    s.currentVehicle = currentVehicle
    s.progress.steps[2].subTitles.push(`${ymm.year} ${ymm.make} ${ymm.model}`)
  })
}

export function addVehicleDetails(vehicleDetails: VehicleDetails) {
  store.update(s => {
    const ymm = s.currentVehicle.ymm
    const currentVehicle = {
      ymm,
      details: { ...vehicleDetails },
    }
    s.currentVehicle = currentVehicle
    s.autoApplication.vehicles.push(currentVehicle)
  })
}
