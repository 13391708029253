import { Modal } from "grins-ui"
import { formatCurrency } from "grins-utils"
import { ReactElement } from "react"
import PhoneAnchorTag from "../utils/phone-anchor-tag"
import { store } from "../store"

export interface QuoteModalProps {
  monthlyPremium: number
  modalOpen: boolean
  setModalOpen: (value: boolean) => void
  image: ReactElement
}

export default function QuoteModal({
  monthlyPremium,
  modalOpen,
  setModalOpen,
  image,
}: QuoteModalProps) {
  const agentInfo = store.useState(s => s.agentInfo)
  const { success } = agentInfo
  return (
    <Modal
      open={modalOpen}
      onRequestClose={() => setModalOpen(false)}
      testId="auto-quote"
    >
      <div className="w-64 grins-sm:w-96 bg-white rounded-xl flex-col flex justify-center p-5">
        <button
          autoFocus={true}
          onClick={() => setModalOpen(false)}
          className="absolute right-5 top-5 cursor-pointer"
        >
          <span aria-hidden>X</span> <span className="sr-only">close</span>
        </button>
        <div>
          {image}
          <p className="font-bold text-4xl text-center pt-10">
            {formatCurrency(monthlyPremium)} /mo
          </p>
          <div className="py-5">
            <hr className="border-t border border-gr-text-color" />
          </div>
          <p className="self-center font-semibold text-lg text-center">
            Our agent will be in touch shortly to finalize your quote.
          </p>
          <br />
          <p className="self-center text-center text-xl font-bold">
            {`Can’t wait? You can give ${success ? "me" : "us"} a `} <br />
            {`call now at `}
            <PhoneAnchorTag />
          </p>
        </div>
      </div>
    </Modal>
  )
}
