"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapOmsiteAutoToQuoteServiceQuoteRequest = exports.mapOmsiteFrontEndDataToQuoteServiceQuoteRequest = void 0;
const quote_service_types_1 = require("./non-normal-types/quote-service-types");
function mapOmsiteFrontEndDataToQuoteServiceQuoteRequest(omsiteFrontEndData, grinsLeadId) {
    const aptSuiteNumber = omsiteFrontEndData.property.address.aptSuiteNumber;
    const applicantPriorAddress = omsiteFrontEndData.applicant.effectivePriorAddress;
    const applicantPriorAddressSuiteNumber = applicantPriorAddress?.unitNumber.trim() ?? null;
    return {
        policy_type: quote_service_types_1.QuoteServicePolicyType.ho3,
        home: {
            applicant: {
                first_name: omsiteFrontEndData.applicant.firstName,
                last_name: omsiteFrontEndData.applicant.lastName,
                date_of_birth: omsiteFrontEndData.applicant.dateOfBirth,
                phone_number: omsiteFrontEndData.applicant.phoneNumber,
                marital_status: omsiteFrontEndData.applicant.maritalStatus,
                ...(applicantPriorAddress && {
                    effective_prior_address: {
                        city: applicantPriorAddress.city,
                        state: applicantPriorAddress.state,
                        street_name: applicantPriorAddress.streetName,
                        street_number: applicantPriorAddress.streetNumber,
                        zip: applicantPriorAddress.zipCode5,
                        ...(applicantPriorAddressSuiteNumber && {
                            apt_suite_number: applicantPriorAddressSuiteNumber,
                        }),
                    },
                }),
            },
            //omsite uses the coApplicant.exists flag to flag the coApplicant data as being populated or not
            ...(omsiteFrontEndData.coApplicant.exists && {
                co_applicant: {
                    first_name: omsiteFrontEndData.coApplicant.firstName,
                    last_name: omsiteFrontEndData.coApplicant.lastName,
                    date_of_birth: omsiteFrontEndData.coApplicant.dateOfBirth,
                    relation_to_applicant: omsiteFrontEndData.coApplicant.relationshipToApplicant || null,
                    //we use the applicant's maritalStatus due to the decision to not collect coApplicant marital
                    //status in the omsite application
                    marital_status: omsiteFrontEndData.applicant.maritalStatus,
                },
            }),
            insured_property: {
                street_name: omsiteFrontEndData.property.address.streetName,
                street_number: omsiteFrontEndData.property.address.streetNumber,
                city: omsiteFrontEndData.property.address.city,
                state: omsiteFrontEndData.property.address.state,
                zip: omsiteFrontEndData.property.address.zip,
                purchase_date: omsiteFrontEndData.property.purchaseDate,
                ...(aptSuiteNumber && { apt_suite_number: aptSuiteNumber }),
            },
        },
        lead_id: grinsLeadId,
        prompt_answers: omsiteFrontEndData.promptAnswers,
    };
}
exports.mapOmsiteFrontEndDataToQuoteServiceQuoteRequest = mapOmsiteFrontEndDataToQuoteServiceQuoteRequest;
function mapOmsiteAutoToQuoteServiceQuoteRequest(clientAutoApplication, grinsLeadId) {
    //check for required auto quote fields
    if (!clientAutoApplication.primaryDriver.dateOfBirth) {
        throw new Error(`Omsite Auto to Quote Service Request mapping failed. Primary driver has no date of birth.`);
    }
    if (!clientAutoApplication.primaryDriver.maritalStatus) {
        throw new Error(`Omsite Auto to Quote Service Request mapping failed. Primary driver has no marital status.`);
    }
    if (!clientAutoApplication.primaryDriver.gender) {
        throw new Error(`Omsite Auto to Quote Service Request mapping failed. Primary driver object has no gender value.`);
    }
    if (!clientAutoApplication.property.address.state) {
        throw new Error("Omsite Auto to Quote Service Request mapping failed.  Address state has no value.");
    }
    return {
        policy_type: quote_service_types_1.QuoteServicePolicyType.auto,
        lead_id: grinsLeadId,
        auto: {
            primary_driver: {
                first_name: clientAutoApplication.primaryDriver.firstName,
                last_name: clientAutoApplication.primaryDriver.lastName,
                date_of_birth: clientAutoApplication.primaryDriver.dateOfBirth?.toString(),
                phone_number: clientAutoApplication.primaryDriver.phoneNumber,
                marital_status: clientAutoApplication.primaryDriver.maritalStatus,
                gender: clientAutoApplication.primaryDriver.gender,
            },
            additional_drivers: clientAutoApplication.additionalDrivers.map(driver => {
                return {
                    first_name: driver.firstName,
                    last_name: driver.lastName,
                    date_of_birth: driver.dateOfBirth?.toString() || "",
                    gender: driver.gender || "",
                    phone_number: driver.phoneNumber,
                    marital_status: driver.maritalStatus || "",
                };
            }),
            vehicles: clientAutoApplication.vehicles.map(vehicle => {
                return {
                    details: {
                        ownership: vehicle.details.ownership,
                        usage: vehicle.details.usage,
                        miles: vehicle.details.miles,
                    },
                    year_make_model: {
                        year: vehicle.ymm.year,
                        make: vehicle.ymm.make,
                        model: vehicle.ymm.model,
                        sub_model: vehicle.ymm.subModel,
                    },
                };
            }),
            property: {
                street_number: clientAutoApplication.property.address.streetNumber,
                street_name: clientAutoApplication.property.address.streetName,
                city: clientAutoApplication.property.address.city,
                state: clientAutoApplication.property.address.state,
                zip: clientAutoApplication.property.address.zip,
                apt_suite_number: clientAutoApplication.property.address.aptSuiteNumber,
                purchase_date: clientAutoApplication.property.purchaseDate?.toString() || "",
            },
        },
    };
}
exports.mapOmsiteAutoToQuoteServiceQuoteRequest = mapOmsiteAutoToQuoteServiceQuoteRequest;
