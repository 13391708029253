"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PalomarFoundationTypeEnum = exports.PalomarHeritageConstructionTypeEnum = exports.PalomarFlexChoiceConstructionTypeEnum = void 0;
var PalomarFlexChoiceConstructionTypeEnum;
(function (PalomarFlexChoiceConstructionTypeEnum) {
    PalomarFlexChoiceConstructionTypeEnum["woodMetalFrame"] = "Wood / Metal Frame";
    PalomarFlexChoiceConstructionTypeEnum["masonryVeneer"] = "Masonry Veneer";
    PalomarFlexChoiceConstructionTypeEnum["reinforcedMasonry"] = "Reinforced Masonry";
    PalomarFlexChoiceConstructionTypeEnum["unreinforcedMasonry"] = "Unreinforced Masonry (Brick)";
})(PalomarFlexChoiceConstructionTypeEnum || (exports.PalomarFlexChoiceConstructionTypeEnum = PalomarFlexChoiceConstructionTypeEnum = {}));
var PalomarHeritageConstructionTypeEnum;
(function (PalomarHeritageConstructionTypeEnum) {
    PalomarHeritageConstructionTypeEnum["woodFrame"] = "Wood Frame";
    PalomarHeritageConstructionTypeEnum["brickVeneerOnWoodFrame"] = "Brick Veneer on Wood Frame";
    PalomarHeritageConstructionTypeEnum["steel"] = "steel";
    PalomarHeritageConstructionTypeEnum["stuccoFrame"] = "Stucco/Frame";
    PalomarHeritageConstructionTypeEnum["masonry"] = "Masonry";
    PalomarHeritageConstructionTypeEnum["other"] = "other";
})(PalomarHeritageConstructionTypeEnum || (exports.PalomarHeritageConstructionTypeEnum = PalomarHeritageConstructionTypeEnum = {}));
var PalomarFoundationTypeEnum;
(function (PalomarFoundationTypeEnum) {
    PalomarFoundationTypeEnum["slab"] = "Slab";
    PalomarFoundationTypeEnum["crawlSpaceWithConcrete"] = "Crawl Space With Concrete";
    PalomarFoundationTypeEnum["basement"] = "Basement";
    PalomarFoundationTypeEnum["other"] = "other";
})(PalomarFoundationTypeEnum || (exports.PalomarFoundationTypeEnum = PalomarFoundationTypeEnum = {}));
