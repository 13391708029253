"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapOneLoanResponseToQuoteServiceHomeApplication = exports.extractMaritalStatusFromOneLoanData = void 0;
const luxon_1 = require("luxon");
const _1 = require(".");
const oneloan_to_myaccount_home_lead_1 = require("./oneloan-to-myaccount-home-lead");
const grins_utils_1 = require("grins-utils");
function extractMaritalStatusFromOneLoanData(person) {
    let maritalStatus;
    switch (person?.maritalStatusType) {
        case "Unmarried":
            maritalStatus = _1.EzLynxMaritalStatus.single;
            break;
        case "Married":
            maritalStatus = _1.EzLynxMaritalStatus.married;
            break;
        case "Separated":
            maritalStatus = _1.EzLynxMaritalStatus.separated;
            break;
        default:
            maritalStatus = _1.EzLynxMaritalStatus.single;
    }
    return maritalStatus;
}
exports.extractMaritalStatusFromOneLoanData = extractMaritalStatusFromOneLoanData;
function mapOneLoanResponseToQuoteServiceHomeApplication(oneLoanData) {
    if (!oneLoanData.loan.applications ||
        oneLoanData.loan.applications.length < 1)
        throw new Error(`OneLoan data is missing application data.`);
    if (!oneLoanData.loan.applications[0].borrower)
        throw new Error(`Borrower is missing from OneLoan data.`);
    const borrower = oneLoanData.loan.applications[0].borrower;
    if (!borrower.firstName || borrower.firstName.trim() === "")
        throw new Error(`Borrower.firstName data is missing or blank.`);
    if (!borrower.lastName || borrower.lastName.trim() === "")
        throw new Error(`Borrower.lastName data is missing or blank.`);
    if (!borrower.birthDate || borrower.birthDate.trim().length <= 0) {
        throw new Error(`Borrower birth date is blank or undefined`);
    }
    const luxonDob = luxon_1.DateTime.fromJSDate(new Date(borrower.birthDate), {
        zone: "utc",
    });
    if (luxonDob.invalidReason) {
        throw new Error(`Borrower DOB error: ${luxonDob.invalidReason}`);
    }
    const coBorrower = oneLoanData.loan.applications[0].coborrower;
    const luxonCoBorrowerDob = coBorrower?.birthDate
        ? luxon_1.DateTime.fromJSDate(new Date(coBorrower.birthDate), {
            zone: "utc",
        })
        : undefined;
    //don't map co-borrower unless we have first_name, last_name and a valid dob
    const validCoBorrower = coBorrower &&
        coBorrower.noCoApplicantIndicator !== true &&
        coBorrower.firstName &&
        coBorrower.lastName;
    if (!oneLoanData.loan.hmda)
        throw new Error(`HMDA data is missing from OneLoan data.`);
    const hmda = oneLoanData.loan.hmda;
    if (!hmda.hmdaPropertyAddress || hmda.hmdaPropertyAddress.trim() === "")
        throw new Error(`Address is missing from OneLoan data.`);
    const { streetName, streetNum, unitNumber } = (0, grins_utils_1.parseAddress)(hmda.hmdaPropertyAddress);
    if (!oneLoanData.loan.loanProductData)
        throw new Error(`Loan Product is missing from OneLoan data.`);
    const loanProductData = oneLoanData.loan.loanProductData;
    const closeDateString = loanProductData.loanScheduledClosingDate ??
        luxon_1.DateTime.utc().toFormat("yyyy-MM-dd");
    const luxonPurchaseDate = luxon_1.DateTime.fromJSDate(new Date(closeDateString), {
        zone: "utc",
    });
    const effectivePriorAddress = (0, oneloan_to_myaccount_home_lead_1.getEffectivePriorAddress)(oneLoanData);
    const loanPurchasePriceAmount = oneLoanData.loan.purchasePriceAmount;
    const quoteServiceHomeApplication = {
        applicant: {
            first_name: borrower.firstName,
            last_name: borrower.lastName,
            date_of_birth: luxonDob.toFormat("yyyy-MM-dd"),
            phone_number: borrower.mobilePhone || borrower.homePhoneNumber || "",
            ...(borrower.emailAddressText && { email: borrower.emailAddressText }),
            marital_status: extractMaritalStatusFromOneLoanData(borrower),
            ...(effectivePriorAddress && {
                effective_prior_address: {
                    city: effectivePriorAddress.city,
                    state: effectivePriorAddress.state,
                    street_name: effectivePriorAddress.streetName,
                    street_number: effectivePriorAddress.streetNumber,
                    zip: effectivePriorAddress.zipCode5,
                    apt_suite_number: effectivePriorAddress.unitNumber,
                },
            }),
        },
        ...(validCoBorrower && {
            co_applicant: {
                first_name: coBorrower.firstName || "",
                last_name: coBorrower.lastName || "",
                date_of_birth: luxonCoBorrowerDob && !luxonCoBorrowerDob.invalidReason
                    ? luxonCoBorrowerDob.toFormat("yyyy-MM-dd")
                    : undefined,
                phone_number: coBorrower.mobilePhone || coBorrower.homePhoneNumber || "",
                marital_status: extractMaritalStatusFromOneLoanData(coBorrower),
                relation_to_applicant: borrower.maritalStatusType?.toString() === "Married" &&
                    coBorrower.maritalStatusType?.toString() === "Married"
                    ? "Spouse"
                    : "Other",
            },
        }),
        insured_property: {
            street_number: streetNum,
            street_name: streetName,
            city: hmda.hmdaPropertyCity || "",
            zip: hmda.hmdaPropertyZipCode || "",
            state: hmda.hmdaPropertyState || "",
            apt_suite_number: unitNumber,
            //county:  //not available on insured property in loan data, and not needed for getting property facts
            purchase_date: !luxonPurchaseDate.invalidReason
                ? luxonPurchaseDate.toFormat("yyyy-MM-dd")
                : "",
            purchase_amount: loanPurchasePriceAmount,
        },
    };
    return quoteServiceHomeApplication;
}
exports.mapOneLoanResponseToQuoteServiceHomeApplication = mapOneLoanResponseToQuoteServiceHomeApplication;
