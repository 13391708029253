import { useRef, useState } from "react"
import { AutoQuote, getCarrierData } from "grid-transform"
import { Button } from "grins-ui"
import AutoQuoteDetails from "./auto-quote-details"
import { formatCurrency } from "grins-utils"
import QuoteModal from "./quote-modal"
import { store } from "../store"

export interface Props {
  autoQuote: AutoQuote
}

// expansion animation taken from https://www.w3schools.com/howto/howto_js_accordion.asp
export default function AutoQuoteSummaryBox({ autoQuote }: Props) {
  const carriersData = store.useState(s => s.carriers)
  const [modalOpen, setModalOpen] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const detailsPanelRef = useRef<HTMLDivElement>(null)
  const headerId = useRef<string>(`header-${autoQuote.quoteId}`)
  const panelId = useRef<string>(`panel-${autoQuote.quoteId}`)

  const toggleExpanded = () => {
    setExpanded(!expanded)
    const panel = detailsPanelRef.current
    if (!panel) return
    if (expanded) panel.style.maxHeight = ""
    else panel.style.maxHeight = `${panel.scrollHeight}px`
  }

  const renderLogo = () => {
    const logo = getCarrierData(autoQuote.carrierId, carriersData)
    if (!logo) return <span>unknown carrier</span>
    return (
      <img
        alt={logo.name}
        src={logo.logo_url}
        data-testid="quote-carrier-logo"
      />
    )
  }

  const renderCost = () => (
    <span className="font-bold text-4xl sm:text-2xl self-center mt-10 mb-5 sm:mt-0 sm:mb-0">
      <span className="sr-only">Premium </span>
      {formatCurrency(autoQuote.monthlyPremium ?? 0)} /mo
    </span>
  )

  const renderExpandButton = () => {
    const chevronUrl = expanded
      ? "/public/chevron-up.svg"
      : "/public/chevron-down.svg"
    return (
      <button
        data-testid="view-details-btn"
        className="text-sm"
        aria-controls={panelId.current}
        aria-expanded={expanded}
        onClick={toggleExpanded}
      >
        View details <img className="inline" aria-hidden src={chevronUrl} />
      </button>
    )
  }

  const renderDetails = () => (
    <div className="mt-4 pt-6 border-t border-gray-300 sm:px-0 px-4">
      <AutoQuoteDetails autoQuote={autoQuote} />
    </div>
  )

  const renderPanel = () => (
    <div
      id={panelId.current}
      ref={detailsPanelRef}
      className="max-h-0 overflow-hidden transition-[max-height] ease-out duration-200"
      role="region"
      aria-hidden={!expanded}
      aria-labelledby={headerId.current}
    >
      {renderDetails()}
    </div>
  )

  return (
    <div className="my-6 p-3 sm:p-x7 sm:p-y4 w-full border bg-white border-gr-disabled-grey rounded-xl shadow-lg flex flex-col justify-center">
      <div
        id={headerId.current}
        className="flex flex-col sm:flex-row  items-center justify-between"
      >
        {renderLogo()}
        <div className="flex gap-2 sm:gap-5 flex-col sm:flex-row">
          {renderCost()}
          <div className="grid gap-4 grid-flow-row auto-rows-max">
            <Button
              data-testid="select-auto-quote-btn"
              size="xs-small-square"
              label="Select"
              onClick={() => setModalOpen(true)}
            />
            {renderExpandButton()}
          </div>
        </div>
      </div>
      {renderPanel()}
      <QuoteModal
        monthlyPremium={autoQuote.monthlyPremium ?? 0}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        image={renderLogo()}
      />
    </div>
  )
}
