import * as rudderAnalytics from "rudder-sdk-js"

export interface EmitClientEventPageLoadProps {
  title: string
  path: string
  properties?: rudderAnalytics.apiObject
  rudderStackUrl: string
  rudderStackWriteKey: string
}

/**
 * Emits a client event for page load using RudderStack.
 * @param title - The title of the page.
 * @param path - The path of the page.
 * @param properties - Additional properties to include in the event.
 * @param rudderStackUrl - The URL of the RudderStack instance.
 * @param rudderStackWriteKey - The write key for the RudderStack instance.
 * @returns void
 * @throws An error if the RudderStack write key or URL config values are missing.
 */
export function emitClientEventPageLoad({
  title,
  path,
  properties = {},
  rudderStackUrl,
  rudderStackWriteKey,
}: EmitClientEventPageLoadProps): void {
  const missingRudderStackConfigs =
    !rudderStackUrl.length || !rudderStackWriteKey.length

  if (missingRudderStackConfigs) {
    throw new Error(
      "RudderStack write key and/or URL config values are missing"
    )
  }

  rudderAnalytics.load(rudderStackWriteKey, rudderStackUrl)

  rudderAnalytics.ready(handleRudderStackEvent(title, path, properties))
}

/**
 * Returns a function that handles the event for RudderStack.
 * @param pageTitle - The title of the page.
 * @param pagePath - The path of the page.
 * @param properties - Additional properties to include in the event.
 * @returns A function that handles the event for RudderStack.
 */
function handleRudderStackEvent(
  pageTitle: EmitClientEventPageLoadProps["title"],
  pagePath: EmitClientEventPageLoadProps["path"],
  properties: EmitClientEventPageLoadProps["properties"]
): () => void {
  return () =>
    rudderAnalytics.page({
      title: pageTitle,
      path: pagePath,
      ...properties,
    })
}
