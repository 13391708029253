"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapQuoteServiceApplicantToBranch = void 0;
const utils_1 = require("../branch/utils");
const luxon_1 = require("luxon");
/**
 * @description Takes in a an application object and returns a JSON object that can be used to prefill a branch quote request.
 * @param {ApplicantProp} applicant - Application object.
 * @returns {BranchHomeApplicant} JSON object that can be used to prefill a branch quote request.
 */
function mapQuoteServiceApplicantToBranch(application) {
    let formattedApplication;
    if ("applicant" in application) {
        formattedApplication =
            privateHelpers._generateApplicationFromHomeObject(application);
    }
    else {
        formattedApplication = _generateApplicationFromApplicantObject(application);
    }
    return {
        ...formattedApplication,
        dob: _generateDateOfBirth(formattedApplication.dob),
        homeCloseDate: _generateHomeCloseDate(formattedApplication.homeCloseDate),
    };
}
exports.mapQuoteServiceApplicantToBranch = mapQuoteServiceApplicantToBranch;
/**
 * @private
 * @description Takes in a an application object derived from a /quotes/start home object and returns a JSON object that can be used to prefill a branch quote request.
 * @param {QuoteServiceHomeApplication} application - Application object.
 * @returns {BranchHomeApplicant} JSON object that can be used to prefill a branch quote request.
 */
function _generateApplicationFromHomeObject(application) {
    const address = `${application.insured_property.street_number} ${application.insured_property.street_name}`;
    return {
        address,
        city: application.insured_property.city,
        dob: application.applicant.date_of_birth || "",
        fname: application.applicant.first_name,
        homeCloseDate: application.insured_property.purchase_date,
        lname: application.applicant.last_name,
        priorAddress: {
            address: application.applicant.effective_prior_address?.street_name ?? "",
            city: application.applicant.effective_prior_address?.city ?? "",
            state: application.applicant.effective_prior_address?.state ?? "",
            zip: application.applicant.effective_prior_address?.zip ?? "",
        },
        state: application.insured_property.state,
        zip: application.insured_property.zip,
    };
}
/**
 * @private
 * @description Takes in a an application object derived from a oneLoan object and returns a JSON object that can be used to prefill a branch quote request.
 * @param {Applicant} application - Application object.
 * @returns {BranchHomeApplicant} JSON object that can be used to prefill a branch quote request.
 */
function _generateApplicationFromApplicantObject(applicant) {
    const address = `${applicant.street_number} ${applicant.street_name}`;
    return {
        address,
        city: applicant.city,
        dob: applicant.date_of_birth || "",
        fname: applicant.first_name,
        homeCloseDate: applicant.purchase_date,
        lname: applicant.last_name,
        priorAddress: {
            address: applicant.priorAddress?.address ?? "",
            city: applicant.priorAddress?.city ?? "",
            state: applicant.priorAddress?.state ?? "",
            zip: applicant.priorAddress?.zip ?? "",
        },
        state: applicant.state,
        zip: applicant.zip,
    };
}
/**
 * @private
 * @description Takes in a date of birth string, validates the date, and returns a string in the format "yyyy-MM-dd".
 * @param {string} dob - Date of birth string.
 * @returns {string} Date of birth string in the format "yyyy-MM-dd".
 */
function _generateDateOfBirth(dob) {
    if (!dob) {
        return null;
    }
    const dateOfBirth = new Date(dob);
    const luxonDob = luxon_1.DateTime.fromJSDate(dateOfBirth, { zone: "utc" });
    if (luxonDob.invalidReason) {
        throw new Error(`Applicant DOB error: ${luxonDob.invalidReason}`);
    }
    const formattedDob = luxonDob.toFormat("yyyy-MM-dd");
    return formattedDob;
}
/**
 * @private
 * @description Takes in a home close date string, validates the date, and returns a string in the format "yyyy-MM-dd".
 * @param {string} homeCloseDate - Home close date string.
 * @returns {string} Home close date string in the format "yyyy-MM-dd".
 */
function _generateHomeCloseDate(homeCloseDate) {
    return (0, utils_1.toBicEffectiveHomeCloseDate)(homeCloseDate ?? "");
}
const privateHelpers = {
    _generateApplicationFromHomeObject,
    _generateApplicationFromApplicantObject,
    _generateDateOfBirth,
    _generateHomeCloseDate,
};
exports.default = privateHelpers;
