import { ProgressSteps } from "grins-ui"
import { store } from "../store"

export function ProgressWidget() {
  const progress = store.useState(s => s.progress)

  return (
    <div>
      <div className="hidden grins-md:block grins-md:rounded-md">
        <ProgressSteps {...progress} />
      </div>
    </div>
  )
}
