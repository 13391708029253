"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toInput = exports.QuoteRequest = void 0;
const luxon_1 = require("luxon");
const non_normal_types_1 = require("../../non-normal-types");
const getDriverGenderForEzLynx = (gender) => {
    return gender.toLowerCase() === non_normal_types_1.OmsiteGender.Female.toLowerCase()
        ? non_normal_types_1.OmsiteGender.Female
        : non_normal_types_1.OmsiteGender.Male;
};
exports.QuoteRequest = {
    Auto: {
        /**
         * @summary Converts GRINS Data Model data (for auto quotes request) to EZLynx format
         * @param grinsDmData
         * @returns {GridTransformers.Ezlynx.Types.AutoSubmitQuoteTemplateAsJson}
         */
        toInput,
    },
};
/**
 * @summary Converts GRINS Data Model data (for auto quotes request) to EZLynx format
 * @param grinsDmData
 * @returns {GridTransformers.Ezlynx.Types.AutoSubmitQuoteTemplateAsJson}
 */
function toInput(grinsDmData) {
    const combinedDrivers = [
        grinsDmData.primary_driver,
        ...grinsDmData.additional_drivers,
    ];
    const allDrivers = combinedDrivers.map((driver, i) => {
        let vehicleNumber = i + 1;
        if (grinsDmData.vehicles.length < vehicleNumber) {
            vehicleNumber = grinsDmData.vehicles.length;
        }
        return {
            Name: { FirstName: driver.first_name, LastName: driver.last_name },
            Gender: getDriverGenderForEzLynx(driver.gender),
            DOB: driver.date_of_birth.substring(0, 10),
            DLState: grinsDmData.property.state || "",
            DateLicensed: luxon_1.DateTime.fromJSDate(new Date(driver.date_of_birth.substring(0, 10)))
                .plus({ years: 16 })
                .toISODate() || "",
            DLStatus: "Valid",
            AgeLicensed: 16,
            MaritalStatus: "Married",
            PrincipalVehicle: vehicleNumber,
        };
    });
    const allVehicles = grinsDmData.vehicles.map(vehicle => {
        return {
            UseVinLookup: "No",
            Year: Number(vehicle.year_make_model.year),
            Vin: vehicle.year_make_model.sub_model.vin,
            Make: vehicle.year_make_model.make,
            Model: vehicle.year_make_model.model,
            SubModel: vehicle.year_make_model.sub_model.description,
        };
    });
    const allVehiclesUse = grinsDmData.vehicles.map((vehicle, i) => {
        let operatorNumber = i + 1;
        if (combinedDrivers.length < operatorNumber) {
            operatorNumber = 1;
        }
        return {
            Useage: vehicle.details.usage, // although prop name is a typo, it's what EZLynx expects
            OneWayMiles: 45,
            DaysPerWeek: 5,
            WeeksPerMonth: 4,
            AnnualMiles: vehicle.details.miles,
            Ownership: vehicle.details.ownership,
            PrincipalOperator: operatorNumber,
        };
    });
    const allVehicleAssignment = grinsDmData.vehicles.map((_, i) => {
        const VehicleAssignment = {
            VehicleAssignment: {
                id: i + 1,
                DriverAssignment: combinedDrivers.map((_, i) => {
                    return {
                        id: i + 1,
                        value: i === 0 ? 100 : 0,
                    };
                }),
            },
        };
        return VehicleAssignment;
    });
    const { first_name, last_name, date_of_birth, gender, marital_status, phone_number, } = grinsDmData.primary_driver;
    const { street_number, street_name, city, state, zip, county } = grinsDmData.property;
    /*
      ❗️NOTE:
      Sept 2022
      Below, most, if not all, hard coded values (and some self-contained dynamic values) were carried over from Omsite.
      Hard coded values should probably not be hard coded at some point.
    */
    return {
        Applicant: {
            ApplicantType: "Applicant",
            PersonalInfo: {
                Name: {
                    FirstName: first_name,
                    LastName: last_name,
                },
                DOB: date_of_birth.substring(0, 10),
                Gender: getDriverGenderForEzLynx(gender),
                MaritalStatus: marital_status,
            },
            Address: {
                AddressCode: "StreetAddress",
                Addr1: {
                    StreetName: street_name,
                    StreetNumber: street_number,
                },
                City: city,
                StateCode: state ?? "",
                /*
                  TODO we want to validate county (like we do in omsite), but we can't have this, grid-transform, library
                  have a dependency to ezlynx-client--lest we end up with circular dependencies.
        
                  omsite code at: https://github.com/Guaranteed-Rate/grins-mono/blob/1b615ad0cf38fd88b71f0d90933a0d1621d212c9/packages/omsite/src/server/utils/omsite-to-ezlynx.ts#L181-L207
                */
                County: county ?? "",
                Zip5: zip,
                Phone: {
                    PhoneType: "Home", // straight from omsite
                    PhoneNumber: phone_number,
                },
                Validation: "Valid",
            },
        },
        PriorPolicyInfo: {
            PriorCarrier: "Other Standard",
            Expiration: luxon_1.DateTime.fromJSDate(new Date()).plus({ days: 7 }).toISODate() || "",
            PriorPolicyTerm: "6 Month",
        },
        PolicyInfo: {
            PolicyTerm: "12 Month",
            Package: "No",
            Effective: luxon_1.DateTime.fromJSDate(new Date()).plus({ days: 7 }).toISODate() || "",
            CreditCheckAuth: "Yes",
        },
        ResidenceInfo: {
            CurrentAddress: {
                YearsAtCurrent: {
                    Years: 3,
                    Months: 0,
                },
                Ownership: "Apartment",
            },
        },
        Drivers: allDrivers,
        Vehicles: allVehicles,
        VehiclesUse: allVehiclesUse,
        VehicleAssignments: allVehicleAssignment,
        GeneralInfo: {
            RatingStateCode: state ?? "",
        },
    };
}
exports.toInput = toInput;
