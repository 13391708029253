import { Button, RadioButton } from "grins-ui"
import PageHeader from "../../components/page-header"
import { useState } from "react"
import { usePageLoadEvent } from "../../utils/session"
import { store } from "../../store"

export interface Props {
  onYes: () => void
  onNo: () => void
}

export default function AddVehicle({ onYes, onNo }: Props) {
  usePageLoadEvent({
    page_path: "/autoflow/add-vehicle",
    page_title: "Auto Add Vehicle",
  })

  const [selectedRadioButton, setSelectedRadioButton] = useState<string>("")
  const [isLoading, setIsLoading] = useState(false)
  const { property } = store.useState(s => s.autoApplication)

  const isStateCalifornia = property.address.state === "CA"
  const businessName = isStateCalifornia
    ? "Rate Insurance Agency, LLC"
    : "Rate Insurance"

  const numberOfVehiclesInApplication =
    store.getRawState().autoApplication.vehicles.length

  return (
    <div>
      <PageHeader
        testId="add-vehicle-header-h2"
        className={`text-white ${
          numberOfVehiclesInApplication >= 2 ? "grins-lg:max-w-sm" : ""
        } grins-md:text-4xl grins-lg:text-5xl`}
      >
        Would you like to add another vehicle?
      </PageHeader>
      <div className="flex flex-col mt-6 gap-6 w-80 max-w-sm">
        <RadioButton
          data-testid="add-vehicle-yes-radio-btn"
          name="add-vehicle"
          label="Yes"
          value="Yes"
          checked={selectedRadioButton === "Yes"}
          onChange={() => setSelectedRadioButton("Yes")}
        />

        <RadioButton
          data-testid="add-vehicle-no-radio-btn"
          name="add-vehicle"
          label="No, that's all"
          value="No"
          checked={selectedRadioButton === "No"}
          onChange={() => setSelectedRadioButton("No")}
        />
      </div>
      <div
        className="my-8 text-2xs text-white font-normal leading-3 max-w-md"
        data-testid="add-vehicle-disclaimer-div"
      >
        {`To provide accurate quotes, some of the insurance companies we represent
        will confirm your information through a consumer credit report. By
        clicking "Next" you authorize ${businessName} permission to
        order your credit information.`}
      </div>
      <div className="mt-14">
        <Button
          data-testid="next-btn"
          size="large-square"
          label="Next"
          disabled={selectedRadioButton === ""}
          onClick={() => {
            selectedRadioButton === "Yes" ? onYes() : onNo()
            setIsLoading(true)
          }}
          loading={isLoading}
        />
      </div>
    </div>
  )
}
