import CongratsErrorPageContent from "../components/congrats-error-page-content"
import { usePageLoadEvent } from "../utils/session"

export default function ErrorPage() {
  usePageLoadEvent({
    page_path: "/error",
    page_title: "Error",
  })

  return <CongratsErrorPageContent />
}
