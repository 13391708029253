"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OmsiteAutoUsage = exports.OmsiteAutoOwnership = void 0;
var OmsiteAutoOwnership;
(function (OmsiteAutoOwnership) {
    OmsiteAutoOwnership["owned"] = "Owned";
    OmsiteAutoOwnership["leased"] = "Leased";
    OmsiteAutoOwnership["lien"] = "Lien";
})(OmsiteAutoOwnership || (exports.OmsiteAutoOwnership = OmsiteAutoOwnership = {}));
var OmsiteAutoUsage;
(function (OmsiteAutoUsage) {
    OmsiteAutoUsage["business"] = "Business";
    OmsiteAutoUsage["farming"] = "Farming";
    OmsiteAutoUsage["pleasure"] = "Pleasure";
    OmsiteAutoUsage["toFromWork"] = "To/From Work";
    OmsiteAutoUsage["toFromSchool"] = "To/From School";
})(OmsiteAutoUsage || (exports.OmsiteAutoUsage = OmsiteAutoUsage = {}));
