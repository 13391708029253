import { Button, EmailInput, PhoneInput, TextInput } from "grins-ui"
import PageHeader from "../../components/page-header"
import { store } from "../../store"
import { usePageLoadEvent } from "../../utils/session"

interface ReferralPageProps {
  onNext: () => void
}

export default function ReferralPage({ onNext }: ReferralPageProps) {
  const agentInfo = store.useState(s => s.agentInfo)
  const { isLoading: isAgentInfoLoading } = agentInfo
  usePageLoadEvent({
    page_path: "/referral/applicantinfo",
    page_title: "Referral Info",
  })

  const isSubmitting = store.useState(s => s.isSubmitting)
  const inputState = store.useState(s => s.referralInfo)

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    inputKey: "firstName" | "lastName"
  ) => {
    store.update(s => {
      s.referralInfo[inputKey] = e.target.value
    })
  }

  const handlePhoneChange = (value: string) => {
    store.update(s => {
      s.referralInfo.phoneNumber = value
    })
  }

  const handleEmailChange = (value: string) => {
    store.update(s => {
      s.referralInfo.emailAddress = value
    })
  }

  const shouldButtonBeDisabled = () => {
    const { firstName, lastName, phoneNumber } = inputState

    if (
      !isAgentInfoLoading &&
      firstName.length > 0 &&
      lastName.length > 0 &&
      phoneNumber.length === 10
    ) {
      return false
    }
    return true
  }

  return (
    <div className="w-full flex flex-col justify-between h-full">
      <div>
        <PageHeader testId="referral-header-h2">
          <div className="text-2xl grins-md:text-4xl text-white">
            Someone you know had a great experience with us!
          </div>
          <div className="text-xs font-normal leading-5 grins-md:leading-6 grins-md:text-sm mt-5">
            On average we save new customers $760,* and we'd like to see what we
            can do for you too. Fill out this free form,
            {agentInfo.success
              ? ` and I'll be reaching out soon.`
              : ` and one of our agents will be reaching out soon.`}
          </div>
        </PageHeader>
        <div className="max-w-xs">
          <TextInput
            data-testid="first-name-input"
            className="mt-7 mb-6"
            label="First name"
            value={inputState.firstName}
            onChange={e => handleChange(e, "firstName")}
          />
          <TextInput
            data-testid="last-name-input"
            className="my-6"
            label="Last name"
            value={inputState.lastName}
            onChange={e => handleChange(e, "lastName")}
          />
          <PhoneInput
            data-testid="phone-number-input"
            className="my-6"
            value={inputState.phoneNumber}
            label="Phone number"
            onChange={handlePhoneChange}
          />
          <EmailInput
            data-testid="email-input"
            className="my-6"
            value={inputState.emailAddress || ""}
            label={"Email address (optional)"}
            onChange={handleEmailChange}
          />
        </div>
      </div>
      <div>
        <div className="mt-6 mb-10 text-2xs pr-10 text-light-grey font-normal leading-3 bg-blend-overlay">
          By entering your email address and submitting this form, you are
          providing express written consent for Rate Insurance and its
          affiliates, agents, & service providers to contact you at that number
          regarding products or services, including via auto-dialed and/or
          prerecorded or artificial voice calls and text messages (SMS and MMS),
          even if your telephone number is a cellular number or on a corporate,
          state or the National Do Not Call Registry (DNC) or other do not
          contact list. Message and data rates may apply, and calls may be
          recorded and/or monitored. To opt out, email dncrateins@rate.com or
          call (917) 877-1599.
          <br />
          <br />* Source: Rate Insurance internal survey data of average savings
          by new customers who saved from November 2021-January 2022.
        </div>
        <Button
          data-testid="next-btn"
          size="large-square"
          label="Next"
          loading={isSubmitting}
          disabled={shouldButtonBeDisabled()}
          onClick={onNext}
        />
      </div>
    </div>
  )
}
