"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.allowCors = void 0;
/**
 * @summary Koa middleware to allow all cors
 *
 * What's set:
 *
 * "Access-Control-Allow-Origin", "*"
 *
 * "Access-Control-Allow-Methods", "GET, POST, PUT, DELETE, OPTIONS"
 *
 * "Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept, Authorization"
 *
 * @example app.use(allowCors)
 */
const allowCors = async (ctx, next) => {
    ctx.set("Access-Control-Allow-Origin", "*");
    ctx.set("Access-Control-Allow-Methods", "GET, POST, PUT, DELETE, OPTIONS");
    ctx.set("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept, Authorization, X-API-KEY");
    if (ctx.request.method === "OPTIONS") {
        ctx.response.status = 200;
        ctx.response.message = "OK";
    }
    else {
        await next();
    }
};
exports.allowCors = allowCors;
