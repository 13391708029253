"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DateInput = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const __1 = require("..");
const luxon_1 = require("luxon");
function DateInput({ allowFutureDates = false, onDateComplete, valueOverride, testId, includeDayInput = true, }) {
    const [year, setYear] = (0, react_1.useState)(valueOverride?.year.toString() || "");
    const [month, setMonth] = (0, react_1.useState)(valueOverride?.month.toString() || "");
    const [day, setDay] = (0, react_1.useState)(valueOverride?.day.toString() || "");
    const [error, setError] = (0, react_1.useState)(false);
    const [dispatchedDate, setDispatchedDate] = (0, react_1.useState)(valueOverride || null);
    const dayInputRef = (0, react_1.useRef)(null);
    const yearInputRef = (0, react_1.useRef)(null);
    (0, react_1.useEffect)(() => {
        if (valueOverride)
            if (!valueOverride.isValid) {
                setError(true);
            }
            else {
                validateDate(valueOverride.year.toString(), valueOverride.month.toString(), valueOverride.day.toString());
            }
        if (!includeDayInput) {
            setDay("1");
        }
    }, []);
    const dispatchError = () => {
        setError(true);
        dispatchNewDate(null);
    };
    const dispatchNewDate = (newDate) => {
        if (newDate === dispatchedDate)
            return;
        if (newDate && dispatchedDate && newDate.equals(dispatchedDate))
            return;
        setDispatchedDate(newDate);
        if (newDate)
            setError(false);
        onDateComplete(newDate);
    };
    const ensureOnlyNumber = (inputString) => {
        const stringWithOnlyNumbers = inputString.replace(/\D/g, "");
        return stringWithOnlyNumbers;
    };
    const handleYearChange = (inputString) => {
        const newValue = ensureOnlyNumber(inputString);
        if (newValue === year)
            return;
        setYear(ensureOnlyNumber(inputString));
        validateDate(newValue, month, day);
    };
    const handleMonthChange = (inputString) => {
        const newValue = ensureOnlyNumber(inputString);
        if (newValue === month)
            return;
        setMonth(ensureOnlyNumber(newValue));
        const isValidState = validateDate(year, newValue, day);
        if (newValue.length === 2 && isValidState && includeDayInput)
            dayInputRef.current?.focus();
        if (newValue.length === 2 && isValidState && !includeDayInput)
            yearInputRef.current?.focus();
    };
    const handleDayChange = (inputString) => {
        const newValue = ensureOnlyNumber(inputString);
        if (newValue === day)
            return;
        setDay(newValue);
        const isValidState = validateDate(year, month, newValue);
        if (newValue.length === 2 && isValidState)
            yearInputRef.current?.focus();
    };
    const validateDate = (yearString, monthString, dayString) => {
        if ([yearString, monthString, dayString].some(s => s.length === 0) ||
            yearString.length < 4) {
            setError(false);
            dispatchNewDate(null);
            return true;
        }
        const newDate = luxon_1.DateTime.fromObject({
            year: parseInt(yearString),
            month: parseInt(monthString),
            day: parseInt(dayString),
        });
        if (!newDate.isValid) {
            dispatchError();
            return false;
        }
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        if (!allowFutureDates && newDate.diffNow().toObject().milliseconds > 0) {
            dispatchError();
            return false;
        }
        dispatchNewDate(newDate);
        return true;
    };
    return ((0, jsx_runtime_1.jsxs)("div", { "data-testid": `${testId}-container`, className: "flex gap-2", children: [(0, jsx_runtime_1.jsx)(__1.TextInput, { "data-testid": "month-input", label: "Month", isError: error, isErrorText: error, helpText: error ? "Please enter a valid date" : undefined, onChange: e => handleMonthChange(e.target.value), placeholder: "MM", maxLength: 2, inputMode: "numeric", value: month }), includeDayInput && ((0, jsx_runtime_1.jsx)(__1.TextInput, { "data-testid": "day-input", inputRef: dayInputRef, label: "Day", isError: error, onChange: e => handleDayChange(e.target.value), placeholder: "DD", maxLength: 2, inputMode: "numeric", value: day })), (0, jsx_runtime_1.jsx)(__1.TextInput, { "data-testid": "year-input", inputRef: yearInputRef, label: "Year", isError: error, onChange: e => handleYearChange(e.target.value), placeholder: "YYYY", maxLength: 4, inputMode: "numeric", value: year })] }));
}
exports.DateInput = DateInput;
