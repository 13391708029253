"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SalesforceTaskDepartmentEnum = exports.SalesforceTaskCallDirectionEnum = exports.SalesforceMethodEnum = exports.SalesforceChannelEnum = exports.SalesforceVehicleBodyTypes = exports.LeadRatingEnum = exports.SFLoanPurposeType = exports.AesfSourceEnum = void 0;
var AesfSourceEnum;
(function (AesfSourceEnum) {
    AesfSourceEnum["guaranteedRate"] = "Guaranteed Rate";
    AesfSourceEnum["properRate"] = "Proper Rate";
    AesfSourceEnum["certainty"] = "Certainty";
    AesfSourceEnum["cityWide"] = "Citywide";
    AesfSourceEnum["originPoint"] = "Origin Point";
    AesfSourceEnum["owning"] = "Owning";
    AesfSourceEnum["gra"] = "GR Affinity";
})(AesfSourceEnum || (exports.AesfSourceEnum = AesfSourceEnum = {}));
var SFLoanPurposeType;
(function (SFLoanPurposeType) {
    SFLoanPurposeType["myAccountPurchaseQuoteRequest"] = "MyAccount Purchase Quote Request";
    SFLoanPurposeType["myAccountRefinanceQuoteRequest"] = "MyAccount Refinance Quote Request";
})(SFLoanPurposeType || (exports.SFLoanPurposeType = SFLoanPurposeType = {}));
var LeadRatingEnum;
(function (LeadRatingEnum) {
    LeadRatingEnum["hot"] = "Hot";
    LeadRatingEnum["warm"] = "Warm";
    LeadRatingEnum["cold"] = "Cold";
})(LeadRatingEnum || (exports.LeadRatingEnum = LeadRatingEnum = {}));
var SalesforceVehicleBodyTypes;
(function (SalesforceVehicleBodyTypes) {
    SalesforceVehicleBodyTypes["convertible"] = "Convertible";
    SalesforceVehicleBodyTypes["coupe"] = "Coupe";
    SalesforceVehicleBodyTypes["hatchback"] = "Hatchback";
    SalesforceVehicleBodyTypes["minivan"] = "Minivan";
    SalesforceVehicleBodyTypes["van"] = "Van";
    SalesforceVehicleBodyTypes["truck"] = "Truck";
    SalesforceVehicleBodyTypes["sedan"] = "Sedan";
    SalesforceVehicleBodyTypes["suv"] = "SUV";
    SalesforceVehicleBodyTypes["motorcycle"] = "Motorcycle";
})(SalesforceVehicleBodyTypes || (exports.SalesforceVehicleBodyTypes = SalesforceVehicleBodyTypes = {}));
var SalesforceChannelEnum;
(function (SalesforceChannelEnum) {
    SalesforceChannelEnum["carrier"] = "Carrier";
    SalesforceChannelEnum["socialMedia"] = "Social Media";
    SalesforceChannelEnum["mortgage"] = "Mortgage";
    SalesforceChannelEnum["partnership"] = "Partnership";
    SalesforceChannelEnum["leadProvider"] = "Lead Provider";
    SalesforceChannelEnum["selfSourced"] = "Self-Sourced";
    SalesforceChannelEnum["internal"] = "Internal";
    SalesforceChannelEnum["organic"] = "Organic";
    SalesforceChannelEnum["contentMarketing"] = "Content Marketing";
    SalesforceChannelEnum["emailMarketing"] = "Email Marketing";
})(SalesforceChannelEnum || (exports.SalesforceChannelEnum = SalesforceChannelEnum = {}));
var SalesforceMethodEnum;
(function (SalesforceMethodEnum) {
    SalesforceMethodEnum["leadForm"] = "Lead Form";
    SalesforceMethodEnum["onlineMarketplace"] = "Online Marketplace";
    SalesforceMethodEnum["phone"] = "Phone";
    SalesforceMethodEnum["encompass"] = "Encompass";
    SalesforceMethodEnum["boxDotCom"] = "Box.com";
    SalesforceMethodEnum["api"] = "API";
})(SalesforceMethodEnum || (exports.SalesforceMethodEnum = SalesforceMethodEnum = {}));
var SalesforceTaskCallDirectionEnum;
(function (SalesforceTaskCallDirectionEnum) {
    SalesforceTaskCallDirectionEnum["inbound"] = "Inbound";
    SalesforceTaskCallDirectionEnum["outbound"] = "Outbound";
})(SalesforceTaskCallDirectionEnum || (exports.SalesforceTaskCallDirectionEnum = SalesforceTaskCallDirectionEnum = {}));
var SalesforceTaskDepartmentEnum;
(function (SalesforceTaskDepartmentEnum) {
    SalesforceTaskDepartmentEnum["sales"] = "Sales";
    SalesforceTaskDepartmentEnum["service"] = "Service";
    SalesforceTaskDepartmentEnum["unknown"] = "Unknown";
})(SalesforceTaskDepartmentEnum || (exports.SalesforceTaskDepartmentEnum = SalesforceTaskDepartmentEnum = {}));
