import { store, ClientFlow } from "../store"

export default function StartSidebar() {
  const progress = store.useState(s => s.progress)
  const currentStepDisplay =
    progress.currentStep >= progress.steps.length
      ? progress.steps.length
      : progress.currentStep + 1

  const determineSideBarTitle = (clientFlowString: string) => {
    switch (clientFlowString) {
      case ClientFlow.auto:
        return "Auto Quote"
      case ClientFlow.referral:
        return ""
      default:
        return "Home Quote"
    }
  }
  const sideBarTitle = determineSideBarTitle(progress.flow)
  const isReferralPage = progress.flow === ClientFlow.referral.toString()

  return (
    <aside
      className={`shrink-0 ${
        isReferralPage ? "grins-md:py-6" : "py-6"
      } bg-white`}
    >
      <div className="h-full flex flex-col justify-between">
        <div className="pl-9 flex items-center  lg:flex-col lg:items-start lg:gap-12">
          {progress.steps.length > 0 && (
            <h2
              className="font-bold text-2xl grins-sm:text-3xl"
              data-testid="sidebar-header-h2"
            >
              {isReferralPage ? (
                sideBarTitle
              ) : (
                <>
                  {sideBarTitle}:{" "}
                  <span className="font-normal">
                    Step {currentStepDisplay} of {progress.steps.length}
                  </span>
                </>
              )}
            </h2>
          )}
        </div>
      </div>
    </aside>
  )
}
