"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PropertyFactSourceTypeEnum = exports.EzLynxResponseStatusType = exports.EzLynxQuoteSubmissionStatusType = exports.EzLynxRequestType = exports.CarrierResponseStatus = exports.CarrierRequestStatus = exports.DeductibleValueType = exports.QuoteStatus = exports.QuoteServiceTermType = exports.QuoteServicePolicyType = void 0;
var QuoteServicePolicyType;
(function (QuoteServicePolicyType) {
    QuoteServicePolicyType["ho3"] = "ho3";
    QuoteServicePolicyType["auto"] = "auto";
    QuoteServicePolicyType["flood"] = "flood";
    QuoteServicePolicyType["quake"] = "quake";
})(QuoteServicePolicyType || (exports.QuoteServicePolicyType = QuoteServicePolicyType = {}));
var QuoteServiceTermType;
(function (QuoteServiceTermType) {
    QuoteServiceTermType["six_month"] = "six_month";
    QuoteServiceTermType["annual"] = "annual";
})(QuoteServiceTermType || (exports.QuoteServiceTermType = QuoteServiceTermType = {}));
var QuoteStatus;
(function (QuoteStatus) {
    QuoteStatus["Succeeded"] = "Succeeded";
    QuoteStatus["Failed"] = "Failed";
    QuoteStatus["Unknown"] = "Unknown";
    QuoteStatus["MissingData"] = "Missing Data";
})(QuoteStatus || (exports.QuoteStatus = QuoteStatus = {}));
var DeductibleValueType;
(function (DeductibleValueType) {
    DeductibleValueType["flat"] = "flat";
    DeductibleValueType["percent"] = "percent";
})(DeductibleValueType || (exports.DeductibleValueType = DeductibleValueType = {}));
var CarrierRequestStatus;
(function (CarrierRequestStatus) {
    CarrierRequestStatus["pending"] = "pending";
    CarrierRequestStatus["in_progress"] = "in_progress";
    CarrierRequestStatus["success"] = "success";
    CarrierRequestStatus["carrier_error"] = "carrier_error";
    CarrierRequestStatus["gr_insurance_error"] = "gr_insurance_error";
    CarrierRequestStatus["skipped"] = "skipped";
})(CarrierRequestStatus || (exports.CarrierRequestStatus = CarrierRequestStatus = {}));
var CarrierResponseStatus;
(function (CarrierResponseStatus) {
    CarrierResponseStatus["unknown"] = "unknown";
    CarrierResponseStatus["carrier_error"] = "carrier_error";
    CarrierResponseStatus["timeout"] = "timeout";
    CarrierResponseStatus["declined"] = "declined";
    CarrierResponseStatus["success"] = "success";
    CarrierResponseStatus["missing_data"] = "missing_data";
})(CarrierResponseStatus || (exports.CarrierResponseStatus = CarrierResponseStatus = {}));
//the following ezlynx types are ONLY used within the quote-service context
var EzLynxRequestType;
(function (EzLynxRequestType) {
    EzLynxRequestType["upload_file"] = "upload_file";
    EzLynxRequestType["submit_quote_template"] = "submit_quote_template";
    EzLynxRequestType["agent_run_quote"] = "agent_run_quote";
})(EzLynxRequestType || (exports.EzLynxRequestType = EzLynxRequestType = {}));
var EzLynxQuoteSubmissionStatusType;
(function (EzLynxQuoteSubmissionStatusType) {
    EzLynxQuoteSubmissionStatusType["succeeded"] = "succeeded";
    EzLynxQuoteSubmissionStatusType["carrier_disabled"] = "carrier_disabled";
    EzLynxQuoteSubmissionStatusType["unknown"] = "uknown";
    EzLynxQuoteSubmissionStatusType["carrier_failed"] = "carrier_failed";
})(EzLynxQuoteSubmissionStatusType || (exports.EzLynxQuoteSubmissionStatusType = EzLynxQuoteSubmissionStatusType = {}));
var EzLynxResponseStatusType;
(function (EzLynxResponseStatusType) {
    EzLynxResponseStatusType["succeeded"] = "succeeded";
    EzLynxResponseStatusType["failed"] = "failed";
    EzLynxResponseStatusType["unknown"] = "unknown";
})(EzLynxResponseStatusType || (exports.EzLynxResponseStatusType = EzLynxResponseStatusType = {}));
var PropertyFactSourceTypeEnum;
(function (PropertyFactSourceTypeEnum) {
    PropertyFactSourceTypeEnum["agent"] = "agent";
    PropertyFactSourceTypeEnum["consumer"] = "consumer";
    PropertyFactSourceTypeEnum["ezlynx"] = "ezlynx";
})(PropertyFactSourceTypeEnum || (exports.PropertyFactSourceTypeEnum = PropertyFactSourceTypeEnum = {}));
