"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EzLynxYearsAtAddressNumsJsMap = exports.EzLynxYearsAtAddress = exports.EzLynxHomePolicyForm = void 0;
var EzLynxHomePolicyForm;
(function (EzLynxHomePolicyForm) {
    EzLynxHomePolicyForm["ho3"] = "HO3";
    EzLynxHomePolicyForm["ho4"] = "HO4";
    EzLynxHomePolicyForm["ho6"] = "HO6";
})(EzLynxHomePolicyForm || (exports.EzLynxHomePolicyForm = EzLynxHomePolicyForm = {}));
var EzLynxYearsAtAddress;
(function (EzLynxYearsAtAddress) {
    EzLynxYearsAtAddress["sixMonthsOrLess"] = "6 months or less";
    EzLynxYearsAtAddress["sixToTwelveMonths"] = "6-12 months";
    EzLynxYearsAtAddress["oneYear"] = "1";
    EzLynxYearsAtAddress["twoYears"] = "2";
    EzLynxYearsAtAddress["threeYears"] = "3";
    EzLynxYearsAtAddress["fourYears"] = "4";
    EzLynxYearsAtAddress["fiveYears"] = "5";
    EzLynxYearsAtAddress["sixYears"] = "6";
    EzLynxYearsAtAddress["sevenYears"] = "7";
    EzLynxYearsAtAddress["eightYears"] = "8";
    EzLynxYearsAtAddress["nineYears"] = "9";
    EzLynxYearsAtAddress["tenYears"] = "10";
    EzLynxYearsAtAddress["moreThanTenYears"] = "More than 10";
})(EzLynxYearsAtAddress || (exports.EzLynxYearsAtAddress = EzLynxYearsAtAddress = {}));
// use to avoid long switch or if-else statements by consumers
exports.EzLynxYearsAtAddressNumsJsMap = new Map([
    ["sixMonthsOrLess", EzLynxYearsAtAddress.sixMonthsOrLess],
    ["sixToTwelveMonths", EzLynxYearsAtAddress.sixToTwelveMonths],
    ["oneYear", EzLynxYearsAtAddress.oneYear],
    ["1", EzLynxYearsAtAddress.oneYear],
    ["twoYears", EzLynxYearsAtAddress.twoYears],
    ["2", EzLynxYearsAtAddress.twoYears],
    ["threeYears", EzLynxYearsAtAddress.threeYears],
    ["3", EzLynxYearsAtAddress.threeYears],
    ["fourYears", EzLynxYearsAtAddress.fourYears],
    ["4", EzLynxYearsAtAddress.fourYears],
    ["fiveYears", EzLynxYearsAtAddress.fiveYears],
    ["5", EzLynxYearsAtAddress.fiveYears],
    ["sixYears", EzLynxYearsAtAddress.sixYears],
    ["6", EzLynxYearsAtAddress.sixYears],
    ["sevenYears", EzLynxYearsAtAddress.sevenYears],
    ["7", EzLynxYearsAtAddress.sevenYears],
    ["eightYears", EzLynxYearsAtAddress.eightYears],
    ["8", EzLynxYearsAtAddress.eightYears],
    ["nineYears", EzLynxYearsAtAddress.nineYears],
    ["9", EzLynxYearsAtAddress.nineYears],
    ["tenYears", EzLynxYearsAtAddress.tenYears],
    ["10", EzLynxYearsAtAddress.tenYears],
    ["moreThanTenYears", EzLynxYearsAtAddress.moreThanTenYears],
]);
