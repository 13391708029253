"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseAddressThrowNoErrors = exports.parseAddress = void 0;
/*
  The reason for having this utility function is that some of our data sources & APIs provide
  addresses in one, long string (e.g. { address: "123 main st." }  ), instead of having them
  in separate parts (e.g. {number: "123", streetName: "main st"} )
*/
/**
 * This method is to parse addresses from a string into an object.
 * @param {string} addressToParse - The address string like: 2112 main st., suite 503
 * @returns {  streetName: string, streetNum: string, unitNumber: string } - The clientId and the clientLookupCode of the newly created Applied Epic Account
 */
function parseAddress(addressToParse) {
    if (addressToParse.trim() === "") {
        throw new Error(`Address data is blank.`);
    }
    // split address string into its components based off numbers
    const addressComponents = addressToParse
        .split(/(\d+)/gm)
        .filter(e => e.trim() !== "");
    if (addressComponents.length === 1) {
        return {
            streetName: addressComponents[0].trim(),
            streetNum: "",
            unitNumber: "", // due to business logic, this is always returned as an empty string
        };
    }
    // given above logic, we are guaranteed a find of one number (single or multi-digit)
    const streetNumIdx = addressComponents.findIndex(e => e.match(/\d+/));
    const streetNum = addressComponents[streetNumIdx]; // grab the first number at index determined above.
    /* by virtue of above notes (re: OneLoan data limitations), below logic just merges all
    array elements, except the first number found above  */
    const streetName = addressComponents.reduce((prev, curr, idx) => {
        if (idx === streetNumIdx) {
            return prev.trim();
        }
        return `${prev.trim()} ${curr.trim()}`;
    }, "");
    /* NOTE: by virtue of above notes (re: OneLoan data limitations), there is currently no way
    to extract the "unitNumber", if it even exists. */
    if (!streetName || streetName.trim() === "")
        throw new Error("Address street name cannot be missing.");
    return {
        streetName: streetName.trim(),
        streetNum: streetNum.trim(),
        unitNumber: "", // due to business logic, this is always returned as an empty string
    };
}
exports.parseAddress = parseAddress;
/**
 * This method is to parse addresses from a string into an object. Instead of throwing errors, this returns null
 * @param {string} addressToParse - The address string like: 2112 main st., suite 503
 * @returns {  streetName: string, streetNum: string, unitNumber: string } - The clientId and the clientLookupCode of the newly created Applied Epic Account
 */
function parseAddressThrowNoErrors(addressToParse) {
    if (addressToParse === "") {
        return {
            streetName: "",
            streetNum: "",
            unitNumber: "", // due to business logic, this is always returned as an empty string
        };
    }
    // split address string into its components based off numbers
    const addressComponents = addressToParse
        .split(/(\d+)/gm)
        .filter(e => e.trim() !== "");
    if (addressComponents.length === 1) {
        return {
            streetName: addressComponents[0].trim(),
            streetNum: "",
            unitNumber: "", // due to business logic, this is always returned as an empty string
        };
    }
    // given above logic, we are guaranteed a find of one number (single or multi-digit)
    const streetNumIdx = addressComponents.findIndex(e => e.match(/\d+/));
    const streetNum = addressComponents[streetNumIdx]; // grab the first number at index determined above.
    /* by virtue of above notes (re: OneLoan data limitations), below logic just merges all
    array elements, except the first number found above  */
    const streetName = addressComponents.reduce((prev, curr, idx) => {
        if (idx === streetNumIdx) {
            return prev.trim();
        }
        return `${prev.trim()} ${curr.trim()}`;
    }, "");
    return {
        streetName: streetName.trim(),
        streetNum: streetNum.trim(),
        unitNumber: "", // due to business logic, this is always returned as an empty string
    };
}
exports.parseAddressThrowNoErrors = parseAddressThrowNoErrors;
