"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LeadServiceMaritalStatus = exports.LeadServiceOrigCompanies = exports.ConsentGivenEnum = exports.LeadServiceRecordTypeNames = exports.LeadServiceVehicleBodyTypes = exports.VehicleOwnershipTypes = exports.InsuranceTypeProductLineEnum = exports.InsuranceTypeLeadAndReferralEnum = exports.InsuranceTypeLabel = exports.ReferralTypeEnum = exports.LeadServiceV2FastTrackEligible = exports.LeadServiceV2ApplicantType = exports.LeadServiceV2AddressType = exports.LeadServiceV2ProductLineType = void 0;
var LeadServiceV2ProductLineType;
(function (LeadServiceV2ProductLineType) {
    LeadServiceV2ProductLineType["home"] = "home";
    LeadServiceV2ProductLineType["auto"] = "auto";
    LeadServiceV2ProductLineType["fire"] = "fire";
    LeadServiceV2ProductLineType["condo"] = "condo";
    LeadServiceV2ProductLineType["mobileHome"] = "mobile home";
    LeadServiceV2ProductLineType["personalPackage"] = "personal package";
})(LeadServiceV2ProductLineType || (exports.LeadServiceV2ProductLineType = LeadServiceV2ProductLineType = {}));
var LeadServiceV2AddressType;
(function (LeadServiceV2AddressType) {
    LeadServiceV2AddressType["insured"] = "insured";
    LeadServiceV2AddressType["prior"] = "prior";
    LeadServiceV2AddressType["garaging"] = "garaging";
})(LeadServiceV2AddressType || (exports.LeadServiceV2AddressType = LeadServiceV2AddressType = {}));
var LeadServiceV2ApplicantType;
(function (LeadServiceV2ApplicantType) {
    LeadServiceV2ApplicantType["applicant"] = "applicant";
    LeadServiceV2ApplicantType["coApplicant"] = "co_applicant";
})(LeadServiceV2ApplicantType || (exports.LeadServiceV2ApplicantType = LeadServiceV2ApplicantType = {}));
var LeadServiceV2FastTrackEligible;
(function (LeadServiceV2FastTrackEligible) {
    LeadServiceV2FastTrackEligible["yes"] = "Yes";
    LeadServiceV2FastTrackEligible["no"] = "No";
})(LeadServiceV2FastTrackEligible || (exports.LeadServiceV2FastTrackEligible = LeadServiceV2FastTrackEligible = {}));
var ReferralTypeEnum;
(function (ReferralTypeEnum) {
    ReferralTypeEnum["guaranteedRateLoanOfficer"] = "Loan Officer - Guaranteed Rate";
    ReferralTypeEnum["otherLoanOfficer"] = "Loan Officer - Other (Proper Rate, Origin Point, Citywide, Guaranteed Rate Affinity, etc.)";
    ReferralTypeEnum["realEstateAgent"] = "Real Estate Agent";
    ReferralTypeEnum["guaranteedRateInsAgent"] = "Guaranteed Rate Insurance Agent";
    ReferralTypeEnum["google"] = "Google Search";
    ReferralTypeEnum["socialMedia"] = "Social Media";
    ReferralTypeEnum["personalReferral"] = "Personal Referral";
    ReferralTypeEnum["other"] = "Other";
})(ReferralTypeEnum || (exports.ReferralTypeEnum = ReferralTypeEnum = {}));
var InsuranceTypeLabel;
(function (InsuranceTypeLabel) {
    InsuranceTypeLabel["homeowners"] = "Homeowners";
    InsuranceTypeLabel["auto"] = "Auto";
    InsuranceTypeLabel["life"] = "Life";
    InsuranceTypeLabel["condo"] = "Condo";
    InsuranceTypeLabel["renters"] = "Renters";
    InsuranceTypeLabel["vacationHome"] = "Vacation Home";
    InsuranceTypeLabel["boat"] = "Boat";
    InsuranceTypeLabel["motorcycle"] = "Motorcycle";
    InsuranceTypeLabel["motorhomeOrRV"] = "Motorhome/RV";
    InsuranceTypeLabel["flood"] = "Flood";
    InsuranceTypeLabel["earthquake"] = "Earthquake";
    InsuranceTypeLabel["rentalProperty"] = "Rental Property";
    InsuranceTypeLabel["umbrella"] = "Umbrella";
    InsuranceTypeLabel["personalArticle"] = "Other";
})(InsuranceTypeLabel || (exports.InsuranceTypeLabel = InsuranceTypeLabel = {}));
// we may want to consider avoiding duplicate values within enums in the future
var InsuranceTypeLeadAndReferralEnum;
(function (InsuranceTypeLeadAndReferralEnum) {
    InsuranceTypeLeadAndReferralEnum["homeowners"] = "HO3";
    InsuranceTypeLeadAndReferralEnum["auto"] = "Auto";
    InsuranceTypeLeadAndReferralEnum["life"] = "Life";
    InsuranceTypeLeadAndReferralEnum["condo"] = "HO6";
    InsuranceTypeLeadAndReferralEnum["renters"] = "HO4";
    InsuranceTypeLeadAndReferralEnum["vacationHome"] = "DF";
    InsuranceTypeLeadAndReferralEnum["boat"] = "Boat";
    InsuranceTypeLeadAndReferralEnum["motorcycle"] = "Motorcycle";
    InsuranceTypeLeadAndReferralEnum["motorhomeOrRV"] = "Other";
    InsuranceTypeLeadAndReferralEnum["flood"] = "Flood";
    InsuranceTypeLeadAndReferralEnum["earthquake"] = "Quake";
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    InsuranceTypeLeadAndReferralEnum["rentalProperty"] = "DF";
    InsuranceTypeLeadAndReferralEnum["umbrella"] = "Umbrella";
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    InsuranceTypeLeadAndReferralEnum["personalArticle"] = "Other";
})(InsuranceTypeLeadAndReferralEnum || (exports.InsuranceTypeLeadAndReferralEnum = InsuranceTypeLeadAndReferralEnum = {}));
var InsuranceTypeProductLineEnum;
(function (InsuranceTypeProductLineEnum) {
    InsuranceTypeProductLineEnum["auto"] = "Auto";
    InsuranceTypeProductLineEnum["condo"] = "Condo";
    InsuranceTypeProductLineEnum["fire"] = "Fire";
    InsuranceTypeProductLineEnum["home"] = "Home";
})(InsuranceTypeProductLineEnum || (exports.InsuranceTypeProductLineEnum = InsuranceTypeProductLineEnum = {}));
var VehicleOwnershipTypes;
(function (VehicleOwnershipTypes) {
    VehicleOwnershipTypes["owned"] = "Owned";
    VehicleOwnershipTypes["leased"] = "Leased";
    VehicleOwnershipTypes["lien"] = "Lien";
})(VehicleOwnershipTypes || (exports.VehicleOwnershipTypes = VehicleOwnershipTypes = {}));
var LeadServiceVehicleBodyTypes;
(function (LeadServiceVehicleBodyTypes) {
    LeadServiceVehicleBodyTypes["convertible"] = "Convertible";
    LeadServiceVehicleBodyTypes["coupe"] = "Coupe";
    LeadServiceVehicleBodyTypes["hatchback"] = "Hatchback";
    LeadServiceVehicleBodyTypes["minivan"] = "Minivan";
    LeadServiceVehicleBodyTypes["van"] = "Van";
    LeadServiceVehicleBodyTypes["truck"] = "Truck";
    LeadServiceVehicleBodyTypes["sedan"] = "Sedan";
    LeadServiceVehicleBodyTypes["suv"] = "SUV";
    LeadServiceVehicleBodyTypes["motorcycle"] = "Motorcycle";
})(LeadServiceVehicleBodyTypes || (exports.LeadServiceVehicleBodyTypes = LeadServiceVehicleBodyTypes = {}));
var LeadServiceRecordTypeNames;
(function (LeadServiceRecordTypeNames) {
    LeadServiceRecordTypeNames["residentialLeads"] = "Residential Leads";
    LeadServiceRecordTypeNames["encompassLeads"] = "Encompass Leads";
})(LeadServiceRecordTypeNames || (exports.LeadServiceRecordTypeNames = LeadServiceRecordTypeNames = {}));
var ConsentGivenEnum;
(function (ConsentGivenEnum) {
    ConsentGivenEnum["yes"] = "Yes";
    ConsentGivenEnum["no"] = "No";
    ConsentGivenEnum["not_yet"] = "Not Yet";
})(ConsentGivenEnum || (exports.ConsentGivenEnum = ConsentGivenEnum = {}));
var LeadServiceOrigCompanies;
(function (LeadServiceOrigCompanies) {
    LeadServiceOrigCompanies["gri"] = "gri";
    LeadServiceOrigCompanies["prate"] = "prate";
    LeadServiceOrigCompanies["cw"] = "cw";
    LeadServiceOrigCompanies["op"] = "op";
    LeadServiceOrigCompanies["gra"] = "gra";
})(LeadServiceOrigCompanies || (exports.LeadServiceOrigCompanies = LeadServiceOrigCompanies = {}));
var LeadServiceMaritalStatus;
(function (LeadServiceMaritalStatus) {
    LeadServiceMaritalStatus["single"] = "single";
    LeadServiceMaritalStatus["married"] = "married";
    LeadServiceMaritalStatus["domestic_partner"] = "domestic_partner";
    LeadServiceMaritalStatus["widowed"] = "widowed";
    LeadServiceMaritalStatus["separated"] = "separated";
    LeadServiceMaritalStatus["divorced"] = "divorced";
})(LeadServiceMaritalStatus || (exports.LeadServiceMaritalStatus = LeadServiceMaritalStatus = {}));
