"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapOmsitePropertyToV2LeadService = exports.mapEzlynxApplicantIdToLeadService = exports.mapOmsitePropertyToLeadService = exports.mapOmsiteCoApplicantToLeadService = exports.mapOmsiteApplicantToLeadService = exports.mapOmsiteMaritalStatusToLeadServiceMaritalStatus = exports.mapOmsiteReferralToLeadService = void 0;
const non_normal_types_1 = require("./non-normal-types");
function mapOmsiteReferralToLeadService(omsiteReferral) {
    const { firstName, lastName, emailAddress, sfUserId, phoneNumber } = omsiteReferral;
    return {
        agent_attribution: sfUserId,
        applicant: [
            {
                applicant_type: non_normal_types_1.LeadServiceV2ApplicantType.applicant,
                ...(emailAddress && { email: emailAddress }),
                first_name: firstName,
                last_name: lastName,
                mobile_phone: phoneNumber,
            },
        ],
        lead_rating: non_normal_types_1.LeadRatingEnum.hot,
        product_line_type: non_normal_types_1.LeadServiceV2ProductLineType.home,
        record_type_name: non_normal_types_1.LeadServiceRecordTypeNames.residentialLeads,
        tracking: {
            aesf_source: "Customer Referral",
            method: non_normal_types_1.SalesforceMethodEnum.onlineMarketplace,
        },
    };
}
exports.mapOmsiteReferralToLeadService = mapOmsiteReferralToLeadService;
function mapOmsiteMaritalStatusToLeadServiceMaritalStatus(omsiteMaritalStatus) {
    let leadServiceMaritalStatus = non_normal_types_1.LeadServiceMaritalStatus.single;
    switch (omsiteMaritalStatus) {
        case non_normal_types_1.OmsiteMaritalStatus.Single:
            leadServiceMaritalStatus = non_normal_types_1.LeadServiceMaritalStatus.single;
            break;
        case non_normal_types_1.OmsiteMaritalStatus.Married:
            leadServiceMaritalStatus = non_normal_types_1.LeadServiceMaritalStatus.married;
            break;
        case non_normal_types_1.OmsiteMaritalStatus.Divorced:
            leadServiceMaritalStatus = non_normal_types_1.LeadServiceMaritalStatus.divorced;
            break;
        case non_normal_types_1.OmsiteMaritalStatus.DomesticPartner:
            leadServiceMaritalStatus = non_normal_types_1.LeadServiceMaritalStatus.domestic_partner;
            break;
        case non_normal_types_1.OmsiteMaritalStatus.Widowed:
            leadServiceMaritalStatus = non_normal_types_1.LeadServiceMaritalStatus.widowed;
            break;
        case non_normal_types_1.OmsiteMaritalStatus.Separated:
            leadServiceMaritalStatus = non_normal_types_1.LeadServiceMaritalStatus.separated;
            break;
    }
    return leadServiceMaritalStatus;
}
exports.mapOmsiteMaritalStatusToLeadServiceMaritalStatus = mapOmsiteMaritalStatusToLeadServiceMaritalStatus;
function mapOmsiteApplicantToLeadService(omsiteApplicant) {
    const { firstName, lastName, dateOfBirth, phoneNumber, maritalStatus, sfUserId, emailAddress, assignLeadToAgent, aesf_source, } = omsiteApplicant;
    return {
        product_line_type: non_normal_types_1.LeadServiceV2ProductLineType.home,
        consent_given: non_normal_types_1.ConsentGivenEnum.yes,
        record_type_name: non_normal_types_1.LeadServiceRecordTypeNames.residentialLeads,
        lead_rating: non_normal_types_1.LeadRatingEnum.hot,
        agent_attribution: assignLeadToAgent ? sfUserId : undefined,
        applicant: [
            {
                applicant_type: non_normal_types_1.LeadServiceV2ApplicantType.applicant,
                first_name: firstName,
                last_name: lastName,
                date_of_birth: dateOfBirth,
                marital_status: mapOmsiteMaritalStatusToLeadServiceMaritalStatus(maritalStatus),
                mobile_phone: phoneNumber,
                email: emailAddress ? emailAddress : undefined,
            },
        ],
        tracking: {
            source: non_normal_types_1.EzLynxHomePolicyForm.ho3,
            aesf_source: aesf_source,
            method: non_normal_types_1.SalesforceMethodEnum.onlineMarketplace,
        },
    };
}
exports.mapOmsiteApplicantToLeadService = mapOmsiteApplicantToLeadService;
function mapOmsiteCoApplicantToLeadService(omsiteCoApplicant) {
    const { firstName, lastName, dateOfBirth } = omsiteCoApplicant;
    return {
        first_name: firstName,
        last_name: lastName,
        date_of_birth: dateOfBirth,
        applicant_type: non_normal_types_1.LeadServiceV2ApplicantType.coApplicant,
    };
}
exports.mapOmsiteCoApplicantToLeadService = mapOmsiteCoApplicantToLeadService;
function combineAddress(streetName, streetNumber, aptSuiteNumber) {
    if (aptSuiteNumber)
        return `${streetNumber} ${streetName}\n${aptSuiteNumber}`;
    return `${streetNumber} ${streetName}`;
}
function mapOmsitePropertyToLeadService(omsiteProperty) {
    const { city, zip, state, streetName, streetNumber, aptSuiteNumber } = omsiteProperty.address;
    return {
        address_type: non_normal_types_1.LeadServiceV2AddressType.garaging,
        city,
        postal_code: zip,
        // `country` and `country_code` are currently hardcoded to "United Sates" and "US" and can't be modified
        state: state,
        street1: combineAddress(streetName, streetNumber),
        ...(aptSuiteNumber && {
            street2: aptSuiteNumber,
        }),
    };
}
exports.mapOmsitePropertyToLeadService = mapOmsitePropertyToLeadService;
function mapEzlynxApplicantIdToLeadService(ezLynxBaseUrl, applicantID, grinsLeadId) {
    return {
        lead_id: grinsLeadId,
        quote_service_url: `${ezLynxBaseUrl}${applicantID}/overview`,
    };
}
exports.mapEzlynxApplicantIdToLeadService = mapEzlynxApplicantIdToLeadService;
function mapOmsitePropertyToV2LeadService(omsiteProperty) {
    const { city, zip, state, streetName, streetNumber, aptSuiteNumber } = omsiteProperty.address;
    const baseMappedAddress = {
        address_type: non_normal_types_1.LeadServiceV2AddressType.insured,
        city,
        postal_code: zip,
        state: state,
    };
    if (streetNumber && streetName) {
        baseMappedAddress.street1 = `${streetNumber} ${streetName}`;
    }
    if (aptSuiteNumber) {
        baseMappedAddress.street2 = aptSuiteNumber;
    }
    return baseMappedAddress;
}
exports.mapOmsitePropertyToV2LeadService = mapOmsitePropertyToV2LeadService;
