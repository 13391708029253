import { Modal } from "grins-ui"

export interface AutoQuoteInfoModalProps {
  title: string
  verbiage: string
  modalOpen: boolean
  setModalOpen: (value: boolean) => void
}

export default function QuoteInfoModal({
  title,
  verbiage,
  modalOpen,
  setModalOpen,
}: AutoQuoteInfoModalProps) {
  return (
    <Modal
      open={modalOpen}
      onRequestClose={() => setModalOpen(false)}
      testId={`${title.replace(/\s/g, "-")}`}
    >
      <div className="w-64 grins-sm:w-96 bg-white rounded-xl flex-col flex justify-center p-5">
        <button
          autoFocus={true}
          onClick={() => setModalOpen(false)}
          className="absolute right-5 top-5 cursor-pointer"
        >
          <span aria-hidden>X</span> <span className="sr-only">close</span>
        </button>
        <p className="font-bold text-xl text-center">{title}</p>
        <p className="self-center font-normal text-sm text-center my-4">
          {verbiage}
        </p>
      </div>
    </Modal>
  )
}
