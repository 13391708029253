import { getPrefill } from "./utils/api"
import { BrowserRouter } from "react-router-dom"
import App from "./app"
import {
  AutoFlowProgressSteps,
  ClientApplication,
  PrefillFlowProgressSteps,
  ClientFlow,
  store,
  ReferralProgressSteps,
} from "./store"
import { agentRegex, serverApplicationToClient } from "./utils/transform"
import {
  AgentServiceGetResponse,
  MyAccountHomeLead,
  Carrier,
} from "grid-transform"
import { navigatePage } from "./actions"
import { Page } from "./utils/enum"
import "./main.css"
import { createRoot } from "react-dom/client"

declare global {
  interface Window {
    ENV: {
      GOOGLE_MAPS_API_KEY: string
      BUILD_VERSION: string
      prefill?: MyAccountHomeLead
      ffShowAdditionalLogos: boolean
      ffPrefillQuery: boolean
      ffAutoFlowEnabled: boolean
      rudderStackUrl: string
      rudderStackWriteKey: string
      omsiteRenderLeadEmailAddress: boolean
      omsiteRequiredEmail: boolean
      omsiteShowAdditionalInfo: boolean
      rumActive: boolean
      rumServiceName: string
      rumEnvironment: string
      rumServerUrl: string
    }
    dataLayer: []
  }
}

// This works around an HMR bug in webpack 5 - https://github.com/webpack-contrib/webpack-hot-middleware/issues/390
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
;(module as any)?.hot?.accept()

const pathname = window.location.pathname
const params = new URLSearchParams(window.location.search)
const prefillParam = params.get("prefill")
let prefillRawData: MyAccountHomeLead | undefined = undefined
const matches = pathname ? agentRegex(pathname) : null
//check if we have a request to retrieve agent info in the
//format of /agents/{firstName}-{lastName}
if (
  matches &&
  matches.length === 4 &&
  matches[2].length > 0 &&
  matches[3].length > 0
) {
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  ;(async function () {
    try {
      store.update(s => {
        s.agentInfo = {
          ...s.agentInfo,
          isLoading: true,
        }
      })
      const firstName = matches[2]
      const lastName = matches[3]
      const response = await fetch("/api/agent-info", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          firstName,
          lastName,
        }),
      })

      const agentData = (await response.json()) as AgentServiceGetResponse
      const { name, images, phone, assignLeadToAgent } = agentData
      //store agentInfo for use in displaying agent info and attributing leads
      store.update(s => {
        s.agentInfo = {
          ...agentData,
          isLoading: false,
        }
      })
      if (name && phone) {
        store.update(s => {
          s.agentDisplay = {
            header: name,
            content: "Want to talk? Give me a call, I'm here to help.",
            profilePhoto: images
              ? images.mediumPhotoBase64Encoded
              : "/public/agent-no-photo.png, /public/agent-no-photo@2x.png",
            phone: assignLeadToAgent ? phone : s.agentDisplay.phone,
          }
          if (pathname.startsWith("/auto")) {
            s.autoApplication.primaryDriver.assignLeadToAgent =
              assignLeadToAgent
          } else {
            s.application.applicant.assignLeadToAgent = assignLeadToAgent
          }
        })
      }
    } catch (e) {
      const err = e as Error
      console.error(`Error with agent info data.  ${err.message}`)
    }
  })()
}

// Fetch carriers data from quote-service
// eslint-disable-next-line @typescript-eslint/no-floating-promises
;(async function () {
  try {
    const response = await fetch("/api/quotes/carriers", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
    const carriersData = (await response.json()) as Carrier[]

    store.update(s => {
      s.carriers = carriersData
    })
  } catch (e) {
    const err = e as Error
    console.error(`Error with carrier data. ${err.message}`)
  }
})()

if (window.ENV.ffPrefillQuery && prefillParam) {
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  ;(async function () {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      prefillRawData = await getPrefill(prefillParam || "")
    } catch (e) {
      const err = e as Error
      console.error(`Error getting prefill.  ${err.message}`)
    }
    if (prefillRawData) {
      try {
        const clientApplication: ClientApplication =
          serverApplicationToClient(prefillRawData)

        store.update(s => {
          s.application = clientApplication
          s.progress.steps = PrefillFlowProgressSteps
          s.progress.flow = ClientFlow.home
          s.grinsLeadId = prefillRawData?.leadId || ""
          s.omlinkPurchasePriceAmount =
            prefillRawData?.purchasePriceAmount || null
        })

        navigatePage(Page.prefill)
      } catch (e) {
        const err = e as Error
        console.error(`Error with prefill data.  ${err.message}`)
      }
    }
  })()
} else if (pathname.startsWith("/duplicate-contact")) {
  store.update(s => {
    s.progress.steps = []
  })
  navigatePage(Page.duplicateContact)
} else if (pathname.startsWith("/auto")) {
  store.update(s => {
    s.progress.steps = AutoFlowProgressSteps
    s.progress.flow = ClientFlow.auto
  })
  navigatePage(Page.primaryDriver)
} else if (pathname.startsWith("/referral")) {
  store.update(s => {
    s.progress.steps = ReferralProgressSteps
    s.progress.flow = ClientFlow.referral
    s.progress.currentStep = 0
  })
  navigatePage(Page.referral)
} else if (pathname.startsWith("/") || pathname.startsWith("/home")) {
  navigatePage(Page.applicant)
}

const container = document.getElementById("root")
const root = createRoot(container!)
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
)
