"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.normalizeLeadAndReferralKeys = void 0;
var PropertyTypeValueKey;
(function (PropertyTypeValueKey) {
    PropertyTypeValueKey["attached"] = "Attached";
    PropertyTypeValueKey["condominium"] = "Condominium";
    PropertyTypeValueKey["detached"] = "Detached";
    PropertyTypeValueKey["highRiseCondominium"] = "HighRiseCondominium";
    PropertyTypeValueKey["pUD"] = "PUD";
})(PropertyTypeValueKey || (PropertyTypeValueKey = {}));
const propertyTypeValuesMap = new Map();
propertyTypeValuesMap.set(PropertyTypeValueKey.attached, PropertyTypeValueKey.attached);
propertyTypeValuesMap.set(PropertyTypeValueKey.condominium, PropertyTypeValueKey.condominium);
propertyTypeValuesMap.set(PropertyTypeValueKey.detached, PropertyTypeValueKey.detached);
propertyTypeValuesMap.set(PropertyTypeValueKey.highRiseCondominium, PropertyTypeValueKey.highRiseCondominium);
propertyTypeValuesMap.set(PropertyTypeValueKey.pUD, PropertyTypeValueKey.pUD);
var PropertyUsageTypeKey;
(function (PropertyUsageTypeKey) {
    PropertyUsageTypeKey["investor"] = "Investor";
    PropertyUsageTypeKey["primaryResidence"] = "PrimaryResidence";
    PropertyUsageTypeKey["secondHome"] = "SecondHome";
})(PropertyUsageTypeKey || (PropertyUsageTypeKey = {}));
const propertyUsageTypeMap = new Map();
propertyUsageTypeMap.set(PropertyUsageTypeKey.investor, PropertyUsageTypeKey.investor);
propertyUsageTypeMap.set(PropertyUsageTypeKey.primaryResidence, PropertyUsageTypeKey.primaryResidence);
propertyUsageTypeMap.set(PropertyUsageTypeKey.secondHome, PropertyUsageTypeKey.secondHome);
/*
    Normalizes passed-in values to empty string, if initial value is not part of the pre-defined
    values in requirements (xref: https://wiki.guaranteedrate.com/x/-iNiBg#ProperRateIntegrationRequirements-LeadandReferralSource  )

    Use hash above maps for O(1) lookups.
*/
function normalizeLeadAndReferralKeys(propertyType, propertyUsageType) {
    return {
        propertyType: propertyTypeValuesMap.has(propertyType) ? propertyType : "",
        propertyUsageType: propertyUsageTypeMap.has(propertyUsageType)
            ? propertyUsageType
            : "",
    };
}
exports.normalizeLeadAndReferralKeys = normalizeLeadAndReferralKeys;
