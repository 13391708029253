"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EzLynxHomeStyleEnum = exports.EzLynxConstructionTypeEnum = exports.EzLynxRoofCoveringTypeEnum = exports.EzLynxFoundationTypeEnum = exports.EZLQuoteExecutionStatusEnum = exports.EzLynxQuoteStatusEnum = exports.EZLSubmitQuoteCarrierResponseStatus = exports.EZLSubmitQuoteDataResponseStatus = exports.CreditScoredEnum = exports.EzLynxUploadFileResponseStatus = exports.EzLynxApplicantRelation = exports.EzLynxMaritalStatus = void 0;
var EzLynxMaritalStatus;
(function (EzLynxMaritalStatus) {
    EzLynxMaritalStatus["single"] = "Single";
    EzLynxMaritalStatus["married"] = "Married";
    EzLynxMaritalStatus["domesticPartner"] = "Domestic Partner";
    EzLynxMaritalStatus["widowed"] = "Widowed";
    EzLynxMaritalStatus["separated"] = "Separated";
    EzLynxMaritalStatus["divorced"] = "Divorced";
})(EzLynxMaritalStatus || (exports.EzLynxMaritalStatus = EzLynxMaritalStatus = {}));
var EzLynxApplicantRelation;
(function (EzLynxApplicantRelation) {
    EzLynxApplicantRelation["child"] = "Child";
    EzLynxApplicantRelation["domesticPartner"] = "Domestic Partner";
    EzLynxApplicantRelation["employee"] = "Employee";
    EzLynxApplicantRelation["insured"] = "Insured";
    EzLynxApplicantRelation["other"] = "Other";
    EzLynxApplicantRelation["parent"] = "Parent";
    EzLynxApplicantRelation["relative"] = "Relative";
    EzLynxApplicantRelation["spouse"] = "Spouse";
})(EzLynxApplicantRelation || (exports.EzLynxApplicantRelation = EzLynxApplicantRelation = {}));
var EzLynxUploadFileResponseStatus;
(function (EzLynxUploadFileResponseStatus) {
    EzLynxUploadFileResponseStatus["succeeded"] = "Succeeded";
    EzLynxUploadFileResponseStatus["failed"] = "Failed";
    EzLynxUploadFileResponseStatus["unknown"] = "Unknown";
})(EzLynxUploadFileResponseStatus || (exports.EzLynxUploadFileResponseStatus = EzLynxUploadFileResponseStatus = {}));
var CreditScoredEnum;
(function (CreditScoredEnum) {
    CreditScoredEnum["yes"] = "Yes";
    CreditScoredEnum["no"] = "No";
    CreditScoredEnum["NA"] = "NA";
})(CreditScoredEnum || (exports.CreditScoredEnum = CreditScoredEnum = {}));
var EZLSubmitQuoteDataResponseStatus;
(function (EZLSubmitQuoteDataResponseStatus) {
    EZLSubmitQuoteDataResponseStatus["unknown"] = "Unknown";
    EZLSubmitQuoteDataResponseStatus["succeeded"] = "Succeeded";
    EZLSubmitQuoteDataResponseStatus["invalidRequestId"] = "Invalid Request ID";
    EZLSubmitQuoteDataResponseStatus["vendorNotAuthenticated"] = "Vendor Not Authenticated";
    EZLSubmitQuoteDataResponseStatus["vendorNotAuthorized"] = "Vendor Not Authorized";
    EZLSubmitQuoteDataResponseStatus["userNotAuthenticated"] = "User Not Authenticated";
    EZLSubmitQuoteDataResponseStatus["userNotAuthorized"] = "User Not Authorized";
    EZLSubmitQuoteDataResponseStatus["invalidData"] = "Invalid Data";
    EZLSubmitQuoteDataResponseStatus["invalidAddressSpecified"] = "Invalid Address Specified";
})(EZLSubmitQuoteDataResponseStatus || (exports.EZLSubmitQuoteDataResponseStatus = EZLSubmitQuoteDataResponseStatus = {}));
var EZLSubmitQuoteCarrierResponseStatus;
(function (EZLSubmitQuoteCarrierResponseStatus) {
    EZLSubmitQuoteCarrierResponseStatus["unknown"] = "Unknown";
    EZLSubmitQuoteCarrierResponseStatus["succeeded"] = "Succeeded";
    EZLSubmitQuoteCarrierResponseStatus["carrierDisabled"] = "Carrier Disabled";
    EZLSubmitQuoteCarrierResponseStatus["carrierFailed"] = "Carrier Failed";
})(EZLSubmitQuoteCarrierResponseStatus || (exports.EZLSubmitQuoteCarrierResponseStatus = EZLSubmitQuoteCarrierResponseStatus = {}));
var EzLynxQuoteStatusEnum;
(function (EzLynxQuoteStatusEnum) {
    EzLynxQuoteStatusEnum["rated"] = "Rated";
    EzLynxQuoteStatusEnum["declined"] = "Declined";
    EzLynxQuoteStatusEnum["failed"] = "Failed";
    EzLynxQuoteStatusEnum["unknown"] = "Unknown";
    EzLynxQuoteStatusEnum["issued"] = "Issued";
    EzLynxQuoteStatusEnum["finalized"] = "Finalized";
})(EzLynxQuoteStatusEnum || (exports.EzLynxQuoteStatusEnum = EzLynxQuoteStatusEnum = {}));
var EZLQuoteExecutionStatusEnum;
(function (EZLQuoteExecutionStatusEnum) {
    EZLQuoteExecutionStatusEnum["executing"] = "Executing";
    EZLQuoteExecutionStatusEnum["failed"] = "Failed";
    EZLQuoteExecutionStatusEnum["failedWaiting"] = "Failed Waiting";
    EZLQuoteExecutionStatusEnum["initializing"] = "Initializing";
    EZLQuoteExecutionStatusEnum["serviceNotAvailable"] = "Service Not Available";
    EZLQuoteExecutionStatusEnum["submissionFailure"] = "Submission Failure";
    EZLQuoteExecutionStatusEnum["succeeded"] = "Succeeded";
    EZLQuoteExecutionStatusEnum["unknown"] = "Unknown";
    EZLQuoteExecutionStatusEnum["waiting"] = "Waiting";
})(EZLQuoteExecutionStatusEnum || (exports.EZLQuoteExecutionStatusEnum = EZLQuoteExecutionStatusEnum = {}));
var EzLynxFoundationTypeEnum;
(function (EzLynxFoundationTypeEnum) {
    EzLynxFoundationTypeEnum["basement"] = "Basement";
    EzLynxFoundationTypeEnum["basementFinished"] = "Basement - Finished";
    EzLynxFoundationTypeEnum["concreteSlab"] = "Concrete Slab";
    EzLynxFoundationTypeEnum["crawlSpaceEnclosed"] = "Crawl Space - Enclosed";
    EzLynxFoundationTypeEnum["other"] = "Other";
    EzLynxFoundationTypeEnum["slab"] = "Slab";
})(EzLynxFoundationTypeEnum || (exports.EzLynxFoundationTypeEnum = EzLynxFoundationTypeEnum = {}));
var EzLynxRoofCoveringTypeEnum;
(function (EzLynxRoofCoveringTypeEnum) {
    EzLynxRoofCoveringTypeEnum["rubberPitched"] = "RUBBER(PITCHED)";
    EzLynxRoofCoveringTypeEnum["metalPitched"] = "METAL(PITCHED)";
    EzLynxRoofCoveringTypeEnum["architecturalShingles"] = "ARCHITECTURAL SHINGLES";
    EzLynxRoofCoveringTypeEnum["slate"] = "SLATE";
    EzLynxRoofCoveringTypeEnum["tarAndGravel"] = "TAR and GRAVEL";
    EzLynxRoofCoveringTypeEnum["asphaltShingles"] = "ASPHALT SHINGLES";
    EzLynxRoofCoveringTypeEnum["tileClay"] = "TILE(CLAY)";
    EzLynxRoofCoveringTypeEnum["tileConcrete"] = "TILE(CONCRETE)";
    EzLynxRoofCoveringTypeEnum["woodShake"] = "WOOD SHAKE";
    EzLynxRoofCoveringTypeEnum["woodShingles"] = "WOOD SHINGLES";
})(EzLynxRoofCoveringTypeEnum || (exports.EzLynxRoofCoveringTypeEnum = EzLynxRoofCoveringTypeEnum = {}));
var EzLynxConstructionTypeEnum;
(function (EzLynxConstructionTypeEnum) {
    EzLynxConstructionTypeEnum["adobe"] = "Adobe";
    EzLynxConstructionTypeEnum["brickOnBlock"] = "Brick on Block";
    EzLynxConstructionTypeEnum["brickVeneer"] = "Brick Veneer";
    EzLynxConstructionTypeEnum["cementFiberShingles"] = "Cement Fiber Shingles";
    EzLynxConstructionTypeEnum["clapboard"] = "Clapboard";
    EzLynxConstructionTypeEnum["concreteDecorativeBlockPainted"] = "Concrete Decorative Block, Painted";
    EzLynxConstructionTypeEnum["logs"] = "Logs";
    EzLynxConstructionTypeEnum["pouredConcrete"] = "Poured Concrete";
    EzLynxConstructionTypeEnum["sidingAluminum"] = "Siding, Aluminum";
    EzLynxConstructionTypeEnum["sidingHardboard"] = "Siding, Hardboard";
    EzLynxConstructionTypeEnum["sidingPlywood"] = "Siding, Plywood";
    EzLynxConstructionTypeEnum["sidingSteel"] = "Siding, Steel";
    EzLynxConstructionTypeEnum["sidingVinyl"] = "Siding, Vinyl";
    EzLynxConstructionTypeEnum["sidingWood"] = "Siding, Wood";
    EzLynxConstructionTypeEnum["slumpBlock"] = "Slump Block";
    EzLynxConstructionTypeEnum["solidBrick"] = "Solid Brick";
    EzLynxConstructionTypeEnum["solidStone"] = "Solid Stone";
    EzLynxConstructionTypeEnum["stoneVeneer"] = "Stone Veneer";
    EzLynxConstructionTypeEnum["stuccoOnBlock"] = "Stucco on Block";
    EzLynxConstructionTypeEnum["stuccoOnFrame"] = "Stucco on Frame";
    EzLynxConstructionTypeEnum["woodShakes"] = "Wood Shakes";
})(EzLynxConstructionTypeEnum || (exports.EzLynxConstructionTypeEnum = EzLynxConstructionTypeEnum = {}));
var EzLynxHomeStyleEnum;
(function (EzLynxHomeStyleEnum) {
    EzLynxHomeStyleEnum["apartment"] = "Apartment";
    EzLynxHomeStyleEnum["biLevel"] = "Bi-Level";
    EzLynxHomeStyleEnum["bungalow"] = "Bungalow";
    EzLynxHomeStyleEnum["capeCod"] = "Cape Cod";
    EzLynxHomeStyleEnum["colonial"] = "Colonial";
    EzLynxHomeStyleEnum["contemporary"] = "Contemporary";
    EzLynxHomeStyleEnum["cottage"] = "Cottage";
    EzLynxHomeStyleEnum["mediterranean"] = "Mediterranean";
    EzLynxHomeStyleEnum["ranch"] = "Ranch";
    EzLynxHomeStyleEnum["townHouse"] = "Townhouse";
    EzLynxHomeStyleEnum["triLevel"] = "Tri-Level";
    EzLynxHomeStyleEnum["victorian"] = "Victorian";
})(EzLynxHomeStyleEnum || (exports.EzLynxHomeStyleEnum = EzLynxHomeStyleEnum = {}));
