import PhoneAnchorTag from "../utils/phone-anchor-tag"
import { store } from "../store"

export default function Header() {
  const homePropertyState = store.useState(
    s => s.application.property.address.state
  )
  const garagingPropertyState = store.useState(
    s => s.autoApplication.property.address.state
  )
  const isStateCalifornia =
    homePropertyState === "CA" || garagingPropertyState === "CA"

  return (
    <header
      className="relative shadow flex flex-row items-center justify-between px-9 bg-white"
      data-testid="page-layout-new-header"
    >
      <div className="flex justify-center h-20 flex-col">
        <img
          className="h-10 w-16 grins-sm:hidden"
          src="/public/down-arrow-logo.svg"
          alt="Rate Insurance Logo"
        />
        {isStateCalifornia ? (
          <img
            className="h-10 w-36 hidden grins-sm:block"
            src={"/public/rate-insurance-logo-1.svg"}
            alt="Rate Insurance Agency Logo"
            data-testid="gri-agency-logo-img"
          />
        ) : (
          <img
            className="h-10 w-36 hidden grins-sm:block"
            src={"/public/rate-insurance-logo-1.svg"}
            alt="Rate Insurance Logo"
            data-testid="gri-logo-img"
          />
        )}
      </div>
      <div className="flex flex-col grins-md:hidden">
        <h2 className="font-bold">Want to talk?</h2>
        <div className="flex align-center">
          <img src="/public/phone-icon.svg" aria-hidden />
          <PhoneAnchorTag />
        </div>
      </div>
    </header>
  )
}
