import CongratsErrorPageContent from "../../components/congrats-error-page-content"
import { usePageLoadEvent } from "../../utils/session"

export default function EzLynxPrefillError() {
  usePageLoadEvent({
    page_path: "/additionalinforequired",
    page_title: "Additional Information Required",
  })

  return <CongratsErrorPageContent />
}
