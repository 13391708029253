"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.complexOptionsFrom = void 0;
/**
 * @function SelectComplexOptions Provides a convenient way to generate an array of `SelectOptionsType<string>` elements that have the same `uiLabel` and `value.base`
 *
 * @param {string[]} valueLabelDualities Array of string values that are also to be used as labels, thus the "duality".
 *
 * @returns {SelectOptionsType<string>[]} The array of string values that will also be used as labels in the consuming `select` control
 */
function complexOptionsFrom(valueLabelDualities) {
    const options = [];
    valueLabelDualities.forEach(elem => {
        const option = {
            uiLabel: elem,
            value: {
                base: elem,
                data: undefined,
            },
        };
        options.push(option);
    });
    return options;
}
exports.complexOptionsFrom = complexOptionsFrom;
