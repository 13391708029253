import { useSearchParams } from "react-router-dom"
import ContentArea from "./content-area"
import Header from "./header"
import StartSidebar from "./start-sidebar"

export interface Props {
  children: React.ReactNode
}

export default function Layout({ children }: Props) {
  const [searchParams] = useSearchParams()

  const renderHeader = () => {
    const isEmbeddedPage = searchParams.get("embedded") === "true"
    if (isEmbeddedPage) {
      return null
    }

    return <Header />
  }

  return (
    <div className="mx-auto dark-layout">
      <div className="bg-gradient-to-tr from-gr-blueprint+50 to-gr-blueprint grins-md:bg-none min-h-screen flex flex-col justify-between">
        {renderHeader()}
        <div className="grins-md:flex grins-md:justify-center grow">
          <div className="flex flex-col">
            <StartSidebar />
            <ContentArea>{children}</ContentArea>
            <p className="hidden text-[10px] text-very-light-grey font-normal text-left align-bottom grins-md:block ml-9">
              Version: {window?.ENV?.BUILD_VERSION}
            </p>
          </div>
        </div>
        <footer className="grins-md:hidden">
          <p className="text-[10px] text-very-light-grey font-normal text-left align-bottom pt-8 pl-9">
            Version: {window?.ENV?.BUILD_VERSION}
          </p>
        </footer>
      </div>
    </div>
  )
}
