import * as rudderAnalytics from "rudder-sdk-js"

export interface EmitClientEventSfIdentifyProps {
  rudderStackUrl: string
  rudderStackWriteKey: string
  sfLeadId: string
  traits?: rudderAnalytics.apiObject
}

export function emitClientEventSfIdentify({
  rudderStackUrl,
  rudderStackWriteKey,
  sfLeadId,
  traits,
}: EmitClientEventSfIdentifyProps): void {
  const missingRudderStackConfigs =
    !rudderStackUrl.length || !rudderStackWriteKey.length

  if (missingRudderStackConfigs) {
    throw new Error(
      "RudderStack write key and/or URL config values are missing"
    )
  }

  rudderAnalytics.load(rudderStackWriteKey, rudderStackUrl)

  rudderAnalytics.ready(handleIdentifyEvent(sfLeadId, traits))
}

function handleIdentifyEvent(
  sfLeadId: EmitClientEventSfIdentifyProps["sfLeadId"],
  traits: EmitClientEventSfIdentifyProps["traits"]
) {
  return () =>
    rudderAnalytics.identify(
      { ...traits },
      {
        externalId: [{ type: "Salesforce-Lead", id: sfLeadId }],
        integrations: { Salesforce: true },
      }
    )
}
