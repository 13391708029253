"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatFileType = exports.extractDocumentArrayFromOneLoanData = exports.documentIsValid = exports.generateLoanOfficerEmployeeId = exports.mapOneLoanToLeadService = exports.extractAdditionalLoanTeamMembers = exports.mapLoanMaritalStatusTypeToLeadService = exports.determineAesfSourceByOriginationCompany = exports.determineIfFirstTimeHomeBuyer = exports.determineLeadAndReferralSource = exports.determineOriginCompanyName = exports.mapCustomFastTrackEligibilityHeaderValue = exports.extractEncompassFastTrackData = exports.mapLanguageFromOneLoanToSalesforce = void 0;
const _1 = require(".");
const grins_types_1 = require("grins-types");
const enumerations_1 = require("./data-model/enumerations");
const oneloan_to_myaccount_home_lead_1 = require("./oneloan-to-myaccount-home-lead");
const mapLanguageFromOneLoanToSalesforce = (languagePreference) => {
    switch (languagePreference) {
        case _1.OneLoanLanguageEnum.English:
            return "English";
        case _1.OneLoanLanguageEnum.Chinese:
            return "Chinese";
        case _1.OneLoanLanguageEnum.Korean:
            return "Korean";
        case _1.OneLoanLanguageEnum.Spanish:
            return "Spanish";
        case _1.OneLoanLanguageEnum.Tagalog:
            return "Tagalog";
        case _1.OneLoanLanguageEnum.Vietnamese:
            return "Vietnamese";
        default:
            return undefined;
    }
};
exports.mapLanguageFromOneLoanToSalesforce = mapLanguageFromOneLoanToSalesforce;
function extractEncompassFastTrackData(oneLoanData) {
    if (!oneLoanData.loan.customFields) {
        //if there's no customFields we won't find Fast Track data
        return undefined;
    }
    const customFieldFTEligibility = oneLoanData.loan.customFields.find((elem) => {
        if (elem.fieldName === _1.CustomFieldName.cxFTEligibility.toString()) {
            return true;
        }
    });
    const customFieldFPEligible = oneLoanData.loan.customFields.find((elem) => {
        if (elem.fieldName === _1.CustomFieldName.cxFPEligible.toString()) {
            return true;
        }
    });
    if (!customFieldFTEligibility && !customFieldFPEligible) {
        //don't return an object unless we have at least one of these populated with values
        return undefined;
    }
    return {
        fast_track_eligibility_tier: customFieldFTEligibility?.stringValue,
        fast_track_eligible: mapCustomFastTrackEligibilityHeaderValue(customFieldFPEligible?.stringValue),
    };
}
exports.extractEncompassFastTrackData = extractEncompassFastTrackData;
function mapCustomFastTrackEligibilityHeaderValue(stringValue) {
    return stringValue?.toLowerCase() === "yes"
        ? "Yes"
        : stringValue?.toLowerCase() === "no"
            ? "No"
            : undefined;
}
exports.mapCustomFastTrackEligibilityHeaderValue = mapCustomFastTrackEligibilityHeaderValue;
function determineOriginCompanyName(companyName) {
    let origCompanyName;
    switch (companyName.toLowerCase()) {
        case grins_types_1.CompanyName.citywide:
            origCompanyName = _1.LeadServiceOrigCompanies.cw;
            break;
        case grins_types_1.CompanyName.certainty:
        case grins_types_1.CompanyName.owng:
        case grins_types_1.CompanyName.gri:
            origCompanyName = _1.LeadServiceOrigCompanies.gri;
            break;
        case grins_types_1.CompanyName.op:
            origCompanyName = _1.LeadServiceOrigCompanies.op;
            break;
        case grins_types_1.CompanyName.prate:
            origCompanyName = _1.LeadServiceOrigCompanies.prate;
            break;
        case grins_types_1.CompanyName.gra:
            origCompanyName = _1.LeadServiceOrigCompanies.gra;
            break;
    }
    if (!origCompanyName) {
        throw new Error(`${companyName} does not map to LeadServiceOrigCompanies`);
    }
    return origCompanyName;
}
exports.determineOriginCompanyName = determineOriginCompanyName;
function determineLeadAndReferralSource(loanPurposeType) {
    return loanPurposeType === _1.LoanPurposeType.refinance.toString()
        ? _1.MyAccountLeadTypeEnum.refinance
        : _1.MyAccountLeadTypeEnum.purchase;
}
exports.determineLeadAndReferralSource = determineLeadAndReferralSource;
function determineIfFirstTimeHomeBuyer(firstTimeHomebuyersIndicator) {
    if (firstTimeHomebuyersIndicator === true)
        return enumerations_1.FirstTimeHomeBuyerFlag.yes;
    return enumerations_1.FirstTimeHomeBuyerFlag.no;
}
exports.determineIfFirstTimeHomeBuyer = determineIfFirstTimeHomeBuyer;
function determineAesfSourceByOriginationCompany(companyName) {
    let origCompanyName;
    switch (companyName.toLowerCase()) {
        case grins_types_1.CompanyName.citywide:
            origCompanyName = _1.AesfSourceEnum.cityWide;
            break;
        case grins_types_1.CompanyName.certainty:
            origCompanyName = _1.AesfSourceEnum.certainty;
            break;
        case grins_types_1.CompanyName.gri:
            origCompanyName = _1.AesfSourceEnum.guaranteedRate;
            break;
        case grins_types_1.CompanyName.op:
            origCompanyName = _1.AesfSourceEnum.originPoint;
            break;
        case grins_types_1.CompanyName.prate:
            origCompanyName = _1.AesfSourceEnum.properRate;
            break;
        case grins_types_1.CompanyName.owng:
            origCompanyName = _1.AesfSourceEnum.owning;
            break;
        case grins_types_1.CompanyName.gra:
            origCompanyName = _1.AesfSourceEnum.gra;
            break;
    }
    if (!origCompanyName) {
        throw new Error(`${companyName} does not map to AesfSourceEnum`);
    }
    return origCompanyName;
}
exports.determineAesfSourceByOriginationCompany = determineAesfSourceByOriginationCompany;
function mapLoanMaritalStatusTypeToLeadService(maritalStatusType) {
    let maritalStatus;
    switch (maritalStatusType?.toLowerCase()) {
        case "unmarried":
            maritalStatus = _1.LeadServiceMaritalStatus.single;
            break;
        case "married":
            maritalStatus = _1.LeadServiceMaritalStatus.married;
            break;
        case "separated":
            maritalStatus = _1.LeadServiceMaritalStatus.separated;
            break;
        default:
            maritalStatus = _1.LeadServiceMaritalStatus.single;
    }
    return maritalStatus;
}
exports.mapLoanMaritalStatusTypeToLeadService = mapLoanMaritalStatusTypeToLeadService;
function extractAdditionalLoanTeamMembers(oneLoanData) {
    let mortgageConsultantName;
    let loanCoordinatorName;
    let salesAssistantName;
    const teamMembers = oneLoanData?.["team-members"];
    if (teamMembers) {
        for (const member of teamMembers) {
            if (member.roleName === "Mortgage Consultant") {
                mortgageConsultantName = member.name;
            }
            else if (member.roleName === "Loan Coordinator") {
                loanCoordinatorName = member.name;
            }
            else if (member.roleName === "SaleAssist") {
                salesAssistantName = member.name;
            }
        }
    }
    return {
        mortgageConsultantName,
        loanCoordinatorName,
        salesAssistantName,
    };
}
exports.extractAdditionalLoanTeamMembers = extractAdditionalLoanTeamMembers;
function mapOneLoanToLeadService(oneLoanData, loan_guid, company, extractDocuments, campaign) {
    /**
     * Verify data
     */
    if (!oneLoanData.loan.applications ||
        oneLoanData.loan.applications.length <= 0) {
        throw new Error(`No loan applications found for OneLoan ID: ${oneLoanData.loan.loanIdNumber || "<unknown loan id>"} and GUID: ${oneLoanData["gr-loan-guid"] || "<unknown guid>"}`);
    }
    const application = oneLoanData.loan.applications[0];
    if (!application?.borrower?.lastName ||
        application?.borrower?.lastName === "") {
        throw new Error(`OneLoan to Salesforce mapping failed. User has no last name.`);
    }
    if (!application?.borrower?.firstName ||
        application?.borrower?.firstName === "") {
        throw new Error(`OneLoan to Salesforce mapping failed. User has no first name.`);
    }
    if ((!application?.borrower?.homePhoneNumber ||
        application?.borrower?.homePhoneNumber === "") &&
        (!application?.borrower?.mobilePhone ||
            application?.borrower?.mobilePhone === "") &&
        (!application?.borrower?.emailAddressText ||
            application?.borrower?.emailAddressText === "")) {
        throw new Error(`OneLoan to Salesforce mapping failed. User has no home, mobile phone, or email address`);
    }
    if (!oneLoanData.loan.loanNumber || oneLoanData.loan.loanNumber === "") {
        throw new Error(`OneLoan to Salesforce mapping failed. There is no loanNumber.`);
    }
    const loanOfficer = oneLoanData.loan.contacts?.find(item => item.contactType?.toString() === "LOAN_OFFICER");
    if (!loanOfficer) {
        throw new Error("OneLoan to Salesforce mapping failed. There is no loan officer.");
    }
    if (!loanOfficer.email || !loanOfficer.name) {
        throw new Error("OneLoan to Salesforce mapping failed. Loan officer information is missing.");
    }
    if (!oneLoanData.loan.loanProductData) {
        throw new Error("OneLoan to Salesforce mapping failed. Loan product data is missing.");
    }
    if (!oneLoanData.loan.property) {
        throw new Error("OneLoan to Salesforce mapping failed. Loan property data is missing.");
    }
    if (!oneLoanData.loan.property.loanPurposeType) {
        throw new Error("OneLoan to Salesforce mapping failed. Loan property purpose type is missing.");
    }
    if (!oneLoanData.loan.customFields) {
        throw new Error("Encompass data missing, loan.customFields array.");
    }
    const customField = oneLoanData.loan.customFields.find(elem => {
        if (elem.fieldName === "CX.LOCODE.1") {
            return true;
        }
    });
    if (!customField) {
        throw new Error("Encompass data missing, loan.customFields, array element with specific loan officer ID code.");
    }
    const customFieldLoanSource = oneLoanData.loan.customFields.find((elem) => {
        if (elem.fieldName === _1.CustomFieldName.cxEId.toString()) {
            return true;
        }
    });
    if (!oneLoanData.loan.hmda?.hmdaPropertyCity) {
        throw new Error("HMDA data is incomplete. Missing oneLoanData.loan.hmda.hmdaPropertyCity");
    }
    if (!oneLoanData.loan.hmda?.hmdaPropertyZipCode) {
        throw new Error("HMDA data is incomplete. Missing oneLoanData.loan.hmda.hmdaPropertyZipCode");
    }
    if (!oneLoanData.loan.hmda?.hmdaPropertyState) {
        throw new Error("HMDA data is incomplete. Missing oneLoanData.loan.hmda.hmdaPropertyState");
    }
    /**
     *  map applicant array
     */
    const applicantArray = [];
    const mapApplicant = (applicant) => {
        const applicantObject = {
            applicant_type: _1.LeadServiceV2ApplicantType.applicant,
            first_name: applicant.firstName,
            last_name: applicant.lastName,
            ...(applicant.middleName && { middle_name: applicant.middleName }),
        };
        if (applicant.birthDate) {
            applicantObject.date_of_birth = applicant.birthDate;
        }
        if (applicant.maritalStatusType) {
            applicantObject.marital_status = mapLoanMaritalStatusTypeToLeadService(applicant.maritalStatusType);
        }
        if (applicant.homePhoneNumber) {
            applicantObject.home_phone = applicant.homePhoneNumber;
        }
        if (applicant.mobilePhone) {
            applicantObject.mobile_phone = applicant.mobilePhone;
        }
        if (applicant.languagePreference) {
            applicantObject.preferred_language = (0, exports.mapLanguageFromOneLoanToSalesforce)(applicant.languagePreference);
        }
        if (applicant.emailAddressText) {
            applicantObject.email = applicant.emailAddressText;
        }
        return applicantObject;
    };
    const mapCoApplicant = (coApplicant) => {
        if (!coApplicant.firstName || !coApplicant.lastName) {
            throw Error("Incomplete coApplicant data");
        }
        const coApplicantObject = {
            applicant_type: _1.LeadServiceV2ApplicantType.coApplicant,
            first_name: coApplicant.firstName,
            last_name: coApplicant.lastName,
            ...(coApplicant.middleName && { middle_name: coApplicant.middleName }),
        };
        if (coApplicant.birthDate) {
            coApplicantObject.date_of_birth = coApplicant.birthDate;
        }
        if (coApplicant.maritalStatusType) {
            coApplicantObject.marital_status = mapLoanMaritalStatusTypeToLeadService(coApplicant.maritalStatusType);
        }
        if (coApplicant.homePhoneNumber) {
            coApplicantObject.home_phone = coApplicant.homePhoneNumber;
        }
        if (coApplicant.mobilePhone) {
            coApplicantObject.mobile_phone = coApplicant.mobilePhone;
        }
        if (coApplicant.languagePreference) {
            coApplicantObject.preferred_language = (0, exports.mapLanguageFromOneLoanToSalesforce)(coApplicant.languagePreference);
        }
        if (coApplicant.emailAddressText) {
            coApplicantObject.email = coApplicant.emailAddressText;
        }
        return coApplicantObject;
    };
    applicantArray.push(mapApplicant(application.borrower));
    if (application.coborrower &&
        !application.coborrower.noCoApplicantIndicator) {
        applicantArray.push(mapCoApplicant(application.coborrower));
    }
    /**
     *  map loan_info object
     */
    const { mortgageConsultantName, loanCoordinatorName, salesAssistantName } = extractAdditionalLoanTeamMembers(oneLoanData);
    const loanInfo = {
        loan_guid: loan_guid,
        loan_number: oneLoanData.loan.loanNumber,
        estimated_closing_date: oneLoanData.loan.loanProductData.loanScheduledClosingDate,
        ...extractEncompassFastTrackData(oneLoanData),
        loan_documents: extractDocuments
            ? (0, exports.extractDocumentArrayFromOneLoanData)(oneLoanData)
            : [],
        mortgage_consultant_name: mortgageConsultantName,
        loan_coordinator_name: loanCoordinatorName,
        sales_assistant_name: salesAssistantName,
        current_milestone: oneLoanData.loan.milestoneCurrentName,
        current_milestone_date: oneLoanData.loan.milestoneCurrentDateUtc,
        adverse_status: oneLoanData.loan.adverseStatus,
        will_escrow: oneLoanData.loan.closingCost?.closingDisclosure1
            ?.inEscrowHomeownerInsurance === "YES"
            ? true
            : false,
        occupancy_type: oneLoanData.loan.property.occupancyDisplayField,
    };
    if (oneLoanData.loan.firstTimeHomebuyersIndicator) {
        loanInfo.first_time_home_buyer = determineIfFirstTimeHomeBuyer(oneLoanData.loan.firstTimeHomebuyersIndicator);
    }
    /**
     *  map address array
     */
    const addressArray = [
        {
            address_type: _1.LeadServiceV2AddressType.insured,
            street1: oneLoanData.loan.hmda.hmdaPropertyAddress,
            city: oneLoanData.loan.hmda.hmdaPropertyCity,
            postal_code: oneLoanData.loan.hmda.hmdaPropertyZipCode,
            state: oneLoanData.loan.hmda.hmdaPropertyState,
        },
    ];
    const priorAddress = (0, oneloan_to_myaccount_home_lead_1.getEffectivePriorAddress)(oneLoanData);
    if (priorAddress) {
        addressArray.push({
            address_type: _1.LeadServiceV2AddressType.prior,
            street1: `${priorAddress.streetNumber} ${priorAddress.streetName}`.trim(),
            city: priorAddress.city,
            postal_code: priorAddress.zipCode5,
            state: priorAddress.state,
        });
    }
    switch (customFieldLoanSource?.stringValue) {
        case _1.CustomFieldLoanSourceEnum.CEHL:
            company = grins_types_1.CompanyName.certainty;
            break;
        case _1.CustomFieldLoanSourceEnum.OWNG:
            company = grins_types_1.CompanyName.owng;
            break;
        default:
            break;
    }
    const mappedValues = {
        product_line_type: _1.LeadServiceV2ProductLineType.home,
        record_type_name: _1.LeadServiceRecordTypeNames.encompassLeads,
        lead_rating: _1.LeadRatingEnum.hot,
        applicant: applicantArray,
        tracking: {
            source: determineLeadAndReferralSource(oneLoanData.loan.property.loanPurposeType),
            aesf_source: determineAesfSourceByOriginationCompany(company),
            channel: _1.SalesforceChannelEnum.mortgage,
            campaign: campaign ? campaign : "MyAccount",
            method: _1.SalesforceMethodEnum.onlineMarketplace,
        },
        loan_officer: {
            orig_company: determineOriginCompanyName(company),
            name: loanOfficer.name,
            employee_email: loanOfficer.email,
            employee_id: generateLoanOfficerEmployeeId(customField.stringValue, company),
        },
        loan_info: loanInfo,
        address: addressArray,
    };
    return mappedValues;
}
exports.mapOneLoanToLeadService = mapOneLoanToLeadService;
function generateLoanOfficerEmployeeId(loanOfficerEmployeeId, companyName) {
    let generatedLoanOfficerEmployeeId = loanOfficerEmployeeId;
    switch (companyName) {
        case grins_types_1.CompanyName.citywide:
            generatedLoanOfficerEmployeeId = "CW_" + loanOfficerEmployeeId;
            break;
        case grins_types_1.CompanyName.certainty:
            generatedLoanOfficerEmployeeId = "CE_" + loanOfficerEmployeeId;
            break;
        case grins_types_1.CompanyName.op:
            generatedLoanOfficerEmployeeId = "OP_" + loanOfficerEmployeeId;
            break;
        case grins_types_1.CompanyName.prate:
            generatedLoanOfficerEmployeeId = "PR_" + loanOfficerEmployeeId;
            break;
        case grins_types_1.CompanyName.gra:
            generatedLoanOfficerEmployeeId = "GRA_" + loanOfficerEmployeeId;
            break;
        case grins_types_1.CompanyName.gri:
        case grins_types_1.CompanyName.owng:
        default:
            generatedLoanOfficerEmployeeId = loanOfficerEmployeeId;
            break;
    }
    return generatedLoanOfficerEmployeeId;
}
exports.generateLoanOfficerEmployeeId = generateLoanOfficerEmployeeId;
const LOAN_DOCUMENT_TITLES = [
    "1003 - URLA",
    "Appraisal",
    "Closing Disclosure",
    "Insurance",
    "Invoices",
    "Loan Estimate",
    "Flood Cert",
    "Purchase Contract",
];
/**
 * Checks if a document is valid based on certain criteria.
 *
 * @param {OneLoanDocument} document - The document to check.
 * @returns {boolean} True if the document is valid, false otherwise.
 */
const documentIsValid = (document) => {
    const documentIsRelevant = document.attachments.length > 0 && document.isReceived;
    const documentIsExpected = LOAN_DOCUMENT_TITLES.some(title => 
    // We are intentionally using startsWith instead of includes to reduce the number of false positives
    document.titleWithIndex.startsWith(title));
    const documentIsValid = documentIsRelevant && documentIsExpected;
    return documentIsValid;
};
exports.documentIsValid = documentIsValid;
/**
 * Extracts an array of lead service loan documents from the provided OneLoanResponse.
 *
 * @param {OneLoanResponse} oneLoanData - The OneLoanResponse to extract the documents from.
 * @returns {LeadServiceLoanDocuments[]} An array of validated and formatted lead service loan documents.
 */
const extractDocumentArrayFromOneLoanData = (oneLoanData) => {
    const validatedAndFormattedDocuments = [];
    // Some loans don't have documents
    if (!oneLoanData.documents) {
        return validatedAndFormattedDocuments;
    }
    // Loans can have multiple documents
    oneLoanData.documents.forEach(document => {
        // We ignore documents that we are not expecting
        const documentIsInvalid = !(0, exports.documentIsValid)(document);
        if (documentIsInvalid) {
            return;
        }
        // Documents can have multiple attachments
        document.attachments.forEach(attachment => {
            const attachmentIsNotActive = !attachment.isActive;
            if (attachmentIsNotActive) {
                return;
            }
            const newDoc = {
                file_type: (0, exports.formatFileType)(document.titleWithIndex),
                name: attachment.entityName,
                external_id: attachment.entityId,
            };
            validatedAndFormattedDocuments.push(newDoc);
        });
    });
    return validatedAndFormattedDocuments;
};
exports.extractDocumentArrayFromOneLoanData = extractDocumentArrayFromOneLoanData;
/**
 * Formats the file type based on a predefined list of expected file types.
 * If the file type matches any of the expected file types, it returns the matching file type.
 * Otherwise, it returns the original file type.
 *
 * @param {string} fileType - The file type to format.
 * @returns {string} The formatted file type.
 */
const formatFileType = (fileType) => {
    const expectedFileType = LOAN_DOCUMENT_TITLES.find(expectedFileType => fileType.includes(expectedFileType));
    const formattedFileType = expectedFileType ?? fileType;
    return formattedFileType;
};
exports.formatFileType = formatFileType;
