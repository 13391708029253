"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.quoteServiceHomeQuoteToLeadService = void 0;
function quoteServiceHomeQuoteToLeadService(quoteServiceQuotes) {
    const convertedValues = quoteServiceQuotes.map(quoteServiceQuote => {
        return {
            ...(quoteServiceQuote.agentUrl && {
                agent_url: quoteServiceQuote.agentUrl,
            }),
            carrier_id: quoteServiceQuote.carrierId,
            carrier_logo: quoteServiceQuote.carrierLogo,
            carrier_name: quoteServiceQuote.carrierName,
            ...(quoteServiceQuote.deductible && {
                deductible: quoteServiceQuote.deductible,
            }),
            ...(quoteServiceQuote.dwellingCoverage && {
                dwelling_coverage: quoteServiceQuote.dwellingCoverage,
            }),
            external_id: quoteServiceQuote.externalId,
            ...(quoteServiceQuote.lossOfUseCoverage && {
                loss_of_use_coverage: quoteServiceQuote.lossOfUseCoverage,
            }),
            ...(quoteServiceQuote.medicalPaymentsCoverage && {
                medical_payments_coverage: quoteServiceQuote.medicalPaymentsCoverage,
            }),
            ...(quoteServiceQuote.otherStructuresCoverage && {
                other_structures_coverage: quoteServiceQuote.otherStructuresCoverage,
            }),
            ...(quoteServiceQuote.personalPropertyCoverage && {
                personal_property_coverage: quoteServiceQuote.personalPropertyCoverage,
            }),
            ...(quoteServiceQuote.costPerYear && {
                cost_per_year: quoteServiceQuote.costPerYear,
            }),
            ...(quoteServiceQuote.quoteId && {
                quote_id: quoteServiceQuote.quoteId,
            }),
            status: quoteServiceQuote.status,
            status_message: quoteServiceQuote.statusMessage,
        };
    });
    return convertedValues;
}
exports.quoteServiceHomeQuoteToLeadService = quoteServiceHomeQuoteToLeadService;
