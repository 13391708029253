import * as rudderAnalytics from "rudder-sdk-js"

export function emitClientEventEmptyIdentify(
  rudderStackUrl: string,
  rudderStackWriteKey: string
): void {
  const missingRudderStackConfigs =
    !rudderStackUrl.length || !rudderStackWriteKey.length

  if (missingRudderStackConfigs) {
    throw new Error(
      "RudderStack write key and/or URL config values are missing"
    )
  }

  rudderAnalytics.load(rudderStackWriteKey, rudderStackUrl)

  rudderAnalytics.ready(handleIdentifyEvent())
}

function handleIdentifyEvent() {
  return () => rudderAnalytics.identify("0")
}
