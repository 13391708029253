import { AddressInput, Button, Select, TextInput } from "grins-ui"
import PageHeader from "../../components/page-header"
import { ChangeEventHandler } from "react"
import { store } from "../../store"
import { OmsiteAddressObject } from "grid-transform"
import { UsState, UsStateJsMap } from "grins-utils"
import get from "lodash/get"
import { usePageLoadEvent } from "../../utils/session"

export default function AddGaragingAddress({ onNext }: { onNext: () => void }) {
  usePageLoadEvent({
    page_path: "/auto/address",
    page_title: "Auto Address Info",
  })
  const isSubmitting = store.useState(s => s.isSubmitting)
  const inputState = store.useState(s => ({
    property: s.autoApplication.property,
  }))

  const usStatesArray: string[] = [
    ...new Set(Array.from(UsStateJsMap.values())),
  ]
  const handleChange = ({
    e,
    address,
  }: {
    e?: React.ChangeEvent
    address?: OmsiteAddressObject | string
  }) => {
    const target = e?.target as HTMLInputElement
    const name = target?.name
    const value = target?.value
    store.update(s => {
      if (address !== undefined && typeof address === "object") {
        s.autoApplication.property.address = address
      } else if (typeof address === "string") {
        const [streetNumber, streetName] = address.split(/\s+(.*)/)
        s.autoApplication.property.address.streetName = streetName
        s.autoApplication.property.address.streetNumber = streetNumber
      } else {
        s.autoApplication.property.address[name as keyof OmsiteAddressObject] =
          value
      }
    })
  }

  const handleStateChange = (value: number | null) => {
    store.update(s => {
      if (value === null) {
        s.autoApplication.property.address.state = null
      } else {
        s.autoApplication.property.address.state = usStatesArray[value]
      }
    })
  }

  const handleZipChange: ChangeEventHandler<HTMLInputElement> = e => {
    const newValue = e.target.value.replace(/\D/g, "")
    store.update(s => {
      s.autoApplication.property.address.zip = newValue
    })
  }

  const shouldButtonBeDisabled = () => {
    const {
      property: { address },
    } = inputState
    if (
      address.streetNumber.length > 0 &&
      get(address, "streetName", "").length > 0 &&
      address.city.length > 2 &&
      address.state &&
      address.state.length == 2 &&
      address.zip.length === 5
    ) {
      return false
    } else {
      return true
    }
  }

  const findSelectedStateIndex = () => {
    return inputState.property.address.state === ""
      ? null
      : usStatesArray.indexOf(inputState.property.address.state as UsState)
  }

  return (
    <div>
      <PageHeader testId="add-garaging-header-h2">
        What's your address?
      </PageHeader>
      <div className="max-w-sm">
        <AddressInput
          testId="garaging-address-input"
          className="my-6"
          apiKey={window?.ENV?.GOOGLE_MAPS_API_KEY}
          onChange={address =>
            handleChange({ address: address as OmsiteAddressObject | string })
          }
        />
        <TextInput
          data-testid="apt-suite-number-input"
          name="aptSuiteNumber"
          className="my-6"
          label="APT/Suite number"
          value={inputState.property.address.aptSuiteNumber}
          onChange={e => handleChange({ e })}
        />
        <div className="flex gap-3 my-6 mb-14">
          <TextInput
            data-testid="city-input"
            name="city"
            className="w-36"
            label="City"
            value={inputState.property.address.city}
            onChange={e => handleChange({ e })}
          />
          <Select
            testId="state-select"
            autoCompleteName="address-level1"
            className="w-28"
            inputName="state"
            label="State"
            onSelectOption={handleStateChange}
            options={usStatesArray}
            selectedOption={findSelectedStateIndex()}
          />
          <TextInput
            data-testid="zip-input"
            name="zip"
            className="w-36"
            label="Zip"
            inputMode="numeric"
            maxLength={5}
            value={inputState.property.address.zip}
            onChange={handleZipChange}
          />{" "}
        </div>
        <Button
          data-testid="next-btn"
          size="large-square"
          label="Next"
          loading={isSubmitting}
          disabled={shouldButtonBeDisabled()}
          onClick={onNext}
        />
      </div>
    </div>
  )
}
