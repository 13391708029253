export const calculateMaxStringLengthIncludingCommaSeparators = (
  stringLength: number
): number => {
  let numberOfCommas = 0
  if (stringLength > 3) {
    numberOfCommas = Math.floor((stringLength - 1) / 3)
  }
  return stringLength + numberOfCommas
}

export const getFormattedValueAndModifiedMaxLength = (
  value: string,
  maxLength: number,
  formatType: string
): { formattedValue: string; modifiedMaxLength: number } => {
  let modifiedMaxLength = maxLength
  let formattedValue = value
  switch (formatType) {
    case "money":
      formattedValue = `$${Number(value).toLocaleString()}`
      modifiedMaxLength =
        calculateMaxStringLengthIncludingCommaSeparators(maxLength) + 1
      break
    case "number":
      formattedValue = Number(value).toLocaleString()
      modifiedMaxLength =
        calculateMaxStringLengthIncludingCommaSeparators(maxLength)
      break
    case "year":
      modifiedMaxLength = 4
      break
  }
  return { formattedValue, modifiedMaxLength }
}
