import QuotesSummary from "./quotes-summary"
import NoQuotes from "../no-quotes"
import { store } from "../../store"

export default function QuotesDisplay() {
  const { topQuotes, bindableQuotes } = store.useState(s => ({
    topQuotes: s.topQuotes,
    bindableQuotes: s.bindableQuotes,
  }))
  return (topQuotes && topQuotes.length > 0) ||
    (bindableQuotes && bindableQuotes.length > 0) ? (
    <QuotesSummary />
  ) : (
    <NoQuotes />
  )
}
