import { Button } from "grins-ui"
import { useEffect } from "react"
import {
  addApplicantToProgress,
  addCoApplicantToProgress,
  addHomeAddressToProgress,
} from "../../actions"
import PageHeader from "../../components/page-header"
import { store } from "../../store"

interface Props {
  onNext: () => void
}

export default function PrefillPage({ onNext }: Props) {
  const { applicant, coApplicant, property } = store.useState(
    s => s.application
  )
  const isSubmitting = store.useState(s => s.isSubmitting)
  const isStateCalifornia = property.address.state === "CA"
  const populateProgressSubtext = () => {
    // Warning: For design, it's important to add data in this order
    addApplicantToProgress()
    addCoApplicantToProgress()
    addHomeAddressToProgress(0)
  }

  useEffect(() => {
    populateProgressSubtext()
  }, [applicant, coApplicant, property])

  const renderNames = () => {
    let names = `${applicant.firstName} ${applicant.lastName}`
    if (coApplicant.exists)
      names += ` & ${coApplicant.firstName} ${coApplicant.lastName}`
    return <div className="font-bold text-lg">{names}</div>
  }

  const renderStreet = () => {
    let address = `${property.address.streetNumber} ${property.address.streetName}`
    if (property.address.aptSuiteNumber)
      address += `, ${property.address.aptSuiteNumber}`
    return <div className="font-bold text-base">{address}</div>
  }

  const renderCityStateZip = () => {
    return (
      <div className="font-bold text-base">
        {property.address.city}, {property.address.state} {property.address.zip}
      </div>
    )
  }

  const businessName = isStateCalifornia
    ? "Rate Insurance Agency, LLC"
    : "Rate Insurance"

  const renderDisclaimer = () => {
    return (
      <div
        className="text-2xs mt-6 mb-11 text-white leading-3"
        data-testid="prefill-disclaimer-div"
      >
        {`To provide accurate quotes, some of the insurance companies we represent
        will confirm your information through a consumer credit report. By
        clicking "Next" you authorize ${businessName} permission to
        order your credit information.`}
      </div>
    )
  }

  return (
    <div className="sm:max-w-md">
      <PageHeader testId="prefill-page-header-h2" className="my-6">
        Hey {applicant.firstName}!
      </PageHeader>
      <div className="text-2xl text-white">
        Let’s double check your info below
      </div>
      <div className="my-4 flex items-center gap-10 bg-white py-4 px-4 rounded-lg">
        <img src="/public/home-image-whitebg.svg" aria-hidden />
        <div className="flex flex-col gap-1.5">
          {renderNames()}
          <div>
            {renderStreet()}
            {renderCityStateZip()}
          </div>
        </div>
      </div>
      {renderDisclaimer()}
      <Button
        data-testid="next-btn"
        size="large-square"
        label="Next"
        loading={isSubmitting}
        onClick={onNext}
      />
    </div>
  )
}
