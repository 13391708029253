import { useEffect, useState } from "react"
import {
  Button,
  Select,
  SelectComplexOptions,
  SelectOptionsType,
} from "grins-ui"
import { Ymm } from "grid-transform"
import {
  fetchVehicleMakes,
  fetchVehicleModels,
  fetchVehicleSubModels,
  fetchVehicleYears,
} from "../../utils/api"
import { store } from "../../store"
import PageHeader from "../../components/page-header"
import { usePageLoadEvent } from "../../utils/session"

export interface Props {
  allowBack?: boolean
  onBack?: () => void
  onNext: (ymm: Ymm) => void
}

export default function VehicleYmm({
  allowBack = false,
  onBack,
  onNext,
}: Props) {
  usePageLoadEvent({
    page_path: "/auto/vehicle",
    page_title: "Auto Vehicle Info",
  })

  const isSubmitting = store.useState(s => s.isSubmitting)

  const [years, setYears] = useState<string[]>([])
  const [makes, setMakes] = useState<string[]>([])
  const [models, setModels] = useState<string[]>([])
  const [subModels, setSubModels] = useState<SelectOptionsType<string>[]>([])

  const [selectedYearIndex, setSelectedYearIndex] = useState<number | null>(
    null
  )
  const [selectedMakeIndex, setSelectedMakeIndex] = useState<number | null>(
    null
  )
  const [selectedModelIndex, setSelectedModelIndex] = useState<number | null>(
    null
  )
  const [selectedSubModelIndex, setSelectedSubModelIndex] = useState<
    number | null
  >(null)

  useEffect(() => {
    const fetchYears = async () => {
      const newYears = await fetchVehicleYears()
      setYears(newYears.map(String))
    }
    fetchYears().catch(() => console.log("VehicleYmm: failed to fetch years"))
  }, [])

  useEffect(() => {
    const fetchMakes = async () => {
      if (selectedYearIndex === null) return
      const newMakes = await fetchVehicleMakes(years[selectedYearIndex])
      setMakes(newMakes)
    }
    fetchMakes().catch(() => console.log("VehicleYmm: failed to fetch makes"))
  }, [years, selectedYearIndex])

  useEffect(() => {
    const fetchModels = async () => {
      if (selectedYearIndex === null || selectedMakeIndex === null) return
      const newModels = await fetchVehicleModels(
        years[selectedYearIndex],
        makes[selectedMakeIndex]
      )
      setModels(newModels)
    }
    fetchModels().catch(() => console.log("VehicleYmm: failed to fetch models"))
  }, [years, selectedYearIndex, makes, selectedMakeIndex])

  function deDup(
    input: SelectOptionsType<string>[]
  ): SelectOptionsType<string>[] {
    const map = new Map()
    for (let idx = 0; idx < input.length; idx++) {
      if (!map.has(input[idx].uiLabel)) map.set(input[idx].uiLabel, input[idx])
    }
    return Array.from(map.values()) as SelectOptionsType<string>[]
  }

  useEffect(() => {
    const fetchSubModels = async () => {
      if (
        selectedYearIndex === null ||
        selectedMakeIndex === null ||
        selectedModelIndex === null
      ) {
        return
      }
      const newSubModels: SelectOptionsType<string>[] =
        await fetchVehicleSubModels(
          years[selectedYearIndex],
          makes[selectedMakeIndex],
          models[selectedModelIndex]
        )
      setSubModels(deDup(newSubModels))
    }
    fetchSubModels().catch(() =>
      console.log("VehicleYmm: failed to fetch subModels")
    )
  }, [
    years,
    selectedYearIndex,
    makes,
    selectedMakeIndex,
    models,
    selectedModelIndex,
  ])

  const handleSelectYear = (selectedIndex: number | null) => {
    setSelectedYearIndex(selectedIndex)
    if (
      selectedYearIndex === null ||
      selectedIndex === null ||
      selectedYearIndex.valueOf() !== selectedIndex
    ) {
      setMakes([])
      setModels([])
      setSubModels([])
    }
    setSelectedMakeIndex(null)
    setSelectedModelIndex(null)
    setSelectedSubModelIndex(null)
  }

  const handleSelectMake = (selectedIndex: number | null) => {
    setSelectedMakeIndex(selectedIndex)
    if (
      selectedMakeIndex === null ||
      selectedIndex === null ||
      selectedMakeIndex.valueOf() !== selectedIndex
    ) {
      setModels([])
      setSubModels([])
    }
    setSelectedModelIndex(null)
    setSelectedSubModelIndex(null)
  }

  const handleSelectModel = (selectedIndex: number | null) => {
    setSelectedModelIndex(selectedIndex)
    if (
      selectedModelIndex === null ||
      selectedIndex === null ||
      selectedModelIndex.valueOf() !== selectedIndex
    ) {
      setSubModels([])
    }
    setSelectedSubModelIndex(null)
  }

  const handleSelectSubModel = (selectedIndex: number | null) => {
    setSelectedSubModelIndex(selectedIndex)
  }

  const handleNext = () => {
    if (
      selectedYearIndex === null ||
      selectedMakeIndex === null ||
      selectedModelIndex === null ||
      selectedSubModelIndex === null
    ) {
      return
    }
    onNext({
      year: years[selectedYearIndex],
      make: makes[selectedMakeIndex],
      model: models[selectedModelIndex],
      subModel: {
        description: subModels[selectedSubModelIndex].uiLabel,
        vin: subModels[selectedSubModelIndex].value.base,
      },
    })
  }
  const handleBack = () => {
    if (onBack) onBack()
  }

  const isNextEnabled =
    selectedYearIndex !== null &&
    selectedMakeIndex !== null &&
    selectedModelIndex !== null &&
    selectedSubModelIndex !== null

  return (
    <div className="w-full">
      <PageHeader testId="vehicle-ymm-header-h2" className="text-white mb-6">
        {store.getRawState().autoApplication.vehicles.length >= 1
          ? "Add your other vehicle"
          : "Time to add your vehicle"}
      </PageHeader>
      <div className="flex flex-col gap-6 max-w-xs">
        <Select
          testId="vehicle-year-select"
          label="Year"
          options={years.sort((a, b) => {
            if (a > b) return -1
            if (a < b) return 1
            return 0
          })}
          selectedOption={selectedYearIndex}
          onSelectOption={handleSelectYear}
        />
        <Select
          testId="vehicle-make-select"
          disabled={selectedYearIndex === null}
          label="Make"
          options={makes}
          selectedOption={selectedMakeIndex}
          onSelectOption={handleSelectMake}
        />
        <Select
          testId="vehicle-model-select"
          disabled={selectedMakeIndex === null}
          label="Model"
          options={models}
          selectedOption={selectedModelIndex}
          onSelectOption={handleSelectModel}
        />
        <SelectComplexOptions
          testId="vehicle-submodel-select"
          disabled={selectedModelIndex === null}
          label="Trim or sub model"
          options={subModels}
          selectedOption={selectedSubModelIndex}
          onSelectOption={handleSelectSubModel}
        />
      </div>
      <div className="flex gap-2 mt-14">
        {allowBack && (
          <button
            data-testid="back-btn"
            className="text-white"
            type="button"
            onClick={handleBack}
          >
            {"<"} Back
          </button>
        )}
        <Button
          data-testid="next-btn"
          disabled={!isNextEnabled}
          label={allowBack ? "Add" : "Next"}
          loading={isSubmitting}
          size="large-square"
          onClick={handleNext}
        />
      </div>
    </div>
  )
}
