import { DateTime } from "luxon"
import {
  ClientApplication,
  ClientApplicant,
  ClientCoApplicant,
  ClientProperty,
} from "../store"

import {
  GrinsMaritalStatus,
  OmsiteApplicant,
  OmsiteCoApplicant,
  OmsiteProperty,
  MyAccountHomeLead,
  OmsiteMaritalStatus,
  OmsiteRelationshipToApplicant,
  OmsiteApplicantRelation,
  OmsiteDriver,
  OmsiteVehicle,
  OmsiteAutoOwnership,
  OmsiteAutoUsage,
  ClientDriverApplicant,
  Vehicle,
} from "grid-transform"
import { resetPastLuxonDateToFutureDate } from "grins-utils"

export function clientApplicantToServer(
  applicant: ClientApplicant
): OmsiteApplicant {
  return {
    ...applicant,
    dateOfBirth: applicant.dateOfBirth?.toLocaleString(), // Salesforce AddLead requires date to be in mm/dd/yyyy format
  } as OmsiteApplicant // cast bypasses null checks that we don't need
}

export function clientVehicleToServer(clientVehicle: Vehicle): OmsiteVehicle {
  const omsiteVehicle: OmsiteVehicle = {
    year: clientVehicle.ymm.year,
    make: clientVehicle.ymm.make,
    model: clientVehicle.ymm.model,
    subModelTrim: clientVehicle.ymm.subModel.description,
    vin: clientVehicle.ymm.subModel.vin,
    vehicleOwnership: clientToServerVehiclOwnership(
      clientVehicle.details.ownership
    ),
    vehicleUsage: clientToServerVehicleUsage(clientVehicle.details.usage),
    annualMileage: clientVehicle.details.miles,
  }

  return omsiteVehicle
}

export function clientDriverApplicantToServer(
  driverApplicant: ClientDriverApplicant
): OmsiteDriver {
  return {
    ...driverApplicant,
    dateOfBirth: driverApplicant.dateOfBirth?.toLocaleString(), // Salesforce AddLead requires date to be in mm/dd/yyyy format
  } as OmsiteDriver // cast bypasses null checks that we don't need
}

export function clientCoApplicantToServer(
  coApplicant: ClientCoApplicant
): OmsiteCoApplicant {
  return {
    ...coApplicant,
    dateOfBirth: coApplicant.dateOfBirth?.toISODate(),
  } as OmsiteCoApplicant // cast bypasses null checks that we don't need
}

export function clientPropertyToServer(
  property: ClientProperty
): OmsiteProperty {
  return {
    ...property,
    purchaseDate: property.purchaseDate?.toISODate(),
  } as OmsiteProperty // cast bypasses null checks that we don't need
}

export function clientApplicationToServer(application: ClientApplication) {
  return {
    applicant: clientApplicantToServer(application.applicant),
    coApplicant: clientCoApplicantToServer(application.coApplicant),
    property: clientPropertyToServer(application.property),
    promptAnswers: application.promptAnswers,
  }
}

export function serverApplicationToClient(
  homeLead: MyAccountHomeLead
): ClientApplication {
  const homeLeadPurchaseDate = DateTime.fromJSDate(
    new Date(homeLead.dateOfPurchase),
    {
      zone: "utc",
    }
  )

  const defaultApplicantDob = new Date()
  const applicantDobString = homeLead.applicant.dob
    ? homeLead.applicant.dob.toString()
    : defaultApplicantDob.toISOString()
  const coApplicantDobString = homeLead.coApplicant?.dob
    ? homeLead.coApplicant?.dob.toString()
    : defaultApplicantDob.toISOString()
  const clientApplication: ClientApplication = {
    applicant: {
      firstName: homeLead.applicant.firstName,
      lastName: homeLead.applicant.lastName,
      dateOfBirth: DateTime.fromISO(applicantDobString),
      maritalStatus: serverToClientMaritalStatus(
        homeLead.applicant.maritalStatus
      ),
      phoneNumber: homeLead.applicant.phone,
      effectivePriorAddress: homeLead.effectivePriorAddress,
    },
    coApplicant: {
      firstName: homeLead.coApplicant?.firstName || "",
      lastName: homeLead.coApplicant?.lastName || "",
      dateOfBirth: DateTime.fromISO(coApplicantDobString),
      relationshipToApplicant: serverToClientCoApplicantRelationship(
        homeLead.coApplicant ? true : false,
        homeLead.coApplicant?.relation
      ),
      exists: homeLead.coApplicant ? true : false,
    },
    property: {
      address: {
        streetNumber: homeLead.address.streetNumber,
        streetName: homeLead.address.streetName,
        aptSuiteNumber: homeLead.address.aptSuiteNumber,
        city: homeLead.address.city,
        state: homeLead.address.state,
        zip: homeLead.address.zip,
      },
      // if the purchase date from MyAccount is before today, we want to set the purchase date to the current date + 7 days
      purchaseDate: resetPastLuxonDateToFutureDate(homeLeadPurchaseDate, 7),
    },
    promptAnswers: [],
  }

  validateCoApplicant(clientApplication)

  return clientApplication
}

export function validateCoApplicant(clientApplication: ClientApplication) {
  const exists = clientApplication.coApplicant.exists
  if (
    exists &&
    (!clientApplication.coApplicant.firstName ||
      clientApplication.coApplicant.firstName.trim() === "")
  )
    throw new Error(`CoApplicant exists, but first name is missing or blank.`)

  if (
    exists &&
    (!clientApplication.coApplicant.lastName ||
      clientApplication.coApplicant.lastName.trim() === "")
  )
    throw new Error(`CoApplicant exists, but last name is missing or blank.`)
  if (exists && !clientApplication.coApplicant.dateOfBirth)
    throw new Error(`CoApplicant exists, but dote of birth is missing.`)

  // Relationship validation happens in serverToClientCoApplicantRelationship()
}

export function serverToClientCoApplicantRelationship(
  coApplicantExists: boolean,
  serverRelationship?: OmsiteApplicantRelation
): OmsiteRelationshipToApplicant {
  if (!coApplicantExists) {
    // in this case, the return value is inconsequential by virtue of coApp object definition (probably need to revisit)
    return "Spouse"
  }
  if (!serverRelationship) {
    throw new Error(
      `CoApplicant prefill, relationship data not received from server.`
    )
  }
  if (serverRelationship === OmsiteApplicantRelation.spouse) {
    return "Spouse"
  } else if (serverRelationship === OmsiteApplicantRelation.domesticPartner) {
    return "Domestic Partner"
  }

  throw new Error(
    `CoApplicant prefill, relationship data from server is invalid.`
  )
}

export function serverToClientMaritalStatus(
  serverMaritalStatus: GrinsMaritalStatus
): OmsiteMaritalStatus {
  let clientMaritalStatus = OmsiteMaritalStatus.Single
  switch (serverMaritalStatus) {
    case GrinsMaritalStatus.divorced:
      clientMaritalStatus = OmsiteMaritalStatus.Divorced
      break
    case GrinsMaritalStatus.domesticPartner:
      clientMaritalStatus = OmsiteMaritalStatus.DomesticPartner
      break
    case GrinsMaritalStatus.married:
      clientMaritalStatus = OmsiteMaritalStatus.Married
      break
    case GrinsMaritalStatus.separated:
      clientMaritalStatus = OmsiteMaritalStatus.Separated
      break
    case GrinsMaritalStatus.single:
      clientMaritalStatus = OmsiteMaritalStatus.Single
      break
    case GrinsMaritalStatus.widowed:
      clientMaritalStatus = OmsiteMaritalStatus.Widowed
      break
    default:
      clientMaritalStatus = OmsiteMaritalStatus.Single
      break
  }

  return clientMaritalStatus
}

export function clientToServerVehicleUsage(
  vehicleUsage: string
): OmsiteAutoUsage {
  let omsiteAutoUsage: OmsiteAutoUsage

  switch (vehicleUsage) {
    case OmsiteAutoUsage.business:
      omsiteAutoUsage = OmsiteAutoUsage.business
      break
    case OmsiteAutoUsage.farming:
      omsiteAutoUsage = OmsiteAutoUsage.farming
      break
    case OmsiteAutoUsage.pleasure:
      omsiteAutoUsage = OmsiteAutoUsage.pleasure
      break
    case OmsiteAutoUsage.toFromSchool:
      omsiteAutoUsage = OmsiteAutoUsage.toFromSchool
      break
    case OmsiteAutoUsage.toFromWork:
      omsiteAutoUsage = OmsiteAutoUsage.toFromWork
      break
    default:
      omsiteAutoUsage = OmsiteAutoUsage.business
  }

  return omsiteAutoUsage
}

export function clientToServerVehiclOwnership(
  vehicleOwnership: string
): OmsiteAutoOwnership {
  let omsiteAutoOwnership: OmsiteAutoOwnership

  switch (vehicleOwnership) {
    case OmsiteAutoOwnership.leased:
      omsiteAutoOwnership = OmsiteAutoOwnership.leased
      break
    case OmsiteAutoOwnership.lien:
      omsiteAutoOwnership = OmsiteAutoOwnership.lien
      break
    case OmsiteAutoOwnership.owned:
      omsiteAutoOwnership = OmsiteAutoOwnership.owned
      break
    default:
      omsiteAutoOwnership = OmsiteAutoOwnership.leased
  }

  return omsiteAutoOwnership
}

export function agentRegex(pathname: string) {
  // Please keep the regex101 link updated for the regex used below
  // https://regex101.com/r/4cTaol/1
  return pathname.match(/(\/agents\/)([\w'\s]+)-(.+)/)
}
