/* eslint-disable @typescript-eslint/no-misused-promises */
import { init as initRum } from "@elastic/apm-rum"
import { Route, Routes } from "react-router-dom"
import { MAX_TOTAL_DRIVERS, store } from "./store"
import { VehicleDetails, Ymm } from "grid-transform"
import {
  addBlankAdditionalDriver,
  addVehicle,
  navigatePage,
  removeLastAdditionalDriver,
  addApplicantToProgress,
  addCoApplicantToProgress,
  addHomeAddressToProgress,
  addVehicleDetails,
  addDriverToProgress,
  addAutoAddressToProgress,
  toggleCoApplicantExists,
  addPrimaryDriverToProgress,
} from "./actions"
import { Page } from "./utils/enum"
import BasicInfo from "./pages/home/basic-info"
import CoApplicant from "./pages/home/co-applicant"
import ErrorPage from "./pages/error-page"
import QuotesDisplay from "./pages/home/quotes-display"
import AutoQuotesDisplay from "./pages/auto/auto-quotes-display"
import PropertyPage from "./pages/home/property-page"
import FetchingQuotes from "./pages/fetching-quotes"
import PrefillPage from "./pages/home/prefill-page"
import PrimaryDriver from "./pages/auto/primary-driver"
import EzLynxPrefillError from "./pages/home/ezlynx-prefill-error"
import AddDriver from "./pages/auto/add-driver"
import AddVehicle from "./pages/auto/add-vehicle"
import AddGaragingAddress from "./pages/auto/add-garaging-address"
import SecondaryDriver from "./pages/auto/secondary-driver"
import DuplicateContactPage from "./pages/home/duplicate-contact-page"

import {
  postAndNavigate,
  postApplicant,
  postApplication,
  postAutoQuote,
  postCoApplicant,
  postConsent,
  postGaragingAddress,
  postPrimaryDriver,
  postPrimaryDriverAsAdditionalDriver,
  postProperty,
  postReferral,
  postVehicle,
} from "./utils/api"
import Layout from "./layout/layout"
import VehicleYmm from "./pages/auto/vehicle-ymm"
import VehicleDetailsPage from "./pages/auto/vehicle-details"
import AddCoApplicant from "./pages/home/add-co-applicant"
import { emitClientEventEmptyIdentify } from "./utils/events"
import { useEffect } from "react"
import ReferralPage from "./pages/referral/referral-page"
import ReferralSuccess from "./pages/referral/referral-success"
import AdditionalInfo from "./pages/home/additional-info"

export default function App() {
  initRum({
    active: window.ENV.rumActive,
    serviceName: window.ENV.rumServiceName,
    environment: window.ENV.rumEnvironment,
    serverUrl: window.ENV.rumServerUrl,
  })

  useEffect(() => {
    emitClientEventEmptyIdentify(
      window.ENV.rudderStackUrl,
      window.ENV.rudderStackWriteKey
    )
  }, [])

  const promptQuestions = store.useState(s => s.promptQuestions)

  const page = store.useState(s => s.currentPage)
  const determinePage = (page: Page) => {
    switch (page) {
      case Page.applicant:
        return (
          <BasicInfo
            onNext={async () => {
              addApplicantToProgress()
              await postAndNavigate([postApplicant], Page.addCoApplicant)
            }}
          />
        )
      case Page.addCoApplicant:
        return (
          <AddCoApplicant
            onYes={() => {
              toggleCoApplicantExists(true)
              navigatePage(Page.coApplicant)
            }}
            onNo={() => {
              toggleCoApplicantExists(false)
              navigatePage(Page.propertyPage)
            }}
          />
        )
      case Page.coApplicant:
        return (
          <CoApplicant
            onNext={async () => {
              addCoApplicantToProgress()
              await postAndNavigate([postCoApplicant], Page.propertyPage)
            }}
            onBack={() => navigatePage(Page.addCoApplicant)}
          />
        )
      case Page.propertyPage:
        return (
          <PropertyPage
            onNext={async () => {
              addHomeAddressToProgress()
              navigatePage(Page.fetchingQuotes)
              await postAndNavigate(
                [postProperty, postApplication],
                Page.additionalInfo
              )
            }}
          />
        )
      case Page.fetchingQuotes:
        return <FetchingQuotes />
      case Page.additionalInfo: {
        const { skipAdditionalInfo } = store.getRawState()
        const needToAnswerPromptQuestions =
          promptQuestions.length > 0 && !skipAdditionalInfo
        if (
          needToAnswerPromptQuestions &&
          window.ENV.omsiteShowAdditionalInfo
        ) {
          return (
            <AdditionalInfo
              onSkip={() => {
                navigatePage(Page.quotesDisplay)
              }}
              onNext={async () => {
                navigatePage(Page.fetchingQuotes)
                await postAndNavigate([postApplication], Page.quotesDisplay)
              }}
            />
          )
        } else {
          navigatePage(Page.quotesDisplay)
          break
        }
      }
      case Page.quotesDisplay:
        return <QuotesDisplay />
      case Page.autoQuotesDisplay:
        return <AutoQuotesDisplay />
      case Page.prefill:
        return (
          <PrefillPage
            onNext={async () => {
              navigatePage(Page.fetchingQuotes)
              await postAndNavigate(
                [postConsent, postApplication],
                Page.additionalInfo
              )
            }}
          />
        )
      case Page.primaryDriver:
        return (
          <PrimaryDriver
            onNext={async () => {
              addPrimaryDriverToProgress()
              await postAndNavigate([postPrimaryDriver], Page.addDriver)
              await postPrimaryDriverAsAdditionalDriver()
            }}
          />
        )
      case Page.addDriver:
        return (
          <AddDriver
            onYes={() => {
              addBlankAdditionalDriver()
              navigatePage(Page.additionalDrivers)
            }}
            onNo={() => navigatePage(Page.garagingAddress)}
          />
        )

      case Page.additionalDrivers:
        return (
          <SecondaryDriver
            additionalDriverIndex={
              store.getRawState().autoApplication.additionalDrivers.length - 1
            }
            onAdd={() => {
              addDriverToProgress()
              if (
                store.getRawState().autoApplication.additionalDrivers.length >=
                MAX_TOTAL_DRIVERS
              ) {
                navigatePage(Page.garagingAddress)
              } else {
                navigatePage(Page.addDriver)
              }
            }}
            onBack={() => {
              removeLastAdditionalDriver()
              navigatePage(Page.addDriver)
            }}
          />
        )
      case Page.garagingAddress:
        return (
          <AddGaragingAddress
            onNext={async () => {
              addAutoAddressToProgress()
              await postAndNavigate([postGaragingAddress], Page.addVehicle)
            }}
          />
        )
      case Page.addVehicle:
        return (
          <VehicleYmm
            onNext={(ymm: Ymm) => {
              addVehicle(ymm)
              navigatePage(Page.vehicleDetails)
            }}
            onBack={() => {
              navigatePage(Page.addAdditionalVehicle)
            }}
            allowBack={store.getRawState().autoApplication.vehicles.length > 0}
          />
        )
      case Page.addAdditionalVehicle:
        return (
          <AddVehicle
            onYes={() => {
              navigatePage(Page.addVehicle)
            }}
            onNo={async () => {
              navigatePage(Page.fetchingQuotes)
              await postAndNavigate([postAutoQuote], Page.autoQuotesDisplay)
            }}
          />
        )
      case Page.vehicleDetails:
        return (
          <VehicleDetailsPage
            onNext={(vehicleDetails: VehicleDetails) => {
              addVehicleDetails(vehicleDetails)
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              store.getRawState().autoApplication.vehicles.length < 6
                ? (async () => {
                    await postAndNavigate(
                      [postVehicle],
                      Page.addAdditionalVehicle
                    )
                  })()
                : (async () => {
                    await postAndNavigate([postVehicle], Page.fetchingQuotes)
                    await postAndNavigate(
                      [postAutoQuote],
                      Page.autoQuotesDisplay
                    )
                  })()
            }}
            allowBack={false}
          />
        )
      case Page.ezLynxPrefillError:
        return <EzLynxPrefillError />
      case Page.referral:
        return (
          <ReferralPage
            onNext={async () => {
              await postAndNavigate([postReferral], Page.referralSuccess)
            }}
          />
        )
      case Page.referralSuccess:
        return <ReferralSuccess />
      case Page.error:
      default:
        return <ErrorPage />
    }
  }
  return (
    <Routes>
      <Route path="/" element={<Layout>{determinePage(page)}</Layout>} />
      <Route
        path="/agents/:agentName"
        element={<Layout>{determinePage(page)}</Layout>}
      />
      <Route path="/home" element={<Layout>{determinePage(page)}</Layout>} />
      <Route
        path="/home/agents/:agentName"
        element={<Layout>{determinePage(page)}</Layout>}
      />
      <Route
        path="/auto"
        element={
          window.ENV.ffAutoFlowEnabled ? (
            <Layout>{determinePage(page)}</Layout>
          ) : (
            <div>Auto flow coming soon!</div>
          )
        }
      />
      <Route
        path="/auto/agents/:agentName"
        element={
          window.ENV.ffAutoFlowEnabled ? (
            <Layout>{determinePage(page)}</Layout>
          ) : (
            <div>Auto flow coming soon!</div>
          )
        }
      />
      <Route
        path="/duplicate-contact"
        element={
          <Layout>
            <DuplicateContactPage />
          </Layout>
        }
      />
      <Route
        path="/referrals/agents/:agentName"
        element={<Layout>{determinePage(page)}</Layout>}
      />
      <Route path="*" element={<div>Page Not Found</div>} />
    </Routes>
  )
}
