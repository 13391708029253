import { ProgressWidget } from "../components/progress-widget"
import PhoneAnchorTag from "../utils/phone-anchor-tag"
import { store } from "../store"

export interface Props {
  children: React.ReactNode
}

export default function ContentArea({ children }: Props) {
  const agentDisplay = store.useState(s => s.agentDisplay)
  const agentInfo = store.useState(s => s.agentInfo)
  const { isLoading } = agentInfo
  const { header, content, profilePhoto } = agentDisplay
  return (
    <main className="grins-lg:w-[900px] grins-md:mx-9 grins-md:mb-9 grins-md:flex">
      <div className="bg-background-ivory text-black px-6 grins-md:rounded-l-xl grins-md:pt-9 w-60 grins-lg:w-[300px] grins-md:pb-6 grins-md:flex flex-col justify-between border-solid grins-md:border grins-md:border-black/10">
        <ProgressWidget />
        <div className="flex flex-row">
          {isLoading ? (
            <>
              <div className="hidden bg-gray-200 w-20 h-20 mr-3 grins-lg:block rounded-full" />
              <div className="hidden flex-col mt-4 lg:mt-0 grins-md:flex space-y-2.5">
                <h2 className="bg-gray-200 w-32 h-5 rounded-sm" />
                <h2 className="bg-gray-200 w-32 h-5 rounded-sm" />
                <h2 className="bg-gray-200 w-32 h-5 rounded-sm" />
              </div>
            </>
          ) : (
            <>
              <img
                className="w-20 h-20 mr-3 hidden grins-lg:block self-center rounded-full"
                srcSet={profilePhoto}
                aria-hidden
              />
              <div className="hidden text-gr-text flex-col mt-4 lg:mt-0 grins-md:flex">
                <h2 className="text-lg lg:block font-bold">{header}</h2>
                <div className="text-xs w-32">{content}</div>
                <div className="flex align-center gap-1">
                  <img
                    className="w-5"
                    src="/public/phone-icon.svg"
                    aria-hidden
                  />
                  <PhoneAnchorTag className="whitespace-nowrap" />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="px-10 py-7 max-w-[600px] grins-lg:w-[600px] grins-md:min-h-[688px] grins-lg:min-h-[800px] grins-md:bg-gradient-to-tr grins-md:from-gr-blueprint+50 grins-md:to-gr-blueprint grins-md:rounded-r-xl grins-md:border-solid grins-md:border grins-md:border-black/10">
        {children}
      </div>
    </main>
  )
}
