import cx from "classnames"
import React from "react"

interface Props extends React.HTMLAttributes<HTMLHeadElement> {
  children: React.ReactNode
  testId?: string
}

export default function PageHeader({
  children,
  className,
  testId,
  ...rest
}: Props) {
  return (
    <h2
      data-testid={testId}
      className={cx(
        "text-3xl grins-md:text-5xl text-gr-header-font-color font-black",
        className
      )}
      {...rest}
    >
      {children}
    </h2>
  )
}
