import { OmsiteGender } from "grid-transform"
import { Select } from "grins-ui"

const genderOptionsArray: OmsiteGender[] = [
  OmsiteGender.Male,
  OmsiteGender.Female,
  OmsiteGender.RatherNotSay,
]

const findSelectedGenderOptionIndex = (status: OmsiteGender | null) => {
  if (status === null) return null
  const index = genderOptionsArray.indexOf(status)
  if (index === -1) return null
  return index
}

interface Props {
  className?: string
  label?: string
  value: OmsiteGender | null
  onChange: (newValue: OmsiteGender | null) => void
}

export function GenderSelect({
  className,
  label = "Gender",
  value,
  onChange,
}: Props) {
  const handleMartialStatusChange = (newIndex: number | null) => {
    if (newIndex !== null) {
      const newStatus = genderOptionsArray[newIndex]
      onChange(newStatus)
    } else {
      onChange(null)
    }
  }

  return (
    <Select
      testId="gender-select"
      className={className}
      label={label}
      options={genderOptionsArray as string[]}
      selectedOption={findSelectedGenderOptionIndex(value)}
      onSelectOption={handleMartialStatusChange}
    />
  )
}
