"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapQuoteRequestToEzLynxSubmitHomeQuote = exports.calculateEzLynxYearsAtAddress = exports.determineEzLynxMaritalStatus = exports.convertPhoneNumberForEzLynx = exports.determineEzLynxApplicantRelation = void 0;
const non_normal_types_1 = require("../../non-normal-types");
const grins_utils_1 = require("grins-utils");
function determineEzLynxApplicantRelation(value) {
    switch (value) {
        case "Spouse":
            return non_normal_types_1.EzLynxApplicantRelation.spouse;
        case "Domestic Partner":
            return non_normal_types_1.EzLynxApplicantRelation.domesticPartner;
        default:
            return non_normal_types_1.EzLynxApplicantRelation.other;
    }
}
exports.determineEzLynxApplicantRelation = determineEzLynxApplicantRelation;
function convertPhoneNumberForEzLynx(phoneNumber) {
    const parsedPhoneNumber = phoneNumber.replace(/-/g, "");
    if (parsedPhoneNumber.length !== 10) {
        throw new Error(`Error converting phone number to EZLynx format. Phone number must include exactly 10 digits, ay combination of dashes and no other characters: ${phoneNumber}`);
    }
    const newPhoneNumber = parsedPhoneNumber.slice(0, 3) +
        "-" +
        parsedPhoneNumber.slice(3, 6) +
        "-" +
        parsedPhoneNumber.slice(6, 10);
    return newPhoneNumber;
}
exports.convertPhoneNumberForEzLynx = convertPhoneNumberForEzLynx;
function determineEzLynxMaritalStatus(value) {
    switch (value) {
        case "Single":
            return non_normal_types_1.EzLynxMaritalStatus.single;
        case "Married":
            return non_normal_types_1.EzLynxMaritalStatus.married;
        case "Domestic Partner":
            return non_normal_types_1.EzLynxMaritalStatus.domesticPartner;
        case "Widowed":
            return non_normal_types_1.EzLynxMaritalStatus.widowed;
        case "Separated":
            return non_normal_types_1.EzLynxMaritalStatus.separated;
        case "Divorced":
            return non_normal_types_1.EzLynxMaritalStatus.divorced;
        default:
            return non_normal_types_1.EzLynxMaritalStatus.single;
    }
}
exports.determineEzLynxMaritalStatus = determineEzLynxMaritalStatus;
function calculateEzLynxYearsAtAddress(purchaseDate) {
    const calculatedYears = (0, grins_utils_1.calculateYearsAtAddress)(purchaseDate);
    let yearsAtAddress = non_normal_types_1.EzLynxYearsAtAddress.sixMonthsOrLess;
    if (calculatedYears === 0 || calculatedYears === 1 || calculatedYears === 2) {
        /* GRINS-TODO: change back to this when previous-address is implemented for yrsAtAddr < 3
          yearsAtAddress = EzLynxYearsAtAddress.sixToTwelveMonths
        */
        yearsAtAddress = non_normal_types_1.EzLynxYearsAtAddressNumsJsMap.get("3");
    }
    else if (calculatedYears >= 3 && calculatedYears <= 10) {
        /* GRINS-TODO: change back to this when previous-address is implemented for yrsAtAddr < 3
          else if (calculatedYears >= 2 && calculatedYears <= 10) {
        */
        yearsAtAddress = non_normal_types_1.EzLynxYearsAtAddressNumsJsMap.get(calculatedYears.toString());
    }
    else if (calculatedYears > 10) {
        yearsAtAddress = non_normal_types_1.EzLynxYearsAtAddress.moreThanTenYears;
    }
    return yearsAtAddress;
}
exports.calculateEzLynxYearsAtAddress = calculateEzLynxYearsAtAddress;
function mapQuoteRequestToEzLynxSubmitHomeQuote(data) {
    const dataReadyForGrinsTransform = {
        ezHomeTag: {
            applicant: {
                applicantType: "Applicant",
                personalInfo: {
                    name: {
                        firstName: data.applicant.first_name,
                        lastName: data.applicant.last_name,
                    },
                    dob: data.applicant.date_of_birth || "",
                    maritalStatus: determineEzLynxMaritalStatus(data.applicant.marital_status),
                    relation: non_normal_types_1.EzLynxApplicantRelation.insured,
                },
                address: {
                    addressCode: "StreetAddress",
                    city: data.insured_property.city,
                    zip5: data.insured_property.zip,
                    stateCode: data.insured_property.state,
                    validation: "Valid",
                    yearsAtAddress: calculateEzLynxYearsAtAddress(data.insured_property.purchase_date),
                    addr1: {
                        streetNum: data.insured_property.street_number,
                        streetName: data.insured_property.street_name,
                        unitNumber: data.insured_property.apt_suite_number || "",
                    },
                    phone: {
                        phoneNumber: convertPhoneNumberForEzLynx(data.applicant.phone_number),
                        phoneType: "Home",
                    },
                    email: data.applicant.email,
                },
            },
            ...(data.co_applicant && {
                coApplicant: {
                    applicantType: "CoApplicant",
                    personalInfo: {
                        name: {
                            firstName: data.co_applicant.first_name,
                            lastName: data.co_applicant.last_name,
                        },
                        dob: data.co_applicant.date_of_birth || "",
                        maritalStatus: determineEzLynxMaritalStatus(data.co_applicant.marital_status
                            ? data.co_applicant.marital_status
                            : data.applicant.marital_status),
                        relation: determineEzLynxApplicantRelation(data.co_applicant.relation_to_applicant),
                    },
                    address: {
                        addressCode: "StreetAddress",
                        city: data.insured_property.city,
                        zip5: data.insured_property.zip,
                        stateCode: data.insured_property.state,
                        validation: "Valid",
                        yearsAtAddress: calculateEzLynxYearsAtAddress(data.insured_property.purchase_date),
                        addr1: {
                            streetNum: data.insured_property.street_number,
                            streetName: data.insured_property.street_name,
                            unitNumber: data.insured_property.apt_suite_number || "",
                        },
                        phone: {
                            phoneNumber: convertPhoneNumberForEzLynx(data.co_applicant.phone_number
                                ? data.co_applicant.phone_number
                                : data.applicant.phone_number),
                            phoneType: "Home",
                        },
                    },
                },
            }),
            policyInfo: {
                policyType: non_normal_types_1.EzLynxHomePolicyForm.ho3,
            },
            generalInfo: {
                ratingStateCode: data.insured_property.state,
            },
        },
    };
    return dataReadyForGrinsTransform;
}
exports.mapQuoteRequestToEzLynxSubmitHomeQuote = mapQuoteRequestToEzLynxSubmitHomeQuote;
