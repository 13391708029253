"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoanPurposeType = exports.ResidenceType = exports.LienPriorityType = exports.LoanContactType = exports.CustomFieldLoanSourceEnum = exports.CustomFieldName = exports.HomePropertyTypeName = exports.OneLoanLanguageEnum = void 0;
var OneLoanLanguageEnum;
(function (OneLoanLanguageEnum) {
    OneLoanLanguageEnum["English"] = "EnglishIndicator";
    OneLoanLanguageEnum["Chinese"] = "ChineseIndicator";
    OneLoanLanguageEnum["Korean"] = "KoreanIndicator";
    OneLoanLanguageEnum["Spanish"] = "SpanishIndicator";
    OneLoanLanguageEnum["Tagalog"] = "TagalogIndicator";
    OneLoanLanguageEnum["Vietnamese"] = "VietnameseIndicator";
})(OneLoanLanguageEnum || (exports.OneLoanLanguageEnum = OneLoanLanguageEnum = {}));
var HomePropertyTypeName;
(function (HomePropertyTypeName) {
    HomePropertyTypeName["attached"] = "Attached";
    HomePropertyTypeName["condominium"] = "Condominium";
    HomePropertyTypeName["detached"] = "Detached";
    HomePropertyTypeName["highRiseCondominium"] = "HighRiseCondominium";
    HomePropertyTypeName["pUD"] = "PUD";
})(HomePropertyTypeName || (exports.HomePropertyTypeName = HomePropertyTypeName = {}));
var CustomFieldName;
(function (CustomFieldName) {
    CustomFieldName["cxLoCode1"] = "CX.LOCODE.1";
    CustomFieldName["cxEId"] = "CX.EID";
    CustomFieldName["cxFTEligibility"] = "CX.FT.ELIGIBILITY";
    CustomFieldName["cxFPEligible"] = "CX.FASTPASS.ELIGIBLE";
    CustomFieldName["cxPaidLoCode3"] = "CX.PAIDLOCODE.3";
    CustomFieldName["cxAdverseActionType"] = "CX.ADVERSEACTIONTYPE";
})(CustomFieldName || (exports.CustomFieldName = CustomFieldName = {}));
var CustomFieldLoanSourceEnum;
(function (CustomFieldLoanSourceEnum) {
    CustomFieldLoanSourceEnum["GRI"] = "1";
    CustomFieldLoanSourceEnum["GRA"] = "2";
    CustomFieldLoanSourceEnum["PRATE"] = "3";
    CustomFieldLoanSourceEnum["STRN"] = "4";
    CustomFieldLoanSourceEnum["OWNG"] = "5";
    CustomFieldLoanSourceEnum["GATELESSINC"] = "6";
    CustomFieldLoanSourceEnum["SOFI"] = "7";
    CustomFieldLoanSourceEnum["OP"] = "8";
    CustomFieldLoanSourceEnum["GRINS"] = "9";
    CustomFieldLoanSourceEnum["GRRAV"] = "10";
    CustomFieldLoanSourceEnum["TDL"] = "11";
    CustomFieldLoanSourceEnum["BKCO"] = "12";
    CustomFieldLoanSourceEnum["CER"] = "13";
    CustomFieldLoanSourceEnum["CITYWIDE"] = "14";
    CustomFieldLoanSourceEnum["CHL"] = "15";
    CustomFieldLoanSourceEnum["GIB"] = "16";
    CustomFieldLoanSourceEnum["HMA"] = "17";
    CustomFieldLoanSourceEnum["KBHS"] = "18";
    CustomFieldLoanSourceEnum["PRE"] = "19";
    CustomFieldLoanSourceEnum["RESU"] = "20";
    CustomFieldLoanSourceEnum["SHOLD"] = "21";
    CustomFieldLoanSourceEnum["FPF"] = "22";
    CustomFieldLoanSourceEnum["SVENTU"] = "23";
    CustomFieldLoanSourceEnum["GP"] = "24";
    CustomFieldLoanSourceEnum["PMA"] = "25";
    CustomFieldLoanSourceEnum["WL"] = "26";
    CustomFieldLoanSourceEnum["ATG"] = "27";
    CustomFieldLoanSourceEnum["CEHL"] = "28";
})(CustomFieldLoanSourceEnum || (exports.CustomFieldLoanSourceEnum = CustomFieldLoanSourceEnum = {}));
var LoanContactType;
(function (LoanContactType) {
    LoanContactType["loanOfficer"] = "LOAN_OFFICER";
})(LoanContactType || (exports.LoanContactType = LoanContactType = {}));
var LienPriorityType;
(function (LienPriorityType) {
    LienPriorityType["firstLien"] = "FirstLien";
    LienPriorityType["secondLien"] = "SecondLien";
    LienPriorityType["other"] = "Other";
})(LienPriorityType || (exports.LienPriorityType = LienPriorityType = {}));
var ResidenceType;
(function (ResidenceType) {
    ResidenceType["current"] = "Current";
    ResidenceType["prior"] = "Prior";
})(ResidenceType || (exports.ResidenceType = ResidenceType = {}));
var LoanPurposeType;
(function (LoanPurposeType) {
    LoanPurposeType["constructionToPermanent"] = "ConstructionToPermanent";
    LoanPurposeType["noCashOutRefinance"] = "NoCash-Out Refinance";
    LoanPurposeType["purchase"] = "Purchase";
    LoanPurposeType["constructionOnly"] = "ConstructionOnly";
    LoanPurposeType["cashOutRefinance"] = "Cash-Out Refinance";
    LoanPurposeType["refinance"] = "Refinance";
    LoanPurposeType["other"] = "Other";
})(LoanPurposeType || (exports.LoanPurposeType = LoanPurposeType = {}));
