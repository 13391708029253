import { OmsiteMaritalStatus } from "grid-transform"
import { Select } from "grins-ui"

const maritalStatusOptionsArray: OmsiteMaritalStatus[] = [
  OmsiteMaritalStatus.Single,
  OmsiteMaritalStatus.Married,
  OmsiteMaritalStatus.Divorced,
  OmsiteMaritalStatus.DomesticPartner,
  OmsiteMaritalStatus.Widowed,
  OmsiteMaritalStatus.Separated,
]

const findSelectedMaritalStatusIndex = (status: OmsiteMaritalStatus | null) => {
  if (status === null) return null
  const index = maritalStatusOptionsArray.indexOf(status)
  if (index === -1) return null
  return index
}

interface Props {
  className?: string
  label?: string
  value: OmsiteMaritalStatus | null
  onChange: (newValue: OmsiteMaritalStatus | null) => void
}

export function MaritialStatusSelect({
  className,
  label = "Marital status",
  value,
  onChange,
}: Props) {
  const handleMartialStatusChange = (newIndex: number | null) => {
    if (newIndex !== null) {
      const newStatus = maritalStatusOptionsArray[newIndex]
      onChange(newStatus)
    } else {
      onChange(null)
    }
  }

  return (
    <Select
      testId="marital-status-select"
      className={className}
      label={label}
      options={maritalStatusOptionsArray as string[]}
      selectedOption={findSelectedMaritalStatusIndex(value)}
      onSelectOption={handleMartialStatusChange}
    />
  )
}
