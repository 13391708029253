"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapHomeInfoToEzlynxHomeTag = void 0;
const non_normal_types_1 = require("../../non-normal-types");
/**
 * @description Takes in a home application object from the /quotes/start client and returns a JSON object that can be used to prefill an ezlynx quote request.
 * @param {QuoteServiceHomeApplication} homeInfo - Home application object from the /quotes/start client.
 * @returns {EzHomeTagDataAsJson} JSON object that can be used to prefill an ezlynx quote request.
 */
function mapHomeInfoToEzlynxHomeTag(homeInfo) {
    const ezlynxTagData = {
        ezHomeTag: {
            applicant: privateHelpers._generateApplicantTag(homeInfo),
            coApplicant: privateHelpers._generateCoApplicantTag(homeInfo),
            policyInfo: privateHelpers._generatePolicyInfo(homeInfo),
            priorPolicyInfo: privateHelpers._generatePriorPolicyInfo(homeInfo),
            generalInfo: privateHelpers._generateGeneralInfo(homeInfo),
        },
    };
    return ezlynxTagData;
}
exports.mapHomeInfoToEzlynxHomeTag = mapHomeInfoToEzlynxHomeTag;
/**
 * @private
 * @description Generates an object that contains all of the EzHomeTagDataAsJson general info populated with data from the home application object.
 * @param {QuoteServiceHomeApplication} homeInfo - Home application object from the /quotes/start client.
 * @returns {EzHomeTagDataAsJson["ezHomeTag"]["applicant"]} Object that contains all of the EzHomeTagDataAsJson general info populated with data from the home application object.
 */
function _generateApplicantTag(homeInfo) {
    /**
     * The current address of the applicant.
     */
    const currentAddressData = homeInfo.insured_property;
    const currentAddress = {
        addressCode: "StreetAddress",
        city: currentAddressData?.city,
        stateCode: currentAddressData?.state,
        zip5: currentAddressData?.zip,
        validation: "Valid",
        phone: {
            phoneNumber: homeInfo.applicant.phone_number,
            phoneType: "Home",
        },
        addr1: {
            streetName: currentAddressData?.street_name,
            streetNum: currentAddressData?.street_number,
            ...(currentAddressData?.apt_suite_number && {
                unitNumber: currentAddressData?.apt_suite_number,
            }),
        },
        email: homeInfo.applicant.email,
    };
    /**
     * The prior address of the applicant.
     */
    const priorAddressData = homeInfo.applicant.effective_prior_address;
    const priorAddress = priorAddressData
        ? {
            addressCode: "PreviousAddress",
            city: priorAddressData.city,
            stateCode: priorAddressData.state,
            zip5: priorAddressData.zip,
            validation: "Valid",
            addr1: {
                streetName: priorAddressData.street_name,
                streetNum: priorAddressData.street_number,
                ...(priorAddressData.apt_suite_number && {
                    unitNumber: priorAddressData.apt_suite_number,
                }),
            },
        }
        : null;
    const applicantTag = {
        applicantType: "Applicant",
        addresses: [currentAddress],
        personalInfo: {
            dob: homeInfo.applicant.date_of_birth || "",
            name: {
                firstName: homeInfo.applicant.first_name,
                lastName: homeInfo.applicant.last_name,
            },
            ...(homeInfo.applicant.marital_status && {
                maritalStatus: homeInfo.applicant.marital_status,
            }),
        },
    };
    if (priorAddress && applicantTag.addresses) {
        applicantTag.addresses.push(priorAddress);
    }
    return applicantTag;
}
/**
 * @private
 * @description Generates an object that contains all of the EzHomeTagDataAsJson coApplicant info populated with data from the home application object.
 * @param {QuoteServiceHomeApplication} homeInfo - Home application object from the /quotes/start client.
 * @returns {EzHomeTagDataAsJson["ezHomeTag"]["coApplicant"]} Object that contains all of the EzHomeTagDataAsJson coApplicant info populated with data from the home application object.
 */
function _generateCoApplicantTag(homeInfo) {
    if (!homeInfo.co_applicant) {
        return undefined;
    }
    const coApplicantData = homeInfo.co_applicant;
    const coApplicantTag = {
        applicantType: "CoApplicant",
        personalInfo: {
            dob: coApplicantData.date_of_birth,
            name: {
                firstName: coApplicantData.first_name,
                lastName: coApplicantData.last_name,
            },
            ...(coApplicantData.relation_to_applicant && {
                relation: coApplicantData.relation_to_applicant,
            }),
        },
    };
    return coApplicantTag;
}
/**
 * @private
 * @description Generates an object that contains all of the EzHomeTagDataAsJson policy info populated with data from the home application object.
 * @param {QuoteServiceHomeApplication} homeInfo - Home application object from the /quotes/start client.
 * @returns {EzHomeTagDataAsJson["ezHomeTag"]["policyInfo"]} Object that contains all of the EzHomeTagDataAsJson policy info populated with data from the home application object.
 */
function _generatePolicyInfo(homeInfo) {
    return {
        policyType: non_normal_types_1.EzLynxHomePolicyForm.ho3, // Will always be HO3 for now
        effectiveDate: homeInfo.insured_property.purchase_date,
    };
}
/**
 * @private
 * @description Generates an object that contains all of the EzHomeTagDataAsJson prior policy info populated with data from the home application object.
 * @param {QuoteServiceHomeApplication} homeInfo - Home application object from the /quotes/start client.
 * @returns {EzHomeTagDataAsJson["ezHomeTag"]["priorPolicyInfo"]} Object that contains all of the EzHomeTagDataAsJson prior policy info populated with data from the home application object.
 */
function _generatePriorPolicyInfo(homeInfo) {
    return {
        expirationDate: homeInfo.insured_property.purchase_date,
    };
}
/**
 * @private
 * @description Generates an object that contains all of the EzHomeTagDataAsJson general info populated with data from the home application object.
 * @param {QuoteServiceHomeApplication} homeInfo - Home application object from the /quotes/start client.
 * @returns {EzHomeTagDataAsJson["ezHomeTag"]["generalInfo"]} Object that contains all of the EzHomeTagDataAsJson general info populated with data from the home application object.
 */
function _generateGeneralInfo(homeInfo) {
    return {
        ratingStateCode: homeInfo.insured_property.state,
    };
}
const privateHelpers = {
    _generateApplicantTag,
    _generateCoApplicantTag,
    _generatePolicyInfo,
    _generatePriorPolicyInfo,
    _generateGeneralInfo,
};
exports.default = privateHelpers;
