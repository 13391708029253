import { store } from "../store"
export default function PhoneAnchorTag({
  className,
  underlined,
}: {
  className?: string
  underlined?: boolean
}) {
  const agentDisplay = store.useState(s => s.agentDisplay)
  const { phone } = agentDisplay
  const unformattedPhone = phone?.replace(/\D/g, "")
  return (
    <a className={className} href={`tel:1${unformattedPhone}`}>
      {underlined ? <u>{phone}</u> : phone}
    </a>
  )
}
