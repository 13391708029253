"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.prepareValidationErrorObject = void 0;
function prepareValidationErrorObject(request, errors) {
    return {
        message: "Bad Request Body.",
        url: request.url,
        method: request.method,
        query: request.query,
        body: request.body,
        errors: errors ?? [],
    };
}
exports.prepareValidationErrorObject = prepareValidationErrorObject;
