"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NeptuneProductTypeEnum = exports.NeptuneConstructionTypeEnum = exports.NeptuneBasementConstructionEnum = exports.NeptunePropertyTypeEnum = exports.NeptuneFoundationTypeEnum = exports.NeptuneOccupancyEnum = void 0;
var NeptuneOccupancyEnum;
(function (NeptuneOccupancyEnum) {
    NeptuneOccupancyEnum["singleFamily"] = "singleFamily";
    NeptuneOccupancyEnum["multiFamily"] = "multiFamily";
    NeptuneOccupancyEnum["condo"] = "condo";
    NeptuneOccupancyEnum["manufactured"] = "manufactured";
})(NeptuneOccupancyEnum || (exports.NeptuneOccupancyEnum = NeptuneOccupancyEnum = {}));
var NeptuneFoundationTypeEnum;
(function (NeptuneFoundationTypeEnum) {
    NeptuneFoundationTypeEnum["slab"] = "slab";
    NeptuneFoundationTypeEnum["crawlspaceElevated"] = "crawlspaceElevated";
    NeptuneFoundationTypeEnum["crawlspaceSubgrade"] = "crawlspaceSubgrade";
    NeptuneFoundationTypeEnum["basement"] = "basement";
    NeptuneFoundationTypeEnum["elevatedOnPostPilesPiers"] = "elevatedOnPostPilesPiers";
    NeptuneFoundationTypeEnum["elevatedWithEnclosureOnPostPilesPiers"] = "elevatedWithEnclosureOnPostPilesPiers";
    NeptuneFoundationTypeEnum["elevatedWithEnclosure"] = "elevatedWithEnclosure";
})(NeptuneFoundationTypeEnum || (exports.NeptuneFoundationTypeEnum = NeptuneFoundationTypeEnum = {}));
var NeptunePropertyTypeEnum;
(function (NeptunePropertyTypeEnum) {
    NeptunePropertyTypeEnum["primaryHome"] = "primaryHome";
    NeptunePropertyTypeEnum["secondaryHome"] = "secondaryHome";
    NeptunePropertyTypeEnum["rentalOwner"] = "rentalOwner";
    NeptunePropertyTypeEnum["rentalTenant"] = "rentalTenant";
})(NeptunePropertyTypeEnum || (exports.NeptunePropertyTypeEnum = NeptunePropertyTypeEnum = {}));
var NeptuneBasementConstructionEnum;
(function (NeptuneBasementConstructionEnum) {
    NeptuneBasementConstructionEnum["none"] = "none";
    NeptuneBasementConstructionEnum["cinderBlock"] = "cinderBlock";
    NeptuneBasementConstructionEnum["pouredConcrete"] = "pouredConcrete";
    NeptuneBasementConstructionEnum["precastPanels"] = "precastPanels";
    NeptuneBasementConstructionEnum["concreteForms"] = "concreteForms";
    NeptuneBasementConstructionEnum["permWoodFoundation"] = "permWoodFoundation";
    NeptuneBasementConstructionEnum["unsure"] = "unsure";
})(NeptuneBasementConstructionEnum || (exports.NeptuneBasementConstructionEnum = NeptuneBasementConstructionEnum = {}));
var NeptuneConstructionTypeEnum;
(function (NeptuneConstructionTypeEnum) {
    NeptuneConstructionTypeEnum["wood"] = "wood";
    NeptuneConstructionTypeEnum["steel"] = "steel";
    NeptuneConstructionTypeEnum["masonry"] = "masonry";
    NeptuneConstructionTypeEnum["concrete"] = "concrete";
    NeptuneConstructionTypeEnum["other"] = "other";
})(NeptuneConstructionTypeEnum || (exports.NeptuneConstructionTypeEnum = NeptuneConstructionTypeEnum = {}));
var NeptuneProductTypeEnum;
(function (NeptuneProductTypeEnum) {
    NeptuneProductTypeEnum["residential"] = "residential";
    NeptuneProductTypeEnum["commercial"] = "commercial";
    NeptuneProductTypeEnum["RCBAP"] = "RCBAP";
    NeptuneProductTypeEnum["ResidentialEarthquake"] = "ResidentialEarthquake";
    NeptuneProductTypeEnum["CommercialEarthquake"] = "CommercialEarthquake";
})(NeptuneProductTypeEnum || (exports.NeptuneProductTypeEnum = NeptuneProductTypeEnum = {}));
