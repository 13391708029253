"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function mergeRefs(...refs) {
    const filteredRefs = refs.filter(Boolean);
    if (!filteredRefs.length)
        return;
    if (filteredRefs.length === 1)
        return filteredRefs[0];
    return (inst) => {
        for (const ref of filteredRefs) {
            if (typeof ref === "function") {
                ref(inst);
            }
            else if (ref) {
                // rather than deal with the type headaches of whether to take mutable
                // refs or not (useRef returns a immutable Ref), it's easier to just
                // work around the type restriction here
                ;
                ref.current = inst;
            }
        }
    };
}
exports.default = mergeRefs;
