"use strict";
// These functions are used for styling an input element and its label and help
// text. The DOM structure is meant to look like this:
//
// <div>
//   <label className={cx(labelStyles())}>
//     <div className={cx(labelTextStyles())} />
//     <input className={cx(inputStyles(), inputColors())} />
//   </label>
//   <div className={cx(helpTextStyles(isErrorText))} />
// </div>
Object.defineProperty(exports, "__esModule", { value: true });
exports.disabledEl = exports.helpTextStyles = exports.labelTextStyles = exports.labelStyles = exports.inputStyles = exports.inputColors = void 0;
function inputColors(focused, variant, disabled) {
    if (disabled) {
        return ["bg-gr-input-disabled", "border-gr-input-disabled"];
    }
    else if (variant === "normal") {
        return focused
            ? ["bg-gr-bluestone", "border-gr-input-border-focus"]
            : ["bg-gr-bluestone", "border-gr-input-border"];
    }
    else if (variant === "error") {
        return focused
            ? ["bg-gr-input-error-background", "border-gr-input-border-error-focus"]
            : ["bg-gr-input-error-background", "border-gr-input-border-error"];
    }
}
exports.inputColors = inputColors;
function inputStyles() {
    return ["px-4", "pt-5", "pb-1", "border", "rounded", "w-full", "outline-none"];
}
exports.inputStyles = inputStyles;
function labelStyles() {
    return ["block", "relative"];
}
exports.labelStyles = labelStyles;
function labelTextStyles(isOffset, disabled) {
    const normalLabelTextSyles = [
        "absolute",
        "transform",
        "transition",
        "origin-top-left",
        "translate-x-4",
        "z-10",
        isOffset ? "translate-y-1" : "translate-y-3.5",
        isOffset ? "scale-75" : "scale-100",
    ];
    return disabled
        ? normalLabelTextSyles.concat("text-gr-faded opacity-80")
        : normalLabelTextSyles;
}
exports.labelTextStyles = labelTextStyles;
function helpTextStyles(isErrorText) {
    return ["text-xs", "mt-1", isErrorText ? "text-gr-error" : null];
}
exports.helpTextStyles = helpTextStyles;
function disabledEl(disabled) {
    return disabled ? "pointer-events-none" : "";
}
exports.disabledEl = disabledEl;
