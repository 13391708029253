import { Button, RadioButton } from "grins-ui"
import PageHeader from "../../components/page-header"
import { useState } from "react"
import { usePageLoadEvent } from "../../utils/session"
import { store } from "../../store"

export interface Props {
  onYes: () => void
  onNo: () => void
}

export default function AddDriver({ onYes, onNo }: Props) {
  usePageLoadEvent({
    page_path: "/auto/addadditionaldriver",
    page_title: "Auto Add Additional Driver Question",
  })

  const [selectedRadioButton, setSelectedRadioButton] = useState<string>("")

  const numberOfAdditionalDrivers =
    store.getRawState().autoApplication.additionalDrivers.length

  return (
    <div>
      <PageHeader testId="add-driver-header-h2" className="text-white">
        {numberOfAdditionalDrivers >= 2
          ? "Would you like to add an additional driver?"
          : "Want to add any other drivers?"}
      </PageHeader>
      <div className="flex flex-col mt-[24px] gap-6 w-80 max-w-sm">
        <RadioButton
          data-testid="add-driver-yes-radio-btn"
          name="add-driver"
          label="Yes"
          value="Yes"
          checked={selectedRadioButton === "Yes"}
          onChange={() => setSelectedRadioButton("Yes")}
        />

        <RadioButton
          data-testid="add-driver-no-radio-btn"
          name="add-driver"
          label={
            numberOfAdditionalDrivers >= 1 ? "Nope, that's it" : "No, just me"
          }
          value="No"
          checked={selectedRadioButton === "No"}
          onChange={() => setSelectedRadioButton("No")}
        />
      </div>
      <div className="mt-14">
        <Button
          data-testid="next-btn"
          size="large-square"
          label="Next"
          disabled={selectedRadioButton === ""}
          onClick={() => (selectedRadioButton === "Yes" ? onYes() : onNo())}
        />
      </div>
    </div>
  )
}
