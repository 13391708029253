"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GrinsAgencyStandardCoveragesEnum = exports.LeadAndReferralSourceEnum = exports.OccupancyTypeEnum = exports.LoanPurposeTypeEnum = exports.PropertyTypeEnum = exports.FirstTimeHomeBuyerFlag = exports.GrinsGender = exports.GrinsSuffix = exports.GrinsMaritalStatusMap = exports.GrinsMaritalStatus = exports.GrinsApplicantType = void 0;
var GrinsApplicantType;
(function (GrinsApplicantType) {
    GrinsApplicantType["applicant"] = "Applicant";
    GrinsApplicantType["coApplicant"] = "CoApplicant";
})(GrinsApplicantType || (exports.GrinsApplicantType = GrinsApplicantType = {}));
var GrinsMaritalStatus;
(function (GrinsMaritalStatus) {
    GrinsMaritalStatus["single"] = "Single";
    GrinsMaritalStatus["married"] = "Married";
    GrinsMaritalStatus["domesticPartner"] = "Domestic Partner";
    GrinsMaritalStatus["widowed"] = "Widowed";
    GrinsMaritalStatus["separated"] = "Separated";
    GrinsMaritalStatus["divorced"] = "Divorced";
})(GrinsMaritalStatus || (exports.GrinsMaritalStatus = GrinsMaritalStatus = {}));
// use to avoid long switch or if-else statements by consumers
exports.GrinsMaritalStatusMap = new Map([
    ["single", GrinsMaritalStatus.single],
    ["married", GrinsMaritalStatus.married],
    ["domestic partner", GrinsMaritalStatus.domesticPartner],
    ["widowed", GrinsMaritalStatus.widowed],
    ["separated", GrinsMaritalStatus.separated],
    ["divorced", GrinsMaritalStatus.divorced],
]);
var GrinsSuffix;
(function (GrinsSuffix) {
    GrinsSuffix["junior"] = "Jr";
    GrinsSuffix["senior"] = "Sr";
    GrinsSuffix["theFirst"] = "I";
    GrinsSuffix["theSecond"] = "II";
    GrinsSuffix["theThird"] = "III";
})(GrinsSuffix || (exports.GrinsSuffix = GrinsSuffix = {}));
var GrinsGender;
(function (GrinsGender) {
    GrinsGender["female"] = "Female";
    GrinsGender["male"] = "Male";
    GrinsGender["notSpecified"] = "Not Specified";
})(GrinsGender || (exports.GrinsGender = GrinsGender = {}));
var FirstTimeHomeBuyerFlag;
(function (FirstTimeHomeBuyerFlag) {
    FirstTimeHomeBuyerFlag["yes"] = "Yes";
    FirstTimeHomeBuyerFlag["no"] = "No";
})(FirstTimeHomeBuyerFlag || (exports.FirstTimeHomeBuyerFlag = FirstTimeHomeBuyerFlag = {}));
var PropertyTypeEnum;
(function (PropertyTypeEnum) {
    PropertyTypeEnum["attached"] = "Attached";
    PropertyTypeEnum["condominium"] = "Condominium";
    PropertyTypeEnum["detached"] = "Detached";
    PropertyTypeEnum["highRiseCondominium"] = "HighRiseCondominium";
    PropertyTypeEnum["PUD"] = "PUD";
})(PropertyTypeEnum || (exports.PropertyTypeEnum = PropertyTypeEnum = {}));
var LoanPurposeTypeEnum;
(function (LoanPurposeTypeEnum) {
    LoanPurposeTypeEnum["purchase"] = "Purchase";
    LoanPurposeTypeEnum["refinance"] = "Refinance";
})(LoanPurposeTypeEnum || (exports.LoanPurposeTypeEnum = LoanPurposeTypeEnum = {}));
var OccupancyTypeEnum;
(function (OccupancyTypeEnum) {
    OccupancyTypeEnum["investor"] = "Investor";
    OccupancyTypeEnum["primary"] = "PrimaryResidence";
    OccupancyTypeEnum["second"] = "SecondHome";
})(OccupancyTypeEnum || (exports.OccupancyTypeEnum = OccupancyTypeEnum = {}));
var LeadAndReferralSourceEnum;
(function (LeadAndReferralSourceEnum) {
    LeadAndReferralSourceEnum["NPHO3DF"] = "NP HO3 - DF";
    LeadAndReferralSourceEnum["RefiHO3DF"] = "Refi HO3 - DF";
    LeadAndReferralSourceEnum["NPHO6DF"] = "NP HO6 - DF";
    LeadAndReferralSourceEnum["RefiHO6DF"] = "Refi HO6 - DF";
    LeadAndReferralSourceEnum["NPHO6"] = "NP HO6";
    LeadAndReferralSourceEnum["RefiHO6"] = "Refi HO6";
    LeadAndReferralSourceEnum["NPHO3"] = "NP HO3";
    LeadAndReferralSourceEnum["RefiHO3"] = "Refi HO3";
})(LeadAndReferralSourceEnum || (exports.LeadAndReferralSourceEnum = LeadAndReferralSourceEnum = {}));
var GrinsAgencyStandardCoveragesEnum;
(function (GrinsAgencyStandardCoveragesEnum) {
    GrinsAgencyStandardCoveragesEnum["coverageE"] = "500000";
    GrinsAgencyStandardCoveragesEnum["coverageF"] = "5000";
})(GrinsAgencyStandardCoveragesEnum || (exports.GrinsAgencyStandardCoveragesEnum = GrinsAgencyStandardCoveragesEnum = {}));
