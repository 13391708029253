import CongratsErrorPageContent from "../../components/congrats-error-page-content"
import { usePageLoadEvent } from "../../utils/session"

export default function ReferralSuccess() {
  usePageLoadEvent({
    page_path: "/referral/success",
    page_title: "Referral Submitted",
  })

  return <CongratsErrorPageContent />
}
