"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CallSummarizationMetadataDirectionEnum = exports.CallSummarizationMetadataTeamEnum = exports.CallSummarizationDatabaseDirectionEnum = exports.CallSummarizationDatabaseTeamEnum = void 0;
// Database Enum Format: `<TeamType.code>: <TeamType.description>`
var CallSummarizationDatabaseTeamEnum;
(function (CallSummarizationDatabaseTeamEnum) {
    CallSummarizationDatabaseTeamEnum["servicing"] = "Servicing";
    CallSummarizationDatabaseTeamEnum["sales"] = "Sales";
    CallSummarizationDatabaseTeamEnum["unknown"] = "Unknown";
})(CallSummarizationDatabaseTeamEnum || (exports.CallSummarizationDatabaseTeamEnum = CallSummarizationDatabaseTeamEnum = {}));
// Database Enum Format: `<DirectionType.code>: <DirectionType.description>`
var CallSummarizationDatabaseDirectionEnum;
(function (CallSummarizationDatabaseDirectionEnum) {
    CallSummarizationDatabaseDirectionEnum["outbound"] = "Outbound";
    CallSummarizationDatabaseDirectionEnum["inbound"] = "Inbound";
})(CallSummarizationDatabaseDirectionEnum || (exports.CallSummarizationDatabaseDirectionEnum = CallSummarizationDatabaseDirectionEnum = {}));
var CallSummarizationMetadataTeamEnum;
(function (CallSummarizationMetadataTeamEnum) {
    CallSummarizationMetadataTeamEnum["service"] = "serv";
    CallSummarizationMetadataTeamEnum["sales"] = "sales";
    CallSummarizationMetadataTeamEnum["unknown"] = "unknown";
})(CallSummarizationMetadataTeamEnum || (exports.CallSummarizationMetadataTeamEnum = CallSummarizationMetadataTeamEnum = {}));
var CallSummarizationMetadataDirectionEnum;
(function (CallSummarizationMetadataDirectionEnum) {
    CallSummarizationMetadataDirectionEnum["outbound"] = "OUT_BOUND";
    CallSummarizationMetadataDirectionEnum["inbound"] = "IN_BOUND";
})(CallSummarizationMetadataDirectionEnum || (exports.CallSummarizationMetadataDirectionEnum = CallSummarizationMetadataDirectionEnum = {}));
