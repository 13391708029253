import { HomeQuote } from "grid-transform"
import PageHeader from "../../components/page-header"
import QuoteSummaryBox from "../../components/quote-summary-box"
import BindableQuoteSummaryBox from "../../components/bindable-quote-summary-box"
import { store } from "../../store"
import OtherCarriers from "../../components/other-carriers"
import { usePageLoadEvent } from "../../utils/session"

export default function QuotesSummary() {
  usePageLoadEvent({
    page_path: "/quotes",
    page_title: "Quotes",
  })
  const { bindableQuotes, topQuotes, otherQuotes, application } =
    store.useState(s => ({
      bindableQuotes: s.bindableQuotes,
      topQuotes: s.topQuotes,
      otherQuotes: s.otherQuotes,
      application: s.application,
    }))

  const renderTitle = () => {
    const text = `${application.applicant.firstName}, quotes have landed! Here are the best options we found.`
    return (
      <PageHeader
        testId="quotes-summary-header-h2"
        className="text-3xl grins-md:text-4xl text-white mt-0.5 mb-6"
      >
        {text}
      </PageHeader>
    )
  }

  const renderSummaryBox = (quote: HomeQuote) => {
    return <QuoteSummaryBox key={quote.quoteId} quote={quote} />
  }

  const renderBindableSummaryBox = (quote: HomeQuote) => {
    return <BindableQuoteSummaryBox key={quote.quoteId} quote={quote} />
  }

  const renderOtherCarriers = () => {
    if (!window.ENV.ffShowAdditionalLogos) return null
    if (otherQuotes.length === 0) return null
    return <OtherCarriers />
  }

  const renderBuyNowQuotesTitle = () => {
    if (!bindableQuotes || bindableQuotes.length === 0) return null
    return (
      <div className="mt-6 mb-2.5">
        <span
          className="text-2xl text-white"
          data-testid="home-quote-buy-now-subtitle-span"
        >
          Ready to move forward...
        </span>
      </div>
    )
  }

  const renderAssistedQuotesTitle = () => {
    if (!topQuotes || topQuotes.length === 0) return null
    return (
      <div className="mt-10 mb-2.5">
        <span
          className="text-2xl text-white"
          data-testid="home-quote-assisted-subtitle-span"
        >
          {`${
            bindableQuotes.length >= 1 ? "Or, explore" : "Explore"
          } these options! Call an agent now to answer a few more questions.`}
        </span>
      </div>
    )
  }

  return (
    <>
      {renderTitle()}
      {renderBuyNowQuotesTitle()}
      {bindableQuotes && bindableQuotes.length > 0
        ? bindableQuotes.map(renderBindableSummaryBox)
        : null}
      {renderAssistedQuotesTitle()}
      {topQuotes && topQuotes.length > 0
        ? topQuotes.map(renderSummaryBox)
        : null}
      {renderOtherCarriers()}
    </>
  )
}
