"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SalesforceLeadAndReferralSource = exports.generateHashKeyForSalesforceLeadReferral = exports.leadSourceCombinations = void 0;
const kvps = [];
exports.leadSourceCombinations = new Map();
/*
    Provides consistency for generated hash keys, that are used to lookup values (for Salesforce Lead & Referral Sources)
*/
function generateHashKeyForSalesforceLeadReferral(propertyType, propertyUsageType, loanPurposeType) {
    return `propertyType:${propertyType.toLowerCase()}+propertyUsageType:${propertyUsageType.toLowerCase()}+loanPurposeType:${loanPurposeType.toLowerCase()}`;
}
exports.generateHashKeyForSalesforceLeadReferral = generateHashKeyForSalesforceLeadReferral;
var SalesforceLeadAndReferralSource;
(function (SalesforceLeadAndReferralSource) {
    SalesforceLeadAndReferralSource["npHo3"] = "NP HO3";
    SalesforceLeadAndReferralSource["refiHo3"] = "Refi HO3";
    SalesforceLeadAndReferralSource["npHo3Df"] = "NP HO3 - DF";
    SalesforceLeadAndReferralSource["refiHo3Df"] = "Refi HO3 - DF";
    SalesforceLeadAndReferralSource["npHo6Df"] = "NP HO6 - DF";
    SalesforceLeadAndReferralSource["refiHo6Df"] = "Refi HO6 - DF";
    SalesforceLeadAndReferralSource["npHo6"] = "NP HO6";
    SalesforceLeadAndReferralSource["refiHo6"] = "Refi HO6";
})(SalesforceLeadAndReferralSource || (exports.SalesforceLeadAndReferralSource = SalesforceLeadAndReferralSource = {}));
kvps.push({
    propertyType: "",
    propertyUsageType: "",
    loanPurposeType: "Purchase",
    salesforceLeadAndReferralSource: SalesforceLeadAndReferralSource.npHo3,
});
kvps.push({
    propertyType: "",
    propertyUsageType: "",
    loanPurposeType: "Refinance",
    salesforceLeadAndReferralSource: SalesforceLeadAndReferralSource.refiHo3,
});
kvps.push({
    propertyType: "Attached",
    propertyUsageType: "Investor",
    loanPurposeType: "Purchase",
    salesforceLeadAndReferralSource: SalesforceLeadAndReferralSource.npHo3Df,
});
kvps.push({
    propertyType: "Attached",
    propertyUsageType: "Investor",
    loanPurposeType: "Refinance",
    salesforceLeadAndReferralSource: SalesforceLeadAndReferralSource.refiHo3Df,
});
kvps.push({
    propertyType: "Condominium",
    propertyUsageType: "Investor",
    loanPurposeType: "Purchase",
    salesforceLeadAndReferralSource: SalesforceLeadAndReferralSource.npHo6Df,
});
kvps.push({
    propertyType: "Condominium",
    propertyUsageType: "Investor",
    loanPurposeType: "Refinance",
    salesforceLeadAndReferralSource: SalesforceLeadAndReferralSource.refiHo6Df,
});
kvps.push({
    propertyType: "Condominium",
    propertyUsageType: "PrimaryResidence",
    loanPurposeType: "Purchase",
    salesforceLeadAndReferralSource: SalesforceLeadAndReferralSource.npHo6,
});
kvps.push({
    propertyType: "Condominium",
    propertyUsageType: "PrimaryResidence",
    loanPurposeType: "Refinance",
    salesforceLeadAndReferralSource: SalesforceLeadAndReferralSource.refiHo6,
});
kvps.push({
    propertyType: "Condominium",
    propertyUsageType: "SecondHome",
    loanPurposeType: "Purchase",
    salesforceLeadAndReferralSource: SalesforceLeadAndReferralSource.npHo6Df,
});
kvps.push({
    propertyType: "Condominium",
    propertyUsageType: "SecondHome",
    loanPurposeType: "Refinance",
    salesforceLeadAndReferralSource: SalesforceLeadAndReferralSource.refiHo6Df,
});
kvps.push({
    propertyType: "Detached",
    propertyUsageType: "Investor",
    loanPurposeType: "Purchase",
    salesforceLeadAndReferralSource: SalesforceLeadAndReferralSource.npHo3Df,
});
kvps.push({
    propertyType: "Detached",
    propertyUsageType: "Investor",
    loanPurposeType: "Refinance",
    salesforceLeadAndReferralSource: SalesforceLeadAndReferralSource.refiHo3Df,
});
kvps.push({
    propertyType: "Detached",
    propertyUsageType: "SecondHome",
    loanPurposeType: "Purchase",
    salesforceLeadAndReferralSource: SalesforceLeadAndReferralSource.npHo3Df,
});
kvps.push({
    propertyType: "Detached",
    propertyUsageType: "SecondHome",
    loanPurposeType: "Refinance",
    salesforceLeadAndReferralSource: SalesforceLeadAndReferralSource.refiHo3Df,
});
kvps.push({
    propertyType: "HighRiseCondominium",
    propertyUsageType: "PrimaryResidence",
    loanPurposeType: "Purchase",
    salesforceLeadAndReferralSource: SalesforceLeadAndReferralSource.npHo6,
});
kvps.push({
    propertyType: "HighRiseCondominium",
    propertyUsageType: "PrimaryResidence",
    loanPurposeType: "Refinance",
    salesforceLeadAndReferralSource: SalesforceLeadAndReferralSource.refiHo6,
});
kvps.push({
    propertyType: "HighRiseCondominium",
    propertyUsageType: "SecondHome",
    loanPurposeType: "Purchase",
    salesforceLeadAndReferralSource: SalesforceLeadAndReferralSource.npHo6Df,
});
kvps.push({
    propertyType: "HighRiseCondominium",
    propertyUsageType: "SecondHome",
    loanPurposeType: "Refinance",
    salesforceLeadAndReferralSource: SalesforceLeadAndReferralSource.refiHo6Df,
});
kvps.push({
    propertyType: "PUD",
    propertyUsageType: "SecondHome",
    loanPurposeType: "Purchase",
    salesforceLeadAndReferralSource: SalesforceLeadAndReferralSource.npHo3Df,
});
kvps.push({
    propertyType: "PUD",
    propertyUsageType: "SecondHome",
    loanPurposeType: "Refinance",
    salesforceLeadAndReferralSource: SalesforceLeadAndReferralSource.refiHo3Df,
});
/*
    Initialize the hash table (map) for O(1) lookup of Salesforce Lead & Referral Sources
    Reqs ref: https://wiki.guaranteedrate.com/x/-iNiBg#ProperRateIntegrationRequirements-LeadandReferralSource
*/
kvps.forEach(elem => {
    exports.leadSourceCombinations.set(generateHashKeyForSalesforceLeadReferral(elem.propertyType, elem.propertyUsageType, elem.loanPurposeType), elem.salesforceLeadAndReferralSource);
});
