"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.oneLoanDataValidateAndMapToBranchHomeApplicant = void 0;
const grins_utils_1 = require("grins-utils");
const utils_1 = require("./utils");
const oneloan_to_myaccount_home_lead_1 = require("../../oneloan-to-myaccount-home-lead");
const luxon_1 = require("luxon");
function oneLoanDataValidateAndMapToBranchHomeApplicant(oneLoanData) {
    // not abstracting the below if-statements so that Typescript/lints are satisfied
    if (!oneLoanData.loan.applications ||
        oneLoanData.loan.applications.length < 1)
        throw new Error(`OneLoan data is missing application data.`);
    if (!oneLoanData.loan.applications[0].borrower)
        throw new Error(`Borrower is missing from OneLoan data.`);
    const borrower = oneLoanData.loan.applications[0].borrower;
    if (!borrower.firstName || borrower.firstName.trim() === "")
        throw new Error(`BorrowerfirstName data is missing or blank.`);
    if (!borrower.lastName || borrower.lastName.trim() === "")
        throw new Error(`BorrowerlastName data is missing or blank.`);
    if (!oneLoanData.loan.hmda)
        throw new Error(`HMDA data is missing from OneLoan data.`);
    const hmda = oneLoanData.loan.hmda;
    if (!hmda.hmdaPropertyAddress || hmda.hmdaPropertyAddress.trim() === "")
        throw new Error(`Address is missing from OneLoan data.`);
    const { streetName, streetNum, unitNumber } = (0, grins_utils_1.parseAddress)(hmda.hmdaPropertyAddress);
    if (!hmda.hmdaPropertyCity || hmda.hmdaPropertyCity.trim() === "")
        throw new Error(`City is missing from OneLoan data.`);
    if (!hmda.hmdaPropertyState || hmda.hmdaPropertyState.trim() === "")
        throw new Error(`State is missing from OneLoan data.`);
    if (!hmda.hmdaPropertyZipCode || hmda.hmdaPropertyZipCode.trim() === "")
        throw new Error(`Zip is missing from OneLoan data.`);
    if (!oneLoanData.loan.loanProductData?.loanScheduledClosingDate ||
        oneLoanData.loan.loanProductData?.loanScheduledClosingDate.trim().length <=
            0) {
        throw new Error(`Loan Scheduled Closing Date is blank or undefined`);
    }
    if (!borrower.birthDate || borrower.birthDate.trim().length <= 0) {
        throw new Error(`Borrower birth date is blank or undefined`);
    }
    const luxonDob = luxon_1.DateTime.fromJSDate(new Date(borrower.birthDate), {
        zone: "utc",
    });
    if (luxonDob.invalidReason) {
        throw new Error(`Borrower DOB error: ${luxonDob.invalidReason}`);
    }
    const branchHomeApplicant = {
        fname: borrower.firstName,
        lname: borrower.lastName,
        dob: luxonDob.toFormat("yyyy-MM-dd"),
        address: unitNumber
            ? `${unitNumber} ${streetNum} ${streetName}`
            : `${streetNum} ${streetName}`,
        city: hmda.hmdaPropertyCity,
        state: hmda.hmdaPropertyState,
        zip: hmda.hmdaPropertyZipCode,
        homeCloseDate: (0, utils_1.toBicEffectiveHomeCloseDate)(oneLoanData.loan.loanProductData?.loanScheduledClosingDate),
    };
    const effectivePriorAddress = (0, oneloan_to_myaccount_home_lead_1.getEffectivePriorAddress)(oneLoanData);
    if (effectivePriorAddress) {
        const priorAddress = {
            address: `${effectivePriorAddress.streetNumber} ${effectivePriorAddress.streetName} ${effectivePriorAddress.unitNumber}`,
            city: effectivePriorAddress.city,
            state: effectivePriorAddress.state,
            zip: effectivePriorAddress.zipCode5,
        };
        branchHomeApplicant["priorAddress"] = priorAddress;
    }
    return branchHomeApplicant;
}
exports.oneLoanDataValidateAndMapToBranchHomeApplicant = oneLoanDataValidateAndMapToBranchHomeApplicant;
