import { AutoQuote } from "grid-transform"
import PageHeader from "../../components/page-header"
import { store } from "../../store"
import OtherCarriers from "../../components/other-carriers"
import AutoQuoteSummaryBox from "../../components/auto-quote-summary-box"
import AutoBindableQuoteSummaryBox from "../../components/auto-bindable-quote-summary-box"

export default function AutoQuotesSummary() {
  const {
    autoApplication,
    bindableAutoQuotes,
    otherAutoQuotes,
    topAutoQuotes,
  } = store.useState(s => ({
    autoApplication: s.autoApplication,
    bindableAutoQuotes: s.bindableAutoQuotes,
    otherAutoQuotes: s.otherAutoQuotes,
    topAutoQuotes: s.topAutoQuotes,
  }))

  const renderTitle = () => {
    const text = `${autoApplication.primaryDriver.firstName}, quotes have landed! Here are the best options we found.`
    return (
      <PageHeader
        testId="quotes-summary-header-h2"
        className="text-3xl grins-md:text-4xl text-white mt-0.5 mb-6"
      >
        {text}
      </PageHeader>
    )
  }

  const renderOtherCarriers = () => {
    if (!window.ENV.ffShowAdditionalLogos) return null
    if (otherAutoQuotes.length === 0) return null
    return <OtherCarriers />
  }

  // Bindable Quotes
  const renderBindableQuotesSection = () => (
    <>
      {renderBindableQuotesTitle()}
      {renderBindableQuotes()}
    </>
  )

  const renderBindableQuotesTitle = () => {
    const noBindableQuotesToShow = !bindableAutoQuotes.length
    if (noBindableQuotesToShow) {
      return null
    }

    return (
      <div className="mt-6 mb-2.5">
        <span
          className="text-2xl text-white"
          data-testid="home-quote-buy-now-subtitle-span"
        >
          Ready to move forward...
        </span>
      </div>
    )
  }

  const renderBindableQuotes = () => {
    const noBindableQuotesToShow = !bindableAutoQuotes.length
    if (noBindableQuotesToShow) {
      return null
    }

    return bindableAutoQuotes.map(renderBindableSummaryBox)
  }

  const renderBindableSummaryBox = (quote: AutoQuote) => {
    return <AutoBindableQuoteSummaryBox key={quote.quoteId} quote={quote} />
  }

  // Assisted Quotes
  const renderAssistedQuotesSection = () => (
    <>
      {renderAssistedQuotesTitle()}
      {renderAssistedQuotes()}
    </>
  )

  const renderAssistedQuotesTitle = () => {
    const noTopQuotesToShow = !topAutoQuotes.length
    if (noTopQuotesToShow) {
      return null
    }

    return (
      <div className="mt-10 mb-2.5">
        <span
          className="text-2xl text-white"
          data-testid="home-quote-assisted-subtitle-span"
        >
          {`${
            bindableAutoQuotes.length >= 1 ? "Or, explore" : "Explore"
          } these options! Call an agent now to answer a few more questions.`}
        </span>
      </div>
    )
  }

  const renderAssistedQuotes = () => {
    const noTopQuotesToShow = !topAutoQuotes.length
    if (noTopQuotesToShow) {
      return null
    }

    return topAutoQuotes.map(renderSummaryBox)
  }

  const renderSummaryBox = (autoQuote: AutoQuote) => {
    return <AutoQuoteSummaryBox key={autoQuote.quoteId} autoQuote={autoQuote} />
  }

  return (
    <>
      {renderTitle()}
      {renderBindableQuotesSection()}
      {renderAssistedQuotesSection()}
      {renderOtherCarriers()}
    </>
  )
}
