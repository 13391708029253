export enum Page {
  addDriver = "AddDriver",
  additionalDrivers = "AdditionalDrivers",
  applicant = "Applicant",
  addCoApplicant = "AddCoApplicant",
  coApplicant = "CoApplicant",
  error = "Error",
  ezLynxPrefillError = "EzLynxPrefillError",
  fetchingQuotes = "FetchingQuotes",
  garagingAddress = "GaragingAddress",
  addVehicle = "AddVehicle",
  vehicleDetails = "VehicleDetails",
  addAdditionalVehicle = "AddAdditionalVehicle",
  prefill = "Prefill",
  primaryDriver = "PrimaryDriver",
  propertyPage = "PropertyPage",
  quotesDisplay = "QuotesDisplay",
  autoQuotesDisplay = "AutoQuotesDisplay",
  duplicateContact = "DuplicateContact",
  referral = "Referral",
  referralSuccess = "ReferralSuccess",
  additionalInfo = "AdditionalInfo",
}
