import PageHeader from "../../components/page-header"
import PhoneAnchorTag from "../../utils/phone-anchor-tag"

export default function DuplicateContactPage() {
  return (
    <>
      <PageHeader
        testId="duplicate-contact-page-header-h2"
        className="text-white"
      >
        Thanks for your interest!
      </PageHeader>
      <p className="text-white my-6">
        It looks like you are already working with an agent at Rate Insurance.
        They wll be reaching out to you again shortly or you can call us now at{" "}
        {<PhoneAnchorTag />}
      </p>
    </>
  )
}
