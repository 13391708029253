"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.leadServiceQuoteToSalesforce = exports.getGender = exports.leadServiceVehicleToSalesforce = exports.leadServiceDriverToSalesforce = void 0;
const luxon_1 = require("luxon");
const __1 = require("../..");
function leadServiceDriverToSalesforce(leadServiceDriver, id) {
    return {
        Date_of_Birth__c: luxon_1.DateTime.fromJSDate(new Date(leadServiceDriver.date_of_birth)).toISODate() || "",
        First_Name__c: leadServiceDriver.first_name,
        Last_Name__c: leadServiceDriver.last_name,
        Gender__c: getGender(leadServiceDriver.gender),
        Lead_and_Referral__c: id,
    };
}
exports.leadServiceDriverToSalesforce = leadServiceDriverToSalesforce;
function leadServiceVehicleToSalesforce(leadServiceVehicle, id) {
    return {
        Year__c: leadServiceVehicle.year,
        Make__c: leadServiceVehicle?.make,
        Model__c: leadServiceVehicle.model,
        Sub_Model_Trim__c: leadServiceVehicle.sub_model_trim,
        VIN__c: leadServiceVehicle.vin,
        Vehicle_Ownership__c: leadServiceVehicle.vehicle_ownership,
        Usage__c: leadServiceVehicle.vehicle_usage,
        Annual_Mileage__c: leadServiceVehicle.annual_mileage,
        Lead_and_Referral__c: id,
    };
}
exports.leadServiceVehicleToSalesforce = leadServiceVehicleToSalesforce;
function getGender(gender) {
    if (gender === __1.OmsiteGender.Female.toString())
        return "Female";
    else if (gender === __1.OmsiteGender.Male.toString())
        return "Male";
    return "Unknown";
}
exports.getGender = getGender;
function leadServiceQuoteToSalesforce(leadServiceQuotes, id) {
    const convertedValues = leadServiceQuotes.map(leadServiceQuote => {
        return {
            ...(leadServiceQuote.agent_url && {
                Agent_URL__c: leadServiceQuote.agent_url,
            }),
            Carrier_ID__c: leadServiceQuote.carrier_id,
            Carrier_Logo_URL__c: leadServiceQuote.carrier_logo,
            Carrier_Name__c: leadServiceQuote.carrier_name,
            ...(leadServiceQuote.deductible && {
                Deductible__c: leadServiceQuote.deductible,
            }),
            ...(leadServiceQuote.dwelling_coverage && {
                Dwelling_Coverage__c: leadServiceQuote.dwelling_coverage,
            }),
            External_Id__c: leadServiceQuote.external_id,
            Lead__c: id,
            ...(leadServiceQuote.loss_of_use_coverage && {
                Loss_of_Use_Coverage__c: leadServiceQuote.loss_of_use_coverage,
            }),
            ...(leadServiceQuote.medical_payments_coverage && {
                Medical_Payments_Coverage__c: leadServiceQuote.medical_payments_coverage,
            }),
            ...(leadServiceQuote.other_structures_coverage && {
                Other_Structures_Coverage__c: leadServiceQuote.other_structures_coverage,
            }),
            ...(leadServiceQuote.personal_property_coverage && {
                Personal_Property_Coverage__c: leadServiceQuote.personal_property_coverage,
            }),
            Policy_Form__c: "HO3",
            Premium__c: leadServiceQuote.cost_per_year,
            ...(leadServiceQuote.quote_id && {
                Quote_ID__c: leadServiceQuote.quote_id,
            }),
            Status__c: leadServiceQuote.status,
            Status_Text__c: leadServiceQuote.status_message,
        };
    });
    return convertedValues;
}
exports.leadServiceQuoteToSalesforce = leadServiceQuoteToSalesforce;
