import { Button, DateInput, TextInput } from "grins-ui"
import { DateTime } from "luxon"
import { store } from "../../store"
import { OmsiteGender } from "grid-transform"
import PageHeader from "../../components/page-header"
import { GenderSelect } from "../../components/gender-select"
import { usePageLoadEvent } from "../../utils/session"
import { postAdditionalDriver } from "../../utils/api"

interface PrimaryDriverProps {
  additionalDriverIndex: number
  onAdd: (additionalDriverIndex: number) => void
  onBack: (additionalDriverIndex: number) => void
}

export default function SecondaryDriver({
  additionalDriverIndex,
  onAdd,
  onBack,
}: PrimaryDriverProps) {
  usePageLoadEvent({
    page_path: "/auto/additionaldriver",
    page_title: "Auto Additional Driver Info",
  })

  const isSubmitting = store.useState(s => s.isSubmitting)
  const driverInfo = store.useState(
    s => s.autoApplication.additionalDrivers[additionalDriverIndex]
  )

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    inputKey: "firstName" | "lastName"
  ) => {
    store.update(s => {
      s.autoApplication.additionalDrivers[additionalDriverIndex][inputKey] =
        e.target.value
    })
  }

  const handleDateOfBirthChange = (value: DateTime | null) => {
    store.update(s => {
      s.autoApplication.additionalDrivers[additionalDriverIndex].dateOfBirth =
        value
    })
  }

  const handleGenderChange = (value: OmsiteGender | null) => {
    store.update(s => {
      s.autoApplication.additionalDrivers[additionalDriverIndex].gender = value
    })
  }

  const handleAdd = async () => {
    onAdd(additionalDriverIndex)
    await postAdditionalDriver()
  }
  const handleBack = () => onBack(additionalDriverIndex)

  const shouldButtonBeEnabled = () =>
    driverInfo.firstName.length > 0 &&
    driverInfo.lastName.length > 0 &&
    driverInfo.dateOfBirth !== null &&
    driverInfo.gender !== null

  return (
    <div className="w-full">
      <PageHeader testId="secondary-driver-header-h2" className="text-white">
        Great! What's their info?
      </PageHeader>
      <div className="max-w-xs">
        <TextInput
          data-testid="first-name-input"
          className="mt-7 mb-6"
          label="First name"
          value={driverInfo.firstName}
          onChange={e => handleChange(e, "firstName")}
        />
        <TextInput
          data-testid="last-name-input"
          className="my-6"
          label="Last name"
          value={driverInfo.lastName}
          onChange={e => handleChange(e, "lastName")}
        />
        <p className="text-lg font-semibold text-white my-2">Date of birth</p>
        <DateInput
          testId="date-of-birth-input"
          onDateComplete={handleDateOfBirthChange}
          valueOverride={driverInfo.dateOfBirth}
        />
        <GenderSelect
          data-testid="gender-select"
          className="my-6"
          value={driverInfo.gender}
          onChange={handleGenderChange}
        />

        <div className="flex gap-2 mt-14">
          <button
            data-testid="back-btn"
            className="flex-1 text-white text-xl text-left font-bold leading-4 cursor-pointer focus:outline-offset-2 sm:focus:outline-offset-4"
            type="button"
            disabled={isSubmitting}
            onClick={handleBack}
          >
            {"<"}&nbsp;&nbsp;&nbsp;Back
          </button>
          <div className="flex-1">
            <Button
              data-testid="add-driver-btn"
              size="large-square"
              label="Add"
              loading={isSubmitting}
              disabled={!shouldButtonBeEnabled()}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={handleAdd}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
