import {
  AgentServiceGetResponse,
  AutoQuote,
  Carrier,
  ClientAutoApplication,
  EffectivePriorAddress,
  HomeQuote,
  OmsiteApplicant,
  OmsiteCoApplicant,
  OmsiteMaritalStatus,
  OmsiteProperty,
  QuoteServicePromptAnswer,
  QuoteServicePromptQuestion,
  Vehicle,
} from "grid-transform"
import { ProgressStep, ProgressStepsProps } from "grins-ui"
import { DateTime } from "luxon"
import { registerInDevtools, Store } from "pullstate"
import { Page } from "./utils/enum"

export interface ClientApplicant
  extends Omit<
    OmsiteApplicant,
    "dateOfBirth" | "maritalStatus" | "aesf_source"
  > {
  dateOfBirth: DateTime | null
  maritalStatus: OmsiteMaritalStatus | null
  effectivePriorAddress?: EffectivePriorAddress
  aesf_source?: string
}

export interface ClientCoApplicant
  extends Omit<OmsiteCoApplicant, "dateOfBirth"> {
  exists: boolean | null
  dateOfBirth: DateTime | null
}

export interface ClientProperty extends Omit<OmsiteProperty, "purchaseDate"> {
  purchaseDate: DateTime | null
}

export interface ClientApplication {
  applicant: ClientApplicant
  coApplicant: ClientCoApplicant
  property: ClientProperty
  promptAnswers: QuoteServicePromptAnswer[]
}

export interface OmsiteAgentInfo extends AgentServiceGetResponse {
  isLoading: boolean
}

export interface OmsiteAgentDisplay {
  header: string
  content: string
  profilePhoto: string
  phone: string
}

export interface ReferralInfo {
  firstName: string
  lastName: string
  phoneNumber: string
  emailAddress?: string
}

export const MAX_TOTAL_DRIVERS = 6

export enum ClientFlow {
  home = "Home",
  auto = "Auto",
  referral = "Referral",
}

export const HomeFlowProgressSteps: ProgressStep[] = [
  { title: "My Info", subTitles: [] },
  { title: "Address", subTitles: [] },
  { title: "Additional Info", subTitles: [] },
  { title: "Quotes", subTitles: [] },
]

export const AutoFlowProgressSteps: ProgressStep[] = [
  { title: "Driver Info", subTitles: [] },
  { title: "Address", subTitles: [] },
  { title: "Vehicle Info", subTitles: [] },
  { title: "Quotes", subTitles: [] },
]

export const PrefillFlowProgressSteps: ProgressStep[] = [
  { title: "My Info", subTitles: [] },
  { title: "Additional Info", subTitles: [] },
  { title: "Quotes", subTitles: [] },
]

export const ReferralProgressSteps: ProgressStep[] = [
  { title: "My Info", subTitles: [] },
]

export interface StateType {
  isSubmitting: boolean
  grinsLeadId: string | null
  currentPage: Page
  application: ClientApplication
  currentVehicle: Vehicle // tracks vehicle object that's currently being hydrated
  autoApplication: ClientAutoApplication
  bindableQuotes: HomeQuote[]
  bindableAutoQuotes: AutoQuote[]
  topQuotes: HomeQuote[]
  topAutoQuotes: AutoQuote[]
  otherQuotes: HomeQuote[]
  otherAutoQuotes: AutoQuote[]
  progress: ProgressStepsProps
  agentInfo: OmsiteAgentInfo
  agentDisplay: OmsiteAgentDisplay
  referralInfo: ReferralInfo
  carriers: Carrier[]
  promptQuestions: QuoteServicePromptQuestion[]
  skipAdditionalInfo: boolean
  omlinkPurchasePriceAmount: number | null
}

export const initialState: StateType = {
  isSubmitting: false,
  grinsLeadId: null,
  currentPage: Page.applicant,
  application: {
    applicant: {
      firstName: "",
      lastName: "",
      dateOfBirth: null,
      phoneNumber: "",
      maritalStatus: null,
      emailAddress: "",
      assignLeadToAgent: true,
    },
    coApplicant: {
      exists: null,
      firstName: "",
      lastName: "",
      dateOfBirth: null,
      relationshipToApplicant: null,
    },
    property: {
      address: {
        streetNumber: "",
        streetName: "",
        city: "",
        state: "",
        zip: "",
        aptSuiteNumber: "",
      },
      purchaseDate: null,
    },
    promptAnswers: [],
  },
  currentVehicle: {
    ymm: {
      year: "",
      make: "",
      model: "",
      subModel: { description: "", vin: "" },
    },
    details: { ownership: "", usage: "", miles: "" },
  },
  autoApplication: {
    primaryDriver: {
      firstName: "",
      lastName: "",
      dateOfBirth: null,
      phoneNumber: "",
      maritalStatus: null,
      gender: null,
      emailAddress: "",
      assignLeadToAgent: true,
    },
    additionalDrivers: [],
    property: {
      address: {
        streetNumber: "",
        streetName: "",
        city: "",
        state: "",
        zip: "",
        aptSuiteNumber: "",
      },
      purchaseDate: null,
    },
    vehicles: [],
  },
  bindableQuotes: [],
  topQuotes: [],
  otherQuotes: [],
  topAutoQuotes: [],
  otherAutoQuotes: [],
  bindableAutoQuotes: [],
  progress: {
    steps: HomeFlowProgressSteps,
    currentStep: 0,
    flow: ClientFlow.home,
  },
  agentInfo: {
    isLoading: false,
    success: false,
  },
  agentDisplay: {
    header: "Want to talk?",
    content: "Give us a call, we are here to help.",
    profilePhoto: "/public/agent-group.png",
    phone: "(833) 533-2122",
  },
  referralInfo: {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    emailAddress: "",
  },
  carriers: [],
  promptQuestions: [],
  skipAdditionalInfo: false,
  omlinkPurchasePriceAmount: null,
}

export const store = new Store<StateType>(initialState)

registerInDevtools({ store })
