"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAuthTokenV2 = void 0;
const client_secrets_manager_1 = require("@aws-sdk/client-secrets-manager");
const credential_providers_1 = require("@aws-sdk/credential-providers");
const grins_types_1 = require("grins-types");
async function getAuthTokenV2(config, tokenType) {
    let secretsManager;
    if (process.env.USE_LOCAL_AWS_PROFILE === "true") {
        secretsManager = new client_secrets_manager_1.SecretsManagerClient({
            region: "us-east-1",
            credentials: (0, credential_providers_1.fromIni)({
                profile: "nonprod-grinsurance-DevelopersInsurance",
            }),
        });
    }
    else {
        secretsManager = new client_secrets_manager_1.SecretsManagerClient({});
    }
    let secretId;
    if (tokenType === grins_types_1.TokenTypes.okta) {
        secretId = config.oktaTokenCacheSecretName;
    }
    else if (tokenType === grins_types_1.TokenTypes.salesforce) {
        secretId = config.salesforceTokenCacheSecretName;
    }
    else if (tokenType === grins_types_1.TokenTypes.jarvis) {
        secretId = config.jarvisTokenCacheSecretName;
    }
    else {
        secretId = config.oktaTokenCacheSecretName;
    }
    const command = new client_secrets_manager_1.GetSecretValueCommand({
        SecretId: secretId,
    });
    const secretResponse = await secretsManager.send(command);
    const secretString = secretResponse.SecretString || "";
    return secretString;
}
exports.getAuthTokenV2 = getAuthTokenV2;
