"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.omsiteVehicleToLeadService = exports.omsiteAdditionalDriverToLeadService = exports.omsiteDriverToLeadService = exports.getGender = void 0;
const non_normal_types_1 = require("../../non-normal-types");
const luxon_1 = require("luxon");
const omsite_to_lead_service_1 = require("../../omsite-to-lead-service");
function getGender(gender) {
    if (gender === non_normal_types_1.OmsiteGender.Female)
        return "Female";
    else if (gender === non_normal_types_1.OmsiteGender.Male)
        return "Male";
    return "Unknown";
}
exports.getGender = getGender;
function omsiteDriverToLeadService(omsiteDriver) {
    const { dateOfBirth, firstName, lastName, gender, maritalStatus, phoneNumber, sfUserId, emailAddress, assignLeadToAgent, aesf_source, } = omsiteDriver;
    return {
        product_line_type: non_normal_types_1.LeadServiceV2ProductLineType.auto,
        consent_given: non_normal_types_1.ConsentGivenEnum.yes,
        record_type_name: non_normal_types_1.LeadServiceRecordTypeNames.residentialLeads,
        lead_rating: non_normal_types_1.LeadRatingEnum.hot,
        agent_attribution: assignLeadToAgent ? sfUserId : undefined,
        applicant: [
            {
                applicant_type: non_normal_types_1.LeadServiceV2ApplicantType.applicant,
                first_name: firstName,
                last_name: lastName,
                date_of_birth: dateOfBirth,
                marital_status: (0, omsite_to_lead_service_1.mapOmsiteMaritalStatusToLeadServiceMaritalStatus)(maritalStatus),
                mobile_phone: phoneNumber,
                email: emailAddress ? emailAddress : undefined,
                gender,
            },
        ],
        tracking: {
            source: "auto",
            aesf_source: aesf_source ?? "Unknown",
            method: non_normal_types_1.SalesforceMethodEnum.onlineMarketplace,
        },
    };
}
exports.omsiteDriverToLeadService = omsiteDriverToLeadService;
function omsiteAdditionalDriverToLeadService(omsiteDriver) {
    return {
        date_of_birth: luxon_1.DateTime.fromJSDate(new Date(omsiteDriver.dateOfBirth)).toISODate() || "",
        first_name: omsiteDriver.firstName,
        last_name: omsiteDriver.lastName,
        gender: getGender(omsiteDriver.gender),
    };
}
exports.omsiteAdditionalDriverToLeadService = omsiteAdditionalDriverToLeadService;
function omsiteVehicleToLeadService(omsiteVehicle) {
    return {
        year: omsiteVehicle.year,
        make: omsiteVehicle?.make,
        model: omsiteVehicle.model,
        sub_model_trim: omsiteVehicle.subModelTrim,
        vin: omsiteVehicle.vin,
        vehicle_ownership: omsiteVehicle.vehicleOwnership,
        vehicle_usage: omsiteVehicle.vehicleUsage,
        annual_mileage: omsiteVehicle.annualMileage,
    };
}
exports.omsiteVehicleToLeadService = omsiteVehicleToLeadService;
