import PageHeader from "../../components/page-header"
import { Button, TextInput } from "grins-ui"
import { store } from "../../store"
import { Fragment, useState } from "react"
import {
  DecimalType,
  EnumType,
  IntegerType,
  QuoteServicePromptAnswer,
  QuoteServicePromptQuestion,
} from "grid-transform"
import { getFormattedValueAndModifiedMaxLength } from "../../utils/string-utils"

export default function AdditionalInfo({
  onSkip,
  onNext,
}: {
  onSkip: () => void
  onNext: () => void
}) {
  const promptQuestions = store.useState(s => s.promptQuestions)

  const [nextButtonDisabled, setNextButtonDisabled] = useState(true)
  const [formState, setFormState] = useState(
    promptQuestions.reduce(
      (acc, promptQuestion) => {
        acc[promptQuestion.prompt_id] = {
          value: "",
          promptType: "",
          error: false,
          errorText: "",
        }
        return acc
      },
      {} as Record<
        string,
        {
          value: string
          promptType: string
          error: boolean
          errorText: string
        }
      >
    )
  )

  const handleInputValidation = (newFormState: typeof formState) => {
    const formValidationPassed = promptQuestions.map(promptQuestion => {
      const dataType = promptQuestion.data_type
      const currentPrompt = newFormState[promptQuestion.prompt_id]

      if (!currentPrompt?.value) {
        return
      }

      switch (dataType) {
        case "decimal": {
          const value = parseFloat(currentPrompt.value)

          const min = (promptQuestion.value_schema as DecimalType)
            .minValue as number
          const max = (promptQuestion.value_schema as DecimalType)
            .maxValue as number

          return value >= min && value <= max
        }
        case "integer": {
          const value = parseInt(currentPrompt.value)

          const min = (promptQuestion.value_schema as IntegerType)
            .minValue as number
          let max = (promptQuestion.value_schema as IntegerType).maxValue

          if (
            max === "CURRENT_YEAR_PLUS_ONE" &&
            currentPrompt.value.length !== 4
          ) {
            currentPrompt.error = false
            currentPrompt.errorText = ""
            return
          }

          max =
            max === "CURRENT_YEAR_PLUS_ONE"
              ? new Date().getFullYear() + 1
              : (max as number)

          const valueIsValid = min <= value && value <= max

          if (!valueIsValid) {
            currentPrompt.error = true
            currentPrompt.errorText = `Please enter a number between ${min} and ${max}`
          }

          return valueIsValid
        }
        case "enum": {
          return (promptQuestion.value_schema as EnumType).values
            .map(value => value.value)
            .includes(currentPrompt.value)
        }
        case "boolean": {
          return (
            currentPrompt.value === "true" || currentPrompt.value === "false"
          )
        }
        default: {
          return true
        }
      }
    })

    setNextButtonDisabled(!formValidationPassed.every(Boolean))
  }

  const ensureOnlyNumber = (inputString: string) => {
    const stringWithOnlyNumbers = inputString.replace(/\D/g, "")
    return stringWithOnlyNumbers
  }

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    promptQuestion: QuoteServicePromptQuestion
  ) => {
    const newFormState = {
      ...formState,
      [promptQuestion.prompt_id]: {
        value: ensureOnlyNumber(e.target.value),
        promptType: promptQuestion.prompt_type,
        error: false,
        errorText: "",
      },
    }

    setFormState(newFormState)

    handleInputValidation(newFormState)
  }

  const PromptQuestion = (promptQuestion: QuoteServicePromptQuestion) => {
    let value = formState[promptQuestion.prompt_id].value
    let maxLength = String(
      (promptQuestion.value_schema as IntegerType).maxValue
    ).length

    if (value) {
      const updatedValues = getFormattedValueAndModifiedMaxLength(
        value,
        maxLength,
        promptQuestion.format_type
      )

      value = updatedValues.formattedValue
      maxLength = updatedValues.modifiedMaxLength
    }

    return (
      <Fragment key={promptQuestion.prompt_id}>
        <p className="mb-1 text-xl text-white">{promptQuestion.text}</p>
        <TextInput
          data-testid={`${promptQuestion.prompt_id}-input`}
          className="max-w-xs"
          label={generatePromptQuestionLabel(promptQuestion.prompt_type)}
          value={value}
          onChange={e => handleInputChange(e, promptQuestion)}
          inputMode="numeric"
          maxLength={maxLength}
          isError={formState[promptQuestion.prompt_id].error}
          isErrorText={formState[promptQuestion.prompt_id].error}
          helpText={formState[promptQuestion.prompt_id].errorText}
        />
      </Fragment>
    )
  }

  const generatePromptQuestions = () => {
    if (promptQuestions.length === 0) {
      onSkip()
    }

    return promptQuestions.map(promptQuestion => {
      return PromptQuestion(promptQuestion)
    })
  }

  const generatePromptQuestionLabel = (rawLabel: string) => {
    // Replace underscores with spaces
    rawLabel = rawLabel.replace(/_/g, " ")

    // Convert the string to title case
    rawLabel = rawLabel
      .split(" ")
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ")

    return rawLabel
  }

  const PageControls = () => (
    <div className="flex gap-5 flex-col-reverse md:flex-row max-w-xs sm:max-w-full">
      <Button
        className="font-bold border-0 text-nowrap whitespace-nowrap cursor-pointer inline-block leading-4 focus:outline-offset-2 sm:focus:outline-offset-4 text-background-grey relative text-xl py-[18px] sm:w-44 rounded-lg bg-white"
        label="I Don't Know"
        onClick={() => {
          store.update(s => {
            s.skipAdditionalInfo = true
          })
          onSkip()
        }}
      />
      <Button
        size="large-square"
        label="Next"
        disabled={nextButtonDisabled}
        onClick={() => {
          const promptAnswers: QuoteServicePromptAnswer[] = []
          for (const [promptAnswerId] of Object.entries(formState)) {
            const promptQuestionResponse = formState[promptAnswerId]
            const promptAnswer: QuoteServicePromptAnswer = {
              prompt_id: promptAnswerId,
              prompt_type: promptQuestionResponse.promptType,
              value: promptQuestionResponse.value,
            }
            promptAnswers.push(promptAnswer)
          }
          store.update(s => {
            s.skipAdditionalInfo = true
            s.application.promptAnswers.push(...promptAnswers)
          })
          onNext()
        }}
      />
    </div>
  )

  return (
    <div className="w-full">
      <PageHeader>Additional Info</PageHeader>
      <div className="flex flex-col gap-3 mt-6 mb-5">
        {generatePromptQuestions()}
      </div>
      <PageControls />
    </div>
  )
}
