import PageHeader from "../components/page-header"
import { usePageLoadEvent } from "../utils/session"

export default function FetchingQuotes() {
  usePageLoadEvent({
    page_path: "/preparingquotes",
    page_title: "Preparing Quotes",
  })

  const renderMainLoadingSpinner = () => (
    <div>
      <img
        className="relative top-9 left-2 w-10 h-5 grins-sm:w-14 grins-sm:h-9 grins-sm:top-16 grins-sm:left-3 animate-pulse"
        src="/public/down-arrow-logo.svg"
        alt="Rate Insurance Logo"
      />
      <div className="w-14 h-14 grins-sm:w-20 grins-sm:h-20 animate-spin">
        <img srcSet="/public/Spinner.png" alt="Loading Spinner" />
      </div>
    </div>
  )

  const renderGhostBox = (delay: number) => (
    <div
      className={`my-6 p-6 h-24 w-full border-4 border-gr-disabled-grey rounded animate-pulse [animation-delay:${delay}s]`}
    >
      <div className="flex justify-end">
        <img
          className="w-12 h-12 animate-spin"
          srcSet="/public/Spinner.png"
          alt="Loading Spinner"
        />
      </div>
    </div>
  )

  return (
    <div>
      <div className="flex justify-between items-center">
        <PageHeader testId="fetching-quotes-header-h2" className="my-6">
          Prepping your quotes...
        </PageHeader>
        {renderMainLoadingSpinner()}
      </div>
      {renderGhostBox(0)}
      {renderGhostBox(0.3)}
      {renderGhostBox(0.6)}
      {renderGhostBox(0.9)}
    </div>
  )
}
