import {
  MyAccountHomeLead,
  QuoteServiceQuoteRequestHomeResponse,
  QuoteServiceQuoteRequestAutoResponse,
  ConsentGivenEnum,
  OmsiteConsentPatch,
} from "grid-transform"
import { store } from "../store"
import {
  clientApplicantToServer,
  clientCoApplicantToServer,
  clientPropertyToServer,
  clientApplicationToServer,
  clientDriverApplicantToServer,
  clientVehicleToServer,
} from "./transform"
import {
  setQuoteResults,
  setAutoQuoteResults,
  updateProgressWidget,
  updatePromptsWithPurchaseAmount,
} from "../actions"
import { Page } from "./enum"
import { SelectOptionsType } from "grins-ui"
import { emitClientEventSfIdentify } from "./events"

interface ApplicantResponse {
  grinsLeadId: string
  sfLeadId: string
}

type PostFn = () => Promise<Response>

export async function postAndNavigate(postFn: PostFn[], nextPage: Page) {
  store.update(s => {
    s.isSubmitting = true
  })
  try {
    const responses = await Promise.all(postFn.map(apiCall => apiCall()))
    responses.some(response => {
      if (!response.ok) {
        throw new Error(response.statusText)
      }
    })
    store.update(s => {
      s.currentPage = nextPage
    })
    updateProgressWidget(nextPage)
  } catch (e) {
    const error = e as Error
    let errorPage: Page
    if (error.message.match("Error validating prefill data.*")) {
      errorPage = Page.ezLynxPrefillError
      store.update(s => {
        s.currentPage = errorPage
      })
    } else {
      errorPage = Page.error
      store.update(s => {
        s.currentPage = errorPage
      })
      updateProgressWidget(errorPage)
    }
  } finally {
    store.update(s => {
      s.isSubmitting = false
    })
  }
}

interface AutoQuotesResponse {
  success: boolean
  data: QuoteServiceQuoteRequestAutoResponse
  error?: string
}

interface HomeQuotesResponse {
  success: boolean
  data: QuoteServiceQuoteRequestHomeResponse
  error?: string
}

export async function postApplication() {
  const state = store.getRawState()
  const response = await fetch("/api/quotes", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...clientApplicationToServer(store.getRawState().application),
      grinsLeadId: state.grinsLeadId,
    }),
  })
  if (!response.ok) {
    const parsedResponse = (await response.json()) as HomeQuotesResponse
    throw new Error(parsedResponse.error)
  }
  const quoteJson = (await response.json()) as HomeQuotesResponse
  if (!quoteJson.success) throw new Error("/api/quotes returned failure")

  const promptQuestions = quoteJson.data.promptQuestions ?? []

  setQuoteResults(quoteJson.data.quotes, state.carriers, promptQuestions)

  if (state.omlinkPurchasePriceAmount && promptQuestions.length > 0) {
    updatePromptsWithPurchaseAmount(
      promptQuestions,
      state.omlinkPurchasePriceAmount
    )
  }
  return response
}

export async function postPrimaryDriver() {
  const applicant = {
    ...store.getRawState().autoApplication.primaryDriver,
    sfUserId: store.getRawState().agentInfo.sfUserId,
    aesf_source:
      store.getRawState().agentInfo.success === true
        ? "Self-Sourced"
        : "Online Marketplace",
  }
  const response = await fetch("/api/driver", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...clientDriverApplicantToServer(applicant),
    }),
  })

  const responseData = (await response.json()) as ApplicantResponse
  store.update(s => {
    s.grinsLeadId = responseData.grinsLeadId
  })

  // Don't attempt to enrich an SF Lead unless it was actually created
  if (responseData.sfLeadId) {
    emitClientEventSfIdentify({
      rudderStackUrl: window.ENV.rudderStackUrl,
      rudderStackWriteKey: window.ENV.rudderStackWriteKey,
      sfLeadId: responseData.sfLeadId,
      traits: {
        lastName: applicant.lastName,
      },
    })
  }

  return response
}

export async function postVehicle() {
  const vehicleIndex = store.getRawState().autoApplication.vehicles.length - 1
  const response = await fetch("/api/vehicle", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...clientVehicleToServer(
        store.getRawState().autoApplication.vehicles[vehicleIndex]
      ),
      grinsLeadId: store.getRawState().grinsLeadId as string,
    }),
  })
  return response
}

export async function postPrimaryDriverAsAdditionalDriver() {
  const primaryDriver = store.getRawState().autoApplication.primaryDriver
  const response = await fetch("/api/additional-driver", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...clientApplicantToServer(primaryDriver),
      grinsLeadId: store.getRawState().grinsLeadId as string,
    }),
  })
  return response
}

export async function postAdditionalDriver() {
  const additionalDriverIndex =
    store.getRawState().autoApplication.additionalDrivers.length - 1
  const response = await fetch("/api/additional-driver", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...clientApplicantToServer(
        store.getRawState().autoApplication.additionalDrivers[
          additionalDriverIndex
        ]
      ),
      grinsLeadId: store.getRawState().grinsLeadId as string,
    }),
  })
  return response
}

export function postGaragingAddress() {
  const state = store.getRawState()
  return fetch("/api/garaging", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...clientPropertyToServer(state.autoApplication.property),
      grinsLeadId: state.grinsLeadId as string,
    }),
  })
}

export async function postApplicant() {
  const applicant = {
    ...store.getRawState().application.applicant,
    sfUserId: store.getRawState().agentInfo.sfUserId,
    aesf_source:
      store.getRawState().agentInfo.success === true
        ? "Self-Sourced"
        : "Online Marketplace",
  }
  const response = await fetch("/api/applicant", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(clientApplicantToServer(applicant)),
  })

  const responseData = (await response.json()) as ApplicantResponse

  store.update(s => {
    s.grinsLeadId = responseData.grinsLeadId
  })

  // Don't attempt to enrich an SF Lead unless it was actually created
  if (responseData.sfLeadId) {
    emitClientEventSfIdentify({
      rudderStackUrl: window.ENV.rudderStackUrl,
      rudderStackWriteKey: window.ENV.rudderStackWriteKey,
      sfLeadId: responseData.sfLeadId,
      traits: {
        lastName: applicant.lastName,
      },
    })
  }

  return response
}

export function postCoApplicant() {
  const state = store.getRawState()
  return fetch("/api/co-applicant", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...clientCoApplicantToServer(state.application.coApplicant),
      grinsLeadId: state.grinsLeadId,
    }),
  })
}

export function postProperty() {
  const state = store.getRawState()
  return fetch("/api/property", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...clientPropertyToServer(state.application.property),
      grinsLeadId: state.grinsLeadId,
    }),
  })
}

export function postConsent() {
  const state = store.getRawState()
  return fetch("/api/consent", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      consent_given: ConsentGivenEnum.yes,
      grinsLeadId: state.grinsLeadId,
    } as OmsiteConsentPatch),
  })
}

export async function getPrefill(token: string) {
  const data = await fetch(`/api/prefill?token=${token}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })

  if (data && data.status === 200) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    const prefillData = (await data.json()) as MyAccountHomeLead

    return prefillData
  }
}
interface YmmResponse {
  success: boolean
  data: string[]
}
interface YmmComplexOptionsResponse {
  success: boolean
  data: SelectOptionsType<string>[]
}

export async function fetchVehicleYears(): Promise<string[]> {
  const response = await fetch(`/api/vehicle-data/years`)
  const ymm = (await response.json()) as YmmResponse

  if (ymm.success) return ymm.data
  else throw new Error("fetchVehicleYears failed")
}

export async function fetchVehicleMakes(year: string): Promise<string[]> {
  const response = await fetch(`/api/vehicle-data/${year}/makes`)
  const ymm = (await response.json()) as YmmResponse
  if (ymm.success) return ymm.data
  else throw new Error("fetchVehicleMakes failed")
}

export async function fetchVehicleModels(
  year: string,
  make: string
): Promise<string[]> {
  const response = await fetch(`/api/vehicle-data/${year}/${make}/models`)
  const ymm = (await response.json()) as YmmResponse
  if (ymm.success) return ymm.data
  else throw new Error("fetchVehicleModels failed")
}

export async function fetchVehicleSubModels(
  year: string,
  make: string,
  model: string
): Promise<SelectOptionsType<string>[]> {
  const response = await fetch(
    `/api/vehicle-data/${year}/${make}/${encodeURIComponent(model)}/submodels`
  )
  const ymm = (await response.json()) as YmmComplexOptionsResponse
  if (ymm.success) return ymm.data
  else throw new Error("fetchVehicleSubModels failed")
}

export async function postAutoQuote() {
  const state = store.getRawState()

  // using destructuring to clone, instead obj by ref, which causes issues.
  const { autoApplication } = { ...store.getRawState() }

  const bodyJson = autoApplication
  const response = await fetch("/api/vehicle/submit-quote", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ...bodyJson, grinsLeadId: state.grinsLeadId }),
  })

  if (!response.ok) {
    const parsedResponse = (await response.json()) as AutoQuotesResponse
    throw new Error(parsedResponse.error)
  }

  const quoteJson = (await response.json()) as AutoQuotesResponse
  if (!quoteJson.success) {
    throw new Error("/api/vehicle/submit-quote returned failure")
  }

  setAutoQuoteResults(quoteJson.data.quotes, state.carriers)

  return response
}

export async function postReferral() {
  const applicant = {
    ...store.getRawState().referralInfo,
    sfUserId: store.getRawState().agentInfo.sfUserId,
  }
  const response = await fetch("/api/referral", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(applicant),
  })

  const responseData = (await response.json()) as ApplicantResponse

  store.update(s => {
    s.grinsLeadId = responseData.grinsLeadId
  })

  // Don't attempt to enrich an SF Lead unless it was actually created
  if (responseData.sfLeadId) {
    emitClientEventSfIdentify({
      rudderStackUrl: window.ENV.rudderStackUrl,
      rudderStackWriteKey: window.ENV.rudderStackWriteKey,
      sfLeadId: responseData.sfLeadId,
      traits: {
        lastName: applicant.lastName,
      },
    })
  }

  return response
}
