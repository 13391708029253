import { Button, DateInput, PhoneInput, TextInput, EmailInput } from "grins-ui"
import { DateTime } from "luxon"
import { store } from "../../store"
import { OmsiteGender, OmsiteMaritalStatus } from "grid-transform"
import PageHeader from "../../components/page-header"
import { usePageLoadEvent } from "../../utils/session"
import { MaritialStatusSelect } from "../../components/maritial-status-select"
import { GenderSelect } from "../../components/gender-select"

interface PrimaryDriverProps {
  onNext: () => void
}

export default function PrimaryDriver({ onNext }: PrimaryDriverProps) {
  usePageLoadEvent({
    page_path: "/auto/primarydriver",
    page_title: "Auto Primary Driver Info",
  })

  const isSubmitting = store.useState(s => s.isSubmitting)
  const inputState = store.useState(s => s.autoApplication.primaryDriver)

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    inputKey: "firstName" | "lastName"
  ) => {
    store.update(s => {
      s.autoApplication.primaryDriver[inputKey] = e.target.value
    })
  }

  const handlePhoneChange = (value: string) => {
    store.update(s => {
      s.autoApplication.primaryDriver.phoneNumber = value
    })
  }

  const handleDateOfBirthChange = (value: DateTime | null) => {
    store.update(s => {
      s.autoApplication.primaryDriver.dateOfBirth = value
    })
  }

  const handleGenderChange = (value: OmsiteGender | null) => {
    store.update(s => {
      s.autoApplication.primaryDriver.gender = value
    })
  }

  const handleMartialStatusChange = (value: OmsiteMaritalStatus | null) => {
    store.update(s => {
      s.autoApplication.primaryDriver.maritalStatus = value
    })
  }

  const handleEmailChange = (value: string) => {
    store.update(s => {
      s.autoApplication.primaryDriver.emailAddress = value
    })
  }

  const shouldButtonBeDisabled = () => {
    const isAgentInfoLoading = store.useState(s => s.agentInfo.isLoading)
    const { omsiteRenderLeadEmailAddress, omsiteRequiredEmail } = window.ENV
    const {
      firstName,
      lastName,
      phoneNumber,
      dateOfBirth,
      maritalStatus,
      gender,
      emailAddress,
    } = inputState

    let emailAddressIsValid = false

    if (!omsiteRenderLeadEmailAddress) {
      emailAddressIsValid = true
    } else if (omsiteRequiredEmail) {
      emailAddressIsValid = !!emailAddress
    } else {
      emailAddressIsValid = true
    }

    if (
      !isAgentInfoLoading &&
      firstName.length > 0 &&
      lastName.length > 0 &&
      dateOfBirth &&
      maritalStatus !== null &&
      gender !== null &&
      phoneNumber.length === 10 &&
      emailAddressIsValid
    ) {
      return false
    }
    return true
  }

  return (
    <div className="w-full">
      <PageHeader testId="primary-driver-header-h2" className="text-white">
        Tell us about yourself
      </PageHeader>
      <div className="max-w-xs">
        <TextInput
          data-testid="first-name-input"
          className="mt-7 mb-6"
          label="First name"
          value={inputState.firstName}
          onChange={e => handleChange(e, "firstName")}
        />
        <TextInput
          data-testid="last-name-input"
          className="my-6"
          label="Last name"
          value={inputState.lastName}
          onChange={e => handleChange(e, "lastName")}
        />
        <p className="text-lg font-semibold text-white my-2">Date of birth</p>
        <DateInput
          testId="date-of-birth-input"
          onDateComplete={handleDateOfBirthChange}
          valueOverride={inputState.dateOfBirth}
        />
        <GenderSelect
          data-testid="gender-select"
          className="my-6"
          value={inputState.gender}
          onChange={handleGenderChange}
        />
        <MaritialStatusSelect
          data-testid="marital-status-select"
          className="my-6"
          value={inputState.maritalStatus}
          onChange={handleMartialStatusChange}
        />
        <PhoneInput
          data-testid="phone-number-input"
          className="my-6"
          value={inputState.phoneNumber}
          label="Phone number"
          onChange={handlePhoneChange}
        />
        {window.ENV.omsiteRenderLeadEmailAddress && (
          <EmailInput
            data-testid="email-input"
            className="my-6"
            value={inputState.emailAddress || ""}
            label={
              window.ENV.omsiteRequiredEmail
                ? "Email address"
                : "Email address (optional)"
            }
            onChange={handleEmailChange}
          />
        )}
      </div>
      <div className="mt-6 mb-10 text-2xs pr-10 text-light-grey font-normal leading-3 bg-blend-overlay">
        By entering your email address and submitting this form, you are
        providing express written consent for Rate Insurance and its affiliates,
        agents, & service providers to contact you at that number regarding
        products or services, including via auto-dialed and/or prerecorded or
        artificial voice calls and text messages (SMS and MMS), even if your
        telephone number is a cellular number or on a corporate, state or the
        National Do Not Call Registry (DNC) or other do not contact list.
        Message and data rates may apply, and calls may be recorded and/or
        monitored. To opt out, email dncrateins@rate.com or call (917) 877-1599.
      </div>
      <Button
        data-testid="next-btn"
        size="large-square"
        label="Next"
        loading={isSubmitting}
        disabled={shouldButtonBeDisabled()}
        onClick={onNext}
      />
    </div>
  )
}
