import { HomeQuote } from "grid-transform"
import { formatCurrency, toKiloUnits } from "grins-utils"
import { useState } from "react"
import AutoQuoteInfoModal from "./quote-info-modal"

export interface Props {
  quote: HomeQuote
}

export default function QuoteDetails({ quote }: Props) {
  const [modalOpen, setModalOpen] = useState(false)
  const [infoModalTitle, setInfoModalTitle] = useState("")
  const [infoModalVerbiage, setInfoModalVerbiage] = useState("")

  const openInfoModal = (title: string, verbiage: string) => {
    setInfoModalTitle(title)
    setInfoModalVerbiage(verbiage)
    setModalOpen(true)
  }

  const renderDetail = (
    title: string,
    value: number,
    infoModalTitle: string,
    infoModalVerbiage: string
  ) => (
    <div className="flex flex-col gap-2">
      <div>
        <span className="text-sm">{title}&nbsp;</span>
        <img
          src="/public/info-icon.svg"
          className="cursor-pointer inline"
          alt="more info icon"
          data-testid={`${title.replace(/\s/g, "-")}-more-info-icon`}
          onClick={() => openInfoModal(infoModalTitle, infoModalVerbiage)}
        />
      </div>
      <span className="font-bold">
        {formatCurrency(toKiloUnits(value))}
        {Math.abs(value) > 999 ? "K" : ""}
      </span>
    </div>
  )

  return (
    <div className="grid grid-cols-2 sm:grid-cols-4 sm:gap-x-7 gap-y-7">
      {renderDetail(
        "Deductible",
        quote.deductible ?? 0,
        "Deductible",
        "The deductible is your out-of-pocket costs before other coverages kick in to handle additional costs associated with a claim."
      )}
      {renderDetail(
        "Dwelling",
        quote.dwellingCoverage ?? 0,
        "Dwelling Coverage",
        "Covers damage to the structure of your home, including your roof, foundation, built-in appliances, cabinets, and any attached structures."
      )}
      {renderDetail(
        "Other Structures",
        quote.otherStructuresCoverage ?? 0,
        "Other Structures",
        "Covers damage to detached structures on your property, like your garage, shed, above-ground pool, or fence."
      )}
      {renderDetail(
        "Personal Property",
        quote.personalPropertyCoverage ?? 0,
        "Personal Property",
        "Covers the cost of damaged or stolen personal belongings, including clothing, jewelry, and electronics."
      )}
      {renderDetail(
        "Loss of Use",
        quote.lossOfUseCoverage ?? 0,
        "Loss of Use",
        "Covers the costs of hotel stays and restaurant meals if your home is being repaired or rebuilt after a covered event, like a fire."
      )}
      {renderDetail(
        "Personal Liability",
        quote.personalLiabilityCoverage ?? 0,
        "Personal Liability",
        "Covers legal and medical expenses if you or a family member are held liable for injury or property damage to others (like if your dog bites a neighbor)."
      )}
      {renderDetail(
        "Medical Payments",
        quote.medicalPaymentsCoverage ?? 0,
        "Medical Payments",
        "Covers medical bills if a guest is injured on your property."
      )}
      <AutoQuoteInfoModal
        title={infoModalTitle}
        verbiage={infoModalVerbiage}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      />
    </div>
  )
}
