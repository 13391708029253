import { useEffect } from "react"
import { useCookies } from "react-cookie"
import { v4 as uuid } from "uuid"
import { emitClientEventPageLoad } from "./events"

export const utmSessionCookieName = "grins_omsite_session_utm"

interface PageLoadTrackingEvent {
  page_path: string
  page_title: string
}

/**
 * A hook that tracks and emits page load events.
 * @param event - an object containing information about the page load event.
 * @returns void
 */
export function usePageLoadEvent(event: PageLoadTrackingEvent): void {
  // Use cookies hook to read and set cookies
  const [cookies, setCookie] = useCookies([utmSessionCookieName])

  // Hook to handle side effects
  useEffect(() => {
    // Get userId from cookies or generate a new one if it doesn't exist
    let userId = cookies[utmSessionCookieName] as string

    if (!userId) {
      userId = uuid()
      setCookie(utmSessionCookieName, userId)
    }

    // Construct and emit event payload
    const payload = {
      title: event.page_title,
      path: event.page_path,
      rudderStackUrl: window.ENV.rudderStackUrl,
      rudderStackWriteKey: window.ENV.rudderStackWriteKey,
    }

    emitClientEventPageLoad(payload)
  }, [cookies]) // re-run effect when cookies change
}
