"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addressInputDropDownStyles = exports.addressSuggestionStyles = void 0;
function addressSuggestionStyles(idx, currIndex) {
    return [
        "cursor-pointer",
        idx === currIndex ? "bg-gr-select-grey" : "",
        idx !== 4 ? "border-b" : "",
        "hover:bg-gr-hover-grey",
    ];
}
exports.addressSuggestionStyles = addressSuggestionStyles;
function addressInputDropDownStyles() {
    return [
        "absolute w-full z-[99] border border-solid rounded-[5px] border-gr-input-border p-1 bg-white",
    ];
}
exports.addressInputDropDownStyles = addressInputDropDownStyles;
