"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SageSureInsuredSSNRequiredIndicator = exports.SageSureWindstormDeductibleOptionEnum = exports.SageSureCoveragePercentageEnum = exports.SageSureNumberOfStoriesEnum = exports.SageSureGenericTypeEnum = exports.SageSureHomeStyleEnum = exports.SageSureSecondaryWaterResistanceEnum = exports.SageSureRoofGeometryTypeWLMFormEnum = exports.SageSureRoofCoveringTypeEnum = exports.SageSureFoundationTypeEnum = exports.SageSureConstructionTypeEnum = exports.SageSureCarrierCodesEnum = exports.SageSureCarrierEnum = void 0;
var SageSureCarrierEnum;
(function (SageSureCarrierEnum) {
    SageSureCarrierEnum["occidental"] = "Occidental";
    SageSureCarrierEnum["harcoInsurance"] = "Harco Insurance";
    SageSureCarrierEnum["sure"] = "SURE";
    SageSureCarrierEnum["safePortInsurance"] = "SafePort Insurance";
    SageSureCarrierEnum["safeChoice"] = "SafeChoice";
    SageSureCarrierEnum["fedNat"] = "Fed Nat Insurance Company";
})(SageSureCarrierEnum || (exports.SageSureCarrierEnum = SageSureCarrierEnum = {}));
var SageSureCarrierCodesEnum;
(function (SageSureCarrierCodesEnum) {
    SageSureCarrierCodesEnum["occidental"] = "OFCC";
    SageSureCarrierCodesEnum["harcoInsuranceHNIC"] = "HNIC";
    SageSureCarrierCodesEnum["harcoInsuranceHP"] = "HP";
    SageSureCarrierCodesEnum["sure"] = "SURE";
    SageSureCarrierCodesEnum["safePortInsurance"] = "SIC";
    SageSureCarrierCodesEnum["safeChoice"] = "SRCH";
    SageSureCarrierCodesEnum["fedNat"] = "FNIC";
})(SageSureCarrierCodesEnum || (exports.SageSureCarrierCodesEnum = SageSureCarrierCodesEnum = {}));
var SageSureConstructionTypeEnum;
(function (SageSureConstructionTypeEnum) {
    SageSureConstructionTypeEnum["other"] = "0";
    SageSureConstructionTypeEnum["frame"] = "100";
    SageSureConstructionTypeEnum["masonryVeneer"] = "101";
    SageSureConstructionTypeEnum["reinforcedConcrete"] = "303";
    SageSureConstructionTypeEnum["metal"] = "402";
})(SageSureConstructionTypeEnum || (exports.SageSureConstructionTypeEnum = SageSureConstructionTypeEnum = {}));
var SageSureFoundationTypeEnum;
(function (SageSureFoundationTypeEnum) {
    SageSureFoundationTypeEnum["slab"] = "100";
    SageSureFoundationTypeEnum["enclosedCrawlSpace"] = "205";
    SageSureFoundationTypeEnum["basement"] = "300";
    SageSureFoundationTypeEnum["other"] = "500";
})(SageSureFoundationTypeEnum || (exports.SageSureFoundationTypeEnum = SageSureFoundationTypeEnum = {}));
var SageSureRoofCoveringTypeEnum;
(function (SageSureRoofCoveringTypeEnum) {
    SageSureRoofCoveringTypeEnum["unknown"] = "0";
    SageSureRoofCoveringTypeEnum["metal"] = "200";
    SageSureRoofCoveringTypeEnum["asphaltCompositeShingles"] = "500";
    SageSureRoofCoveringTypeEnum["architecturalShingles"] = "502";
    SageSureRoofCoveringTypeEnum["concreteClayTiles"] = "600";
    SageSureRoofCoveringTypeEnum["woodShingle"] = "700";
    SageSureRoofCoveringTypeEnum["woodShake"] = "701";
    SageSureRoofCoveringTypeEnum["rubberBituminous"] = "800";
})(SageSureRoofCoveringTypeEnum || (exports.SageSureRoofCoveringTypeEnum = SageSureRoofCoveringTypeEnum = {}));
var SageSureRoofGeometryTypeWLMFormEnum;
(function (SageSureRoofGeometryTypeWLMFormEnum) {
    SageSureRoofGeometryTypeWLMFormEnum["other"] = "0";
    SageSureRoofGeometryTypeWLMFormEnum["flat"] = "100";
    SageSureRoofGeometryTypeWLMFormEnum["hip"] = "300";
})(SageSureRoofGeometryTypeWLMFormEnum || (exports.SageSureRoofGeometryTypeWLMFormEnum = SageSureRoofGeometryTypeWLMFormEnum = {}));
var SageSureSecondaryWaterResistanceEnum;
(function (SageSureSecondaryWaterResistanceEnum) {
    SageSureSecondaryWaterResistanceEnum["unknown"] = "0";
    SageSureSecondaryWaterResistanceEnum["yes_swr"] = "100";
    SageSureSecondaryWaterResistanceEnum["no_swr"] = "200";
})(SageSureSecondaryWaterResistanceEnum || (exports.SageSureSecondaryWaterResistanceEnum = SageSureSecondaryWaterResistanceEnum = {}));
var SageSureHomeStyleEnum;
(function (SageSureHomeStyleEnum) {
    SageSureHomeStyleEnum["biLevelSplit"] = "100";
    SageSureHomeStyleEnum["triLevel"] = "101";
    SageSureHomeStyleEnum["bungalow"] = "200";
    SageSureHomeStyleEnum["capeCod"] = "300";
    SageSureHomeStyleEnum["colonial"] = "400";
    SageSureHomeStyleEnum["ranch"] = "500";
    SageSureHomeStyleEnum["townhouseEndUnit"] = "601";
    SageSureHomeStyleEnum["victorian"] = "700";
    SageSureHomeStyleEnum["contemporary"] = "900";
    SageSureHomeStyleEnum["oneStory"] = "1100";
})(SageSureHomeStyleEnum || (exports.SageSureHomeStyleEnum = SageSureHomeStyleEnum = {}));
var SageSureGenericTypeEnum;
(function (SageSureGenericTypeEnum) {
    SageSureGenericTypeEnum["none_1"] = "1";
    SageSureGenericTypeEnum["none_0"] = "0";
    SageSureGenericTypeEnum["yes_100"] = "100";
    SageSureGenericTypeEnum["no_200"] = "200";
})(SageSureGenericTypeEnum || (exports.SageSureGenericTypeEnum = SageSureGenericTypeEnum = {}));
var SageSureNumberOfStoriesEnum;
(function (SageSureNumberOfStoriesEnum) {
    SageSureNumberOfStoriesEnum["oneStory"] = "100";
    SageSureNumberOfStoriesEnum["oneAndAHalfStories"] = "150";
    SageSureNumberOfStoriesEnum["twoStories"] = "200";
    SageSureNumberOfStoriesEnum["twoAndAHalfStories"] = "250";
    SageSureNumberOfStoriesEnum["threeStories"] = "300";
    SageSureNumberOfStoriesEnum["threeAndAHalfStories"] = "350";
    SageSureNumberOfStoriesEnum["fourStories"] = "400";
    SageSureNumberOfStoriesEnum["fourAndAHalfStories"] = "450";
    SageSureNumberOfStoriesEnum["fiveStories"] = "500";
    SageSureNumberOfStoriesEnum["sixStories"] = "600";
    SageSureNumberOfStoriesEnum["sevenStories"] = "700";
    SageSureNumberOfStoriesEnum["eightToFourteenStories"] = "800";
    SageSureNumberOfStoriesEnum["fifteenOrMoreStories"] = "1500";
})(SageSureNumberOfStoriesEnum || (exports.SageSureNumberOfStoriesEnum = SageSureNumberOfStoriesEnum = {}));
var SageSureCoveragePercentageEnum;
(function (SageSureCoveragePercentageEnum) {
    SageSureCoveragePercentageEnum["zeroPercent"] = "0";
    SageSureCoveragePercentageEnum["onePercent"] = "100";
    SageSureCoveragePercentageEnum["twoPercent"] = "200";
    SageSureCoveragePercentageEnum["threePercent"] = "300";
    SageSureCoveragePercentageEnum["fourPercent"] = "400";
    SageSureCoveragePercentageEnum["fivePercent"] = "500";
    SageSureCoveragePercentageEnum["sixPercent"] = "600";
    SageSureCoveragePercentageEnum["sevenPercent"] = "700";
    SageSureCoveragePercentageEnum["eightPercent"] = "800";
    SageSureCoveragePercentageEnum["ninePercent"] = "900";
    SageSureCoveragePercentageEnum["tenPercent"] = "1000";
    SageSureCoveragePercentageEnum["fifteenPercent"] = "1500";
    SageSureCoveragePercentageEnum["twentyPercent"] = "2000";
    SageSureCoveragePercentageEnum["twentyFivePercent"] = "2500";
    SageSureCoveragePercentageEnum["thirtyPercent"] = "3000";
    SageSureCoveragePercentageEnum["thirtyFivePercent"] = "3500";
    SageSureCoveragePercentageEnum["fortyPercent"] = "4000";
    SageSureCoveragePercentageEnum["fortyFivePercent"] = "4500";
    SageSureCoveragePercentageEnum["fiftyPercent"] = "5000";
    SageSureCoveragePercentageEnum["fiftyFivePercent"] = "5500";
    SageSureCoveragePercentageEnum["sixtyPercent"] = "6000";
    SageSureCoveragePercentageEnum["sixtyFivePercent"] = "6500";
    SageSureCoveragePercentageEnum["seventyPercent"] = "7000";
    SageSureCoveragePercentageEnum["seventyFivePercent"] = "7500";
    SageSureCoveragePercentageEnum["eightyPercent"] = "8000";
    SageSureCoveragePercentageEnum["eightyFivePercent"] = "8500";
    SageSureCoveragePercentageEnum["ninetyPercent"] = "9000";
    SageSureCoveragePercentageEnum["ninetyFivePercent"] = "9500";
    SageSureCoveragePercentageEnum["oneHundredPercent"] = "10000";
})(SageSureCoveragePercentageEnum || (exports.SageSureCoveragePercentageEnum = SageSureCoveragePercentageEnum = {}));
var SageSureWindstormDeductibleOptionEnum;
(function (SageSureWindstormDeductibleOptionEnum) {
    SageSureWindstormDeductibleOptionEnum["neither"] = "0";
    SageSureWindstormDeductibleOptionEnum["hurricane"] = "100";
    SageSureWindstormDeductibleOptionEnum["windHail"] = "200";
})(SageSureWindstormDeductibleOptionEnum || (exports.SageSureWindstormDeductibleOptionEnum = SageSureWindstormDeductibleOptionEnum = {}));
var SageSureInsuredSSNRequiredIndicator;
(function (SageSureInsuredSSNRequiredIndicator) {
    SageSureInsuredSSNRequiredIndicator["runInsuranceScoreNowWithSSN"] = "100";
    SageSureInsuredSSNRequiredIndicator["runInsuranceScoreNowWithoutSSN"] = "200";
    SageSureInsuredSSNRequiredIndicator["doNotRunInsuranceScore"] = "300";
})(SageSureInsuredSSNRequiredIndicator || (exports.SageSureInsuredSSNRequiredIndicator = SageSureInsuredSSNRequiredIndicator = {}));
