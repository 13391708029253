import {
  Button,
  DateInput,
  SelectComplexOptions,
  SelectOptionsType,
  TextInput,
} from "grins-ui"
import { DateTime } from "luxon"
import { store } from "../../store"
import { OmsiteRelationshipToApplicant } from "grid-transform"
import PageHeader from "../../components/page-header"
import { usePageLoadEvent } from "../../utils/session"

interface CoApplicantProps {
  onNext: () => void
  onBack: () => void
}

export default function CoApplicant({ onNext, onBack }: CoApplicantProps) {
  usePageLoadEvent({
    page_path: "/homeflow/coapplicantinfo",
    page_title: "Home Co-applicant User",
  })

  const isSubmitting = store.useState(s => s.isSubmitting)
  const inputState = store.useState(s => ({ ...s.application.coApplicant }))

  const relationshipOptions: SelectOptionsType<OmsiteRelationshipToApplicant>[] =
    [
      {
        uiLabel: "Spouse",
        value: {
          base: "Spouse" as OmsiteRelationshipToApplicant,
          data: undefined,
        },
      },
      {
        uiLabel: "Domestic Partner",
        value: {
          base: "Domestic Partner" as OmsiteRelationshipToApplicant,
          data: undefined,
        },
      },
    ]

  const shouldButtonBeDisabled = () => {
    if (
      inputState.exists === false ||
      (inputState.firstName.length > 0 &&
        inputState.lastName.length > 0 &&
        inputState.dateOfBirth &&
        inputState.relationshipToApplicant !== null)
    ) {
      return false
    } else {
      return true
    }
  }

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    inputKey: "firstName" | "lastName"
  ) => {
    store.update(s => {
      s.application.coApplicant[inputKey] = e.target.value
    })
  }

  const handleDateOfBirthChange = (value: DateTime | null) => {
    store.update(s => {
      s.application.coApplicant.dateOfBirth = value
    })
  }

  const handleRelationshipChange = (value: number | null) => {
    store.update(s => {
      if (value == null) {
        s.application.coApplicant.relationshipToApplicant = null
      } else {
        s.application.coApplicant.relationshipToApplicant =
          relationshipOptions[value].value.base
      }
    })
  }

  const findSelectedRelationshipIndex = () => {
    const foundIndex = relationshipOptions.findIndex(e => {
      return e.value.base === inputState.relationshipToApplicant
    })

    return foundIndex >= 0 ? foundIndex : null
  }

  return (
    <>
      <div className="w-full">
        <PageHeader testId="co-applicant-header-h2">
          Great! What's their info?
        </PageHeader>
        <div className="max-w-xs">
          <TextInput
            data-testid="first-name-input"
            className="mt-7 mb-6"
            label="First name"
            value={inputState.firstName}
            onChange={e => handleChange(e, "firstName")}
          />
          <TextInput
            data-testid="last-name-input"
            className="my-6"
            label="Last name"
            value={inputState.lastName}
            onChange={e => handleChange(e, "lastName")}
          />
          <p className="text-lg font-semibold text-white my-2">Date of birth</p>
          <DateInput
            testId="date-of-birth-input"
            onDateComplete={handleDateOfBirthChange}
            valueOverride={inputState.dateOfBirth}
          />
          <SelectComplexOptions
            testId="relationship-to-applicant-select"
            className="my-6"
            label="Relationship to you"
            options={relationshipOptions}
            selectedOption={findSelectedRelationshipIndex()}
            onSelectOption={handleRelationshipChange}
          />
          <div className="flex gap-2 mt-14">
            <button
              data-testid="back-btn"
              className="flex-1 text-white text-xl text-left font-bold leading-4 cursor-pointer focus:outline-offset-2 sm:focus:outline-offset-4"
              type="button"
              disabled={isSubmitting}
              onClick={onBack}
            >
              {"<"}&nbsp;&nbsp;&nbsp;Back
            </button>

            <Button
              data-testid="next-btn"
              size="large-square"
              label="Add"
              loading={isSubmitting}
              disabled={shouldButtonBeDisabled()}
              onClick={onNext}
            />
          </div>
        </div>
      </div>
    </>
  )
}
