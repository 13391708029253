"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OmsiteProductLine = exports.OmsiteGender = exports.OmsiteMaritalStatus = exports.MyAccountLeadTypeEnum = exports.OmsiteApplicantRelation = void 0;
var OmsiteApplicantRelation;
(function (OmsiteApplicantRelation) {
    OmsiteApplicantRelation["child"] = "Child";
    OmsiteApplicantRelation["domesticPartner"] = "Domestic Partner";
    OmsiteApplicantRelation["employee"] = "Employee";
    OmsiteApplicantRelation["insured"] = "Insured";
    OmsiteApplicantRelation["other"] = "Other";
    OmsiteApplicantRelation["parent"] = "Parent";
    OmsiteApplicantRelation["relative"] = "Relative";
    OmsiteApplicantRelation["spouse"] = "Spouse";
})(OmsiteApplicantRelation || (exports.OmsiteApplicantRelation = OmsiteApplicantRelation = {}));
var MyAccountLeadTypeEnum;
(function (MyAccountLeadTypeEnum) {
    MyAccountLeadTypeEnum["purchase"] = "MyAccount Purchase Quote Request";
    MyAccountLeadTypeEnum["refinance"] = "MyAccount Refinance Quote Request";
})(MyAccountLeadTypeEnum || (exports.MyAccountLeadTypeEnum = MyAccountLeadTypeEnum = {}));
var OmsiteMaritalStatus;
(function (OmsiteMaritalStatus) {
    OmsiteMaritalStatus["Single"] = "Single";
    OmsiteMaritalStatus["Married"] = "Married";
    OmsiteMaritalStatus["Divorced"] = "Divorced";
    OmsiteMaritalStatus["DomesticPartner"] = "Domestic Partner";
    OmsiteMaritalStatus["Widowed"] = "Widowed";
    OmsiteMaritalStatus["Separated"] = "Separated";
})(OmsiteMaritalStatus || (exports.OmsiteMaritalStatus = OmsiteMaritalStatus = {}));
var OmsiteGender;
(function (OmsiteGender) {
    OmsiteGender["Male"] = "Male";
    OmsiteGender["Female"] = "Female";
    OmsiteGender["RatherNotSay"] = "I'd rather not say";
})(OmsiteGender || (exports.OmsiteGender = OmsiteGender = {}));
var OmsiteProductLine;
(function (OmsiteProductLine) {
    OmsiteProductLine["Home"] = "Home";
    OmsiteProductLine["Auto"] = "Auto";
})(OmsiteProductLine || (exports.OmsiteProductLine = OmsiteProductLine = {}));
