import { useEffect, useState } from "react"
import {
  Button,
  SelectComplexOptions,
  TextInput,
  complexOptionsFrom,
  SelectOptionsType,
} from "grins-ui"
import { store } from "../../store"
import PageHeader from "../../components/page-header"
import {
  OmsiteAutoOwnership,
  OmsiteAutoUsage,
  Vehicle,
  VehicleDetails,
} from "grid-transform"
import { usePageLoadEvent } from "../../utils/session"

export interface Props {
  allowBack?: boolean
  onBack?: () => void
  onNext: (vehicleDetails: VehicleDetails) => void
}

export default function VehicleDetailsPage({
  allowBack = false,
  onBack,
  onNext,
}: Props) {
  usePageLoadEvent({
    page_path: "/auto/vehicleusage",
    page_title: "Auto Vehicle Usage Info",
  })

  const isSubmitting = store.useState(s => s.isSubmitting)

  const [ownership, setOwnershipOptions] = useState<
    SelectOptionsType<string>[]
  >([])
  const [usage, setUsageOptions] = useState<SelectOptionsType<string>[]>([])
  const miles = store.useState(s => s.currentVehicle.details.miles)

  const [selectedOwnershipIndex, setSelectedOwnershipIndex] = useState<
    number | null
  >(null)
  const [selectedUsageIndex, setSelectedUsageIndex] = useState<number | null>(
    null
  )

  let currentVehicle: Vehicle = store.getRawState().currentVehicle

  useEffect(() => {
    currentVehicle = store.getRawState().currentVehicle
  }, [])

  useEffect(() => {
    const ownershipOptions: SelectOptionsType<string>[] = complexOptionsFrom(
      Object.values(OmsiteAutoOwnership as object) as string[]
    )

    // Per specs for better UI/UX, change "Lien" uiLabel to "Financed"
    ownershipOptions.forEach(elem => {
      elem.uiLabel =
        elem.uiLabel === OmsiteAutoOwnership.lien.toString()
          ? "Financed"
          : elem.uiLabel
      return elem
    })

    setOwnershipOptions(ownershipOptions)
  }, [])

  useEffect(() => {
    const usageOptions: SelectOptionsType<string>[] = complexOptionsFrom(
      Object.values(OmsiteAutoUsage as object) as string[]
    )
    setUsageOptions(usageOptions)
  }, [])

  const handleSelectOwnership = (selectedIndex: number | null) => {
    setSelectedOwnershipIndex(selectedIndex)
  }

  const handleSelectUsage = (selectedIndex: number | null) => {
    setSelectedUsageIndex(selectedIndex)
  }

  const handleMilesChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value.replace(/\D/g, "")
    store.update(s => {
      s.currentVehicle.details.miles = newValue
    })
  }

  const handleNext = () => {
    if (
      selectedOwnershipIndex === null ||
      selectedUsageIndex === null ||
      miles == null ||
      miles.trim() === ""
    ) {
      return
    }
    onNext({
      ownership: ownership[selectedOwnershipIndex].value.base,
      usage: usage[selectedUsageIndex].value.base,
      miles,
    })
  }
  const handleBack = () => {
    if (onBack) onBack()
  }

  const isNextEnabled =
    selectedOwnershipIndex !== null &&
    selectedUsageIndex !== null &&
    miles !== null &&
    miles.trim() !== ""
  return (
    <div className="w-full">
      <PageHeader
        className="text-white mb-6"
        testId="vehicle-details-header-h2"
      >
        Let’s get some more details on your {currentVehicle.ymm.year}{" "}
        {currentVehicle.ymm.make} {currentVehicle.ymm.model}
      </PageHeader>
      <div className="flex flex-col gap-y-6 max-w-xs">
        <div>
          <p className="text-base font-semibold text-white mb-2">
            Do you own, finance, or lease this vehicle?
          </p>
          <SelectComplexOptions
            testId="vehicle-ownership-select"
            label="Please select an option"
            options={ownership}
            selectedOption={selectedOwnershipIndex}
            onSelectOption={handleSelectOwnership}
          />
        </div>
        <div>
          <p className="text-base font-semibold text-white mb-2">
            How do you use this vehicle?
          </p>
          <SelectComplexOptions
            testId="vehicle-usage-select"
            label="Please select an option"
            options={usage}
            selectedOption={selectedUsageIndex}
            onSelectOption={handleSelectUsage}
          />
        </div>
        <div>
          <p className="text-base font-semibold text-white mb-2">
            How many miles do you drive each year?
          </p>
          <TextInput
            data-testid="vehicle-miles-input"
            label="Miles"
            value={miles}
            onChange={handleMilesChanged}
          />
        </div>
      </div>
      <div className="flex gap-16 mt-16">
        {allowBack && (
          <button
            data-testid="back-btn"
            className="text-white"
            type="button"
            onClick={handleBack}
          >
            {"<"} Back
          </button>
        )}
        <Button
          data-testid="next-btn"
          disabled={!isNextEnabled}
          label={"Next"}
          loading={isSubmitting}
          size="large-square"
          onClick={handleNext}
        />
      </div>
    </div>
  )
}
